@import '_palette', '_mixin';

$montserrat: 'Montserrat';
$montserrat--bold: 'Montserrat-bold';

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  // unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: Montserrat-bold;
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  // unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: SpoqaHanSansNeo;
  src: url('../assets/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype');
  unicode-range: U+0030-0039;
}

@font-face {
  font-family: SpoqaHanSansNeo-Bold;
  src: url('../assets/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
  unicode-range: U+0030-0039;
}

@font-face {
  font-family: SpoqaHanSansNeo-Medium;
  src: url('../assets/fonts/SpoqaHanSansNeo-Medium.ttf') format('truetype');
  // unicode-range: U+0030-0039 U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
}

@font-face {
  font-family: Inter;
  src: url('../assets/fonts/Inter-Regular.ttf') format('truetype');
  unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A,
    U+0061-007A;
}

@font-face {
  font-family: Inter-Bold;
  src: url('../assets/fonts/Inter-Bold.ttf') format('truetype');
  unicode-range: U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A,
    U+0061-007A;
}

@font-face {
  font-family: Inter-Number-Bold;
  src: url('../assets/fonts/Inter-Bold.ttf') format('truetype');
  unicode-range: U+0030-0039;
}

@font-face {
  font-family: SpoqaHanSansNeo-All-Bold;
  src: url('../assets/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  word-spacing: -0.3px;
  letter-spacing: -0.3px;
  word-break: keep-all;
}

html {
  overflow-y: scroll;
}

$bold: 'Inter-Bold', 'SpoqaHanSansNeo-Bold', 'Montserrat-bold', 'Inter-Bold';

h1,
h2,
b {
  font-family: $bold;
  color: $font-color;
  font-weight: bold;
  margin: 0;
}

p,
a,
span {
  font-family: Inter, SpoqaHanSansNeo;
  color: $font-color;
  text-decoration: none;
  margin: 0;
}

.montserrat {
  font-family: Montserrat;

  &__bold {
    font-family: Montserrat-bold;
  }
}

.bold,
strong {
  font-family: $bold;
}

i {
  font-style: initial;
}

.blue {
  color: $elysia-color !important;
}

.link {
  color: $elysia-color !important;
  text-decoration: underline;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

figure {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;

  > li {
    margin: 10px 0;

    &::marker {
      font-size: 10px;
    }
  }
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.elysia {
  background-color: $background-color;
  white-space: pre-line;

  .navigation {
    background-color: #ffffff;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 900;
    border-bottom: 1px solid $gray-line;
    transition: all 0.5s ease-in-out;

    &__bottom {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 60px;
      border: 1px solid $gray-line;
      background: #ffffff;
      transition: all 0.3s ease;
      z-index: 1;

      &.disabled {
        overflow: hidden;
        padding: 0;
        height: 0;
        opacity: 0;
        background: #ffffff;
        z-index: 1;
      }

      &__link {
        min-width: 196px;
        padding: 20px 15px;
        border-bottom: 1px solid $gray-line;
        cursor: pointer;

        &:hover {
          background: #f7fbff;
          border-left: 2px solid $elysia-color;
          padding: 20px 13px;

          > div > p {
            color: $elysia-color;
            font-weight: bold;
          }
        }

        &:last-child {
          border-bottom: 2px solid $elysia-color;
        }

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          > img {
            margin-left: 10px;
          }

          > p {
            cursor: pointer;
            color: $font-color;
            font-size: 15px;
          }
        }
      }

      > a > p {
        margin: 0 25px;
      }
    }

    // &__apy::after {
    //   content: '';
    //   position: absolute;
    //   border-bottom: 1px solid #efefef;
    //   height: 58px;
    //   left: 0px;
    //   width: 100%;
    // }

    &__container {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // backdrop-filter: blur(10px);
      transition: backdrop-filter 50ms ease-in-out 0s;
      max-width: 1190px;
      height: 100px;
    }

    &__wrapper {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 100%;
    }

    &__link:hover {
      > div:first-child {
        font-weight: bold;
      }
    }

    &__link {
      display: inline;
      cursor: pointer !important;
      position: relative;
      transition: all 0.5s ease-in-out;
      font-size: 15px;
      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 100%;

      //   left: -45px;
      //   right: -45px;
      //   margin-top: -1px;
      //   height: 30px;
      // }

      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: 20px;
        // margin-left: 40px;

        > a,
        > div {
          padding: 0 15px;
        }

        > a:first-child {
          padding-right: 29px;
        }

        > a:hover {
          > div > div > p {
            font-weight: bold;
          }
        }

        > div:hover {
          > div > div > p {
            font-weight: bold;

            > span {
              border-width: 2px;
            }
          }
        }
      }

      &__under-line {
        position: absolute;
        width: 0%;
        height: 3px;
        background-color: #b7b7b7;
        margin: 0 auto;
        bottom: -5px;
        opacity: 0;
      }

      .hover {
        animation: hovers 0.25s;
        animation-fill-mode: forwards;
      }

      &__wrapper {
        display: flex;

        // margin: 0 22px;
      }
    }

    &__sub-menu {
      background-color: #ffffff;
      position: absolute;
      margin-top: 0;
      padding: 0;
      z-index: 99;
      border-radius: 15px;
      top: 40px;
      left: -45px;
      right: -45px;

      &__item {
        padding: 20px;
      }

      :hover {
        background-color: #f0f0f1;
      }

      :first-child {
        border-radius: 15px 15px 0 0;
      }

      :last-child {
        border-radius: 0 0 15px 15px;
      }
    }

    &__address {
      display: inline-flex;
      align-items: center;
    }

    &__jazzicon {
      margin-right: 5px;
      display: flex;
      justify-content: center;
    }

    &__wallet {
      > div {
        justify-content: center;
      }

      animation: glow 1.5s linear infinite alternate;

      &--connected {
        transition: all 0.2s ease-in;

        &.unknown-chain {
          background: #d84d4d !important;
          padding: 10px;
          justify-content: space-between !important;

          > h2 {
            color: #ffffff;
            cursor: pointer;
          }
        }
      }

      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      &__status {
        color: #ffffff;
        font-family: $bold;
        cursor: pointer;

        &--connected {
          color: $font-color;
          font-family: $bold;
          text-align: right;
          margin: 0;
          cursor: pointer;
          font-size: 15px;
        }
      }

      &__sub-menu {
        display: block;
        position: absolute;
        background-color: #ffffff;
        padding: 0;
        z-index: 99;
        word-break: break-all;
        margin-top: 32px;
        border-radius: 15px;
        top: 40px;
        width: 280px;

        > hr {
          border: 0;
          margin: 0;
          background-color: #888888;
          cursor: default;
          width: 100%;
          height: 1px;
        }

        &__item {
          font-family: Montserrat, Inter;
          white-space: normal;
          margin: 0;
          padding: 10px 20px;
        }

        :hover {
          background-color: #f0f0f1;
        }

        :first-child {
          border-radius: 15px 15px 0 0;
        }

        :last-child {
          border-radius: 0 0 15px 15px;
        }
      }
    }

    &__mainnet {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__wrapper {
        position: relative;
        margin-left: 10px;
      }

      &__current {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        border: 1px solid $gray-line;
        background: $background-color;
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s ease-in;

        &:hover {
          border: 1px solid $elysia-color;
        }

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 6px;
          height: 6px;
          margin-bottom: 2px;
          right: 12px;
          border-top: 1px solid #333333;
          border-right: 1px solid #333333;
          transform: rotate(135deg);
        }

        > h2 {
          font-family: $montserrat--bold;
          color: $font-color;
          cursor: pointer;
        }
      }

      &__change-network {
        &__wrapper {
          display: flex;
          position: absolute;
          flex-direction: column;
          border: 1px solid $gray-line;
          background: $background-color;
          border-radius: 0 0 5px 5px;
          z-index: 1;

          > p {
            text-align: center;
            border-bottom: 1px solid #f0f0f1;
            color: $font-color;
            font-family: $montserrat;
          }
        }

        display: flex;
        align-items: center;
        cursor: pointer;

        > h2 {
          font-family: $montserrat--bold;
          color: $font-color;
          cursor: pointer;
        }
      }
    }

    &__metamask-add-el-button {
      cursor: pointer;
    }

    &__arrow {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-left: 5px;
      margin-bottom: 4px;
      border-top: 1px solid #333333;
      border-right: 1px solid #333333;
    }

    .beta {
      &::after {
        content: 'B';
        position: absolute;
        top: 3.5px;
        right: -14px;
        width: 12px;
        height: 12px;
        line-height: 12px;
        font-family: $montserrat--bold;
        color: $background-color;
        font-size: 9px;
        border-radius: 2px;
        background-color: #0087ff;
        text-align: center;
        // transform: translateX(50%);
      }
    }
  }

  // navigation end

  .main {
    &__image-wrapper {
      position: relative;
      display: flex;
      user-select: none;

      .dom {
        position: relative;
      }

      .pit {
        position: absolute;
      }
    }

    &__section {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &__title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      &__text-container {
        > p {
          &:first-child {
            i {
              color: $elysia-color;
              font-family: $bold;
            }
          }
        }
      }

      &__button {
        display: flex;

        > div {
          display: flex;
          flex-direction: column;

          > p {
            text-align: center;
          }

          > a {
            @include hover__sub_box-shadow;

            cursor: pointer;
            border-radius: 28px;

            > p {
              text-align: center;
              cursor: pointer;
            }
          }

          &:first-child {
            > a {
              @include hover__box-shadow--white;

              background-color: $elysia-color;
              border: 1px solid $elysia-color;

              > p {
                color: #ffffff;
              }
            }
          }

          &:last-child {
            > a {
              background-color: #ffffff;
              border: 1px solid $elysia-color;

              > p {
                color: $elysia-color;
              }
            }
          }
        }
      }
    }

    &__content {
      display: flex;

      &__image-container {
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        user-select: none;

        &--01 {
          &--01 {
            top: 100px;
            position: relative;

            $pit-height: 292px;

            > img {
              object-fit: none;
            }

            .pit {
              height: $pit-height;
              top: calc(#{$pit-height} / 2 * -1);
              position: absolute;
            }

            .bottom {
              position: relative;
              z-index: 3;
              object-fit: initial !important;
            }
          }

          .token-low {
            position: relative;
            z-index: 2;
            margin-bottom: -60px;
            animation-duration: 15s;
            animation-name: down-fade;
            animation-iteration-count: infinite;
            width: 513px;
          }

          .token-many {
            position: relative;
            z-index: 2;
            margin-top: -210px;
            animation-duration: 15s;
            animation-name: up-fade;
            animation-iteration-count: infinite;
            width: 513px;
          }
        }

        &--02 {
          position: relative;

          .coin {
            position: absolute;
            top: 100px;
            left: 10px;
            animation-duration: 6s;
            animation-name: floating-image;
            animation-iteration-count: infinite;
          }

          .pit {
            position: absolute;
            bottom: -35px;
            left: -20px;
          }
        }

        &--03 {
          position: relative;

          .governance--image {
            width: 515px;
          }

          > div {
            position: absolute;
            right: 0;
            top: -150px;

            .paper--o {
              left: 50px;
              position: relative;
              z-index: 2;
              animation-delay: 1s;
              animation-duration: 6s;
              animation-name: floating-image;
              animation-iteration-count: infinite;
            }

            .paper--x {
              top: 20px;
              position: relative;
              z-index: 3;
              animation-duration: 6s;
              animation-name: floating-image;
              animation-iteration-count: infinite;
            }
          }

          .pit {
            position: absolute;
            right: 0;
          }
        }
      }

      @keyframes floating-image {
        0% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(40px);
        }
      }

      @keyframes down-fade {
        0% {
          transform: translateY(0);
          opacity: 0;
        }

        3% {
          transform: translateY(40px);
          opacity: 1;
        }

        7% {
          transform: translateY(500px);
          opacity: 1;
        }

        100% {
          transform: translateY(500px);
        }
      }

      @keyframes up-fade {
        0% {
          transform: translateY(0);
          opacity: 1;
        }

        30% {
          transform: translateY(0);
        }

        60% {
          transform: translateY(-400px);
        }

        70% {
          transform: translateY(-380px);
        }

        80% {
          transform: translateY(-400px);
        }

        90% {
          transform: translateY(-380px);
          opacity: 1;
        }

        100% {
          transform: translateY(-400px);
          opacity: 0;
        }
      }

      > div {
        &:last-child {
          > div:last-child {
            background-color: $elysia-color;
            cursor: pointer;
            border-radius: 28px;
            @include hover__box-shadow--white;

            > p {
              user-select: none;
              text-align: center;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }

    &__advantages {
      flex-direction: column;
      align-items: flex-start;

      > h2 {
        color: $font-color;
        text-align: left;

        i {
          color: $elysia-color;
        }
      }

      &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        width: 100%;
        align-items: flex-start;
        justify-items: center;

        > div {
          display: flex;

          > img {
            object-fit: none;
          }

          > div {
            > h2 {
              color: $font-color;
              white-space: nowrap;
            }

            > p {
              color: $gray-font-color;
            }
          }
        }
      }
    }

    &__service {
      &__graph {
        &--circle {
          word-break: keep-all;
        }
      }

      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;

      > h2 {
        font-size: 40px;

        strong {
          color: $elysia-color;
        }
      }

      &__comment {
        display: flex;
        flex-direction: row;
        align-items: center;

        > p {
          font-size: 17px;
          margin-right: 20px;
        }

        > div {
          @include hover__box-shadow;

          width: 253px;
          height: 30px;
          box-shadow: 0 1px 6px #00000029;
          border-radius: 15px;
          background-color: #ffffff;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          > h2 {
            color: $elysia-color;
            cursor: pointer;
            font-size: 15px;
          }
        }
      }
    }

    &__partners {
      height: 100vh;
      flex-direction: column;
      margin-top: 10vh;
      margin-bottom: 40vh;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        > h2 {
          text-align: center;

          strong {
            color: $elysia-color;
          }
        }

        > section {
          display: flex;

          > img:first-child {
            margin-right: 112px;
          }
        }
      }
    }

    &__governance {
      flex-direction: column;
      justify-content: flex-start;

      > h2 {
        color: $font-color;

        strong {
          color: $elysia-color;
        }
      }

      &__table {
        > hr {
          border: 0;
          height: 1px;
          border-top: 1px solid $elysia-color;
        }
      }

      &__converter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__button {
          background: $background-color;
          flex: 1;
          border-radius: 10px;
          border: 1px solid $elysia-color;
          text-align: center;
          cursor: pointer;
          @include hover__soft-background;

          > h2 {
            color: $font-color;
            cursor: pointer;
          }

          &.disable {
            border: 1px solid $gray-line;

            > h2 {
              color: $gray-font-color;
            }
          }
        }
      }

      &__content {
        border-radius: 18px;
        border: 1px solid $gray-line;
        background: $background-color;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      &__header {
        border-bottom: 1px solid $gray-line;

        > p {
          color: $font-color;
        }
      }

      &__body {
        border-bottom: 1px solid $gray-line;
        cursor: pointer;

        &:hover {
          background: $gray-line;
        }

        &:last-child {
          border: 0;
        }

        > p {
          cursor: pointer;
          color: $font-color;
        }
      }

      &__more {
        text-align: center;
        border-radius: 5px;
        border: 1px solid $gray-line;
        cursor: pointer;
        @include hover__soft-background;

        > p {
          background-color: #ffffff;
          cursor: pointer;
        }
      }
    }

    &__dao {
      margin: 150px 0 0;
      padding: 80px;
      width: 100%;
      border: 1px solid #0099cc;
      border-radius: 15px;
      background-color: #f7fbff;

      > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;

        > div {
          width: 284px;
          height: 284px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #0099cc;
          background-color: #ffffff;
          padding: 30px;

          > p {
            text-align: center;
            font-size: 21px;
            line-height: 35px;
          }
        }
      }
    }
  }

  // main end

  .deposit {
    position: relative;

    &__title {
      text-align: center;
      background: no-repeat bottom;

      > p {
        text-align: center;
      }

      > h2 {
        text-align: center;
      }

      &__round {
        display: flex;
        flex-direction: row;
        justify-content: center;

        > div:first-child {
          margin-right: 20px;
        }

        > div:last-child {
          margin-left: 20px;
        }

        > div {
          box-shadow: 0 1px 6px #00000029;
          text-align: center;
          cursor: pointer;
          @include hover__soft-background;

          > p {
            color: $font-color;
            cursor: pointer;
          }

          &.active {
            border: 1px solid $elysia-color;

            > p {
              font-family: $bold;
            }
          }
        }
      }
    }

    &__table {
      background-color: $background-color;
      border: 1px solid $gray-line;
      border-radius: 5px;
      position: relative;

      &__ref {
        position: absolute;
      }

      &__header {
        background-color: $background-color;
        box-shadow: 0 0 6px #00000029;
        border-radius: 5px;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        transition: all 0.5s ease;

        &:hover {
          box-shadow: 0 0 14px #00a7ff66;
          transition: all 0.5s ease;
        }

        &__token-info {
          display: flex;
          justify-content: left;
          flex-direction: row;
          align-items: center;
          // box-shadow: 0px 0px 6px #00000029;

          > section {
            > p:first-child {
              font-family: SpoqaHanSansNeo;
              color: #888888;
            }

            > p:last-child {
              font-family: SpoqaHanSansNeo-Bold, Inter-Bold;
              color: #333333;
            }
          }
        }

        &__data-grid {
          // display: grid;
          // grid-template-columns: repeat(4, 1fr);
          // grid-template-rows: 1fr;
          // justify-items: flex-end;

          > div {
            // display: flex;
            // justify-content: space-between;

            > p {
              cursor: pointer;

              &:first-child {
                color: #888888;
              }

              &:last-child {
                color: #333333;
              }
            }
          }
        }
      }

      &__body {
        &__strategy {
          border-bottom: 1px solid $gray-line;

          > h2 {
            color: $font-color;
          }

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            > div {
              width: 100%;

              &:first-child {
                background: #0099cc;

                > p {
                  text-align: center;
                  color: $background-color;

                  > span {
                    font-family: $bold;
                    color: $background-color;
                  }
                }
              }

              &:last-child {
                background: #40afd5;

                > p {
                  text-align: center;
                  color: $background-color;

                  > span {
                    font-family: $bold;
                    color: $background-color;
                  }
                }
              }
            }
          }
        }

        &__amount {
          text-align: right;

          &__button {
            @include hover__box-shadow;

            background-color: $elysia-color;
            box-shadow: 0 0 6px #00000029;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            margin: 0 0 0 auto;

            &::after {
              content: '';
              width: 100%;
              height: 100%;
              position: absolute;
              border-radius: 50px;
            }

            > p {
              cursor: pointer;
            }

            &.disable {
              pointer-events: none;
              border: 0;
              cursor: not-allowed;
              background-color: $gray-line;
              box-shadow: none;

              > p {
                cursor: not-allowed;
                color: $gray-font-color;
              }
            }
          }

          > div {
            > h2 {
              color: $font-color;

              > span {
                color: $font-color;
              }
            }

            > p {
              color: $gray-font-color;

              > span {
                color: $gray-font-color;
              }
            }
          }

          &__container {
            border-top: 1px solid $gray-line;
            border-bottom: 1px solid $gray-line;
            display: flex;
            justify-content: space-between;
          }

          &__wrapper {
            flex: 1;
            display: flex;
            justify-content: space-between;

            &.right {
              border-left: 1px solid $gray-line;
            }

            > div {
              &:first-child {
                margin-top: 3px;
              }

              > h2 {
                color: $font-color;
              }
            }
          }
        }

        &__loan-list {
          &__button {
            @include hover__sub_box-shadow;
          }

          > div {
            > div:nth-child(1) {
              display: flex;
              justify-content: space-between;

              > h2 {
                color: $font-color;
                margin-top: 25px;
                font-size: 18px;
              }

              > a > div {
                background-color: #ffffff;
                box-shadow: 0 0 6px #00000029;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 25px;
                width: 160px;
                height: 30px;
                cursor: pointer;

                > p {
                  color: #888888;
                  font-size: 15px;
                  cursor: pointer;
                }
              }
            }
          }

          &__more-button {
            width: 100%;
            text-align: center;
            color: $font-color;
            border-radius: 5px;
            border: 1px solid $gray-line;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              box-shadow: 0 0 6px #00a7ff66;
              transition: all 0.2s ease;
            }

            > a > p {
              cursor: pointer;
            }
          }
        }
      }
    }

    &__remote-control {
      box-shadow: 0 0 6px #00000029;
      border-radius: 5px;
      position: fixed;

      &__wrapper {
        position: absolute;
      }

      > a > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid $gray-line;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #ffffffff;
        transition: all 0.2s ease-in;

        &:hover {
          border: 1px solid #00bfff;
        }
      }

      &__images {
        > img {
          width: 15px;
          height: 15px;
        }
      }

      &__name {
        > p {
          cursor: pointer;
        }
      }

      &__apy {
        cursor: pointer;
      }

      &__mining {
        border: 1px solid $gray-line;
        border-radius: 5px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        > p {
          color: $font-color;
          cursor: pointer;

          &:first-child {
            color: $gray-font-color;
          }
        }
      }
    }
  }

  // deposit end

  .reward {
    &__progress-bar {
      width: 100%;
      border: 0;
      color: #e6e6e6;

      &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }

      &__content {
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;

        &__fill {
          border-right: 1px solid $font-color;
          flex: 1 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          text-align: right;
        }
      }
    }

    &__token {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__image-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > img {
          position: relative;
          border-radius: 100%;
        }
      }

      > h2 {
        color: $font-color;
      }

      &__elfi {
        background: $background-color;
        border: 1px solid $gray-line;
        text-align: center;

        > p {
          color: #333333;
        }
      }

      &__container {
        box-shadow: 0 0 6px #00000029;
        border-radius: 5px;
      }

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $gray-line;

        > h2 {
          color: $font-color;

          &.el {
            > strong {
              color: $el-color;
            }
          }

          strong {
            color: $elysia-color;
          }
        }
      }

      &__staking {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray-line;

        > div {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          &:first-child {
            border-right: 1px solid $gray-line;
          }

          > p {
            color: $font-color;
          }

          > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            > h2 {
              color: $font-color;
            }

            > span {
              color: $gray-font-color;
            }
          }
        }
      }

      &__data {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $gray-line;

        > div {
          flex: 1;

          &:last-child {
            border-left: 1px solid $gray-line;
          }
        }

        &__content {
          > h2 {
            color: $font-color;
          }

          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            > p {
              color: $font-color;

              &:first-child {
                color: $gray-font-color;
              }
            }
          }
        }
      }

      @mixin arrow-transform($angle, $x: 0, $y: 0) {
        i:first-child {
          transform: translate($x, $y) rotate($angle);
        }

        i:last-child {
          transform: translate($x, -$y) rotate(-$angle);
        }
      }

      &__array-handler {
        position: relative;

        > div {
          position: absolute;
          cursor: pointer;
          transform: translate3d(0, 0, 0); // fixes flicker in webkit
          margin-top: -20px;
          filter: drop-shadow(0 3px 0 #00000029);
          -webkit-filter: drop-shadow(0 3px 0 #00000029);

          > i {
            position: absolute;
            top: 40%;
            left: 0;
            width: 35px;
            height: 1px;
            border-radius: calc(1px / 2);
            background: $elysia-color;
            transition: all 0.15s ease;
          }
        }

        &--left {
          i {
            transform-origin: 0% 50%;
          }
        }

        &--right {
          i {
            transform-origin: 100% 50%;
          }
        }

        .disabled {
          opacity: 0.3;
          cursor: default;
        }
      }

      &__lp {
        &__container {
          box-shadow: 0 0 6px #00000029;
          border-radius: 5px;
        }

        &__title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: left;
          border-bottom: 1px solid $gray-line;

          > img {
            &.last-token {
              outline: 1px solid #ffffff;
            }
          }

          > h2 {
            color: $font-color;
          }
        }

        &__content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          > div {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $gray-line;

            > p {
              color: $font-color;
            }

            > h2 {
              color: $font-color;
            }
          }

          &--right {
            border-left: 1px solid $gray-line;
          }
        }
      }
    }

    &__token-deposit {
      background: $background-color;
      box-shadow: 0 0 6px #00000029;
      border-radius: 5px;

      &__header {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $gray-line;

        > div {
          display: flex;
          flex-direction: column;

          > p {
            &:first-child {
              color: $font-color;
            }

            p {
              color: $gray-font-color;
            }
          }
        }
      }

      &__apy {
        border-bottom: 1px solid $gray-line;
        display: flex;
        flex-direction: row;

        > div {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            > p {
              color: $font-color;
            }

            > h2 {
              color: $font-color;
            }
          }
        }

        &--left {
          border-right: 1px solid $gray-line;
        }
      }

      &__data {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $gray-line;
        align-items: center;

        > div {
          flex: 1;

          &:last-child {
            border-left: 1px solid $gray-line;
          }
        }
      }
    }
  }

  // reward end

  .detail {
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > h2 {
        color: $font-color;
      }

      .DAI {
        > div {
          box-shadow: 0 1px 6px #00000029;
          text-align: center;
          cursor: pointer;
          @include hover__soft-background;

          &:hover {
            box-shadow: 0 0 6px #f9ae18;
            transition: all 0.2s ease;
          }

          > p {
            color: $font-color;
            cursor: pointer;
          }

          &.active {
            border: 1px solid #f9ae18;

            > p {
              font-family: $bold;
            }
          }
        }
      }

      .USDT {
        > div {
          box-shadow: 0 1px 6px #00000029;
          text-align: center;
          cursor: pointer;
          @include hover__soft-background;

          &:hover {
            box-shadow: 0 0 6px #26a17b;
            transition: all 0.2s ease;
          }

          > p {
            color: $font-color;
            cursor: pointer;
          }

          &.active {
            border: 1px solid #26a17b;

            > p {
              font-family: $bold;
            }
          }
        }
      }

      &__round {
        display: flex;
        flex-direction: row;
        justify-content: center;

        > div:first-child {
          margin-right: 20px;
        }

        > div:last-child {
          margin-left: 20px;
        }
      }
    }

    &__data-wrapper {
      border-radius: 5px;
      box-shadow: 0 0 6px #00000029;

      &__title {
        color: $elysia-color;
        border-bottom: 1px solid $gray-line;
      }

      &__total {
        border-bottom: 1px solid $gray-line;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
          justify-content: space-between;
          flex: 1;

          &:last-child {
            border-left: 1px solid $gray-line;
          }

          > h2 {
            color: $font-color;
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > div {
          flex: 1;

          &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > div {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              &:last-child {
                margin: 0;
              }
            }
          }

          &:last-child {
            border-left: 1px solid $gray-line;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > div {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              &:first-child {
                flex: 1;
                height: 100%;
              }
            }
          }
        }

        &__deposit {
          display: flex;
          flex-direction: row;
          justify-content: left;
          align-items: center;

          &__wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            > div {
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              align-items: center;

              > p {
                color: $font-color;
              }
            }
          }

          > p {
            color: $gray-font-color;
          }

          &__utilization {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > h2 {
              color: $font-color;
            }
          }

          &__strategy {
            flex-direction: column !important;
            border: 1px solid $gray-line;
            position: relative;

            > div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;

              > p:first-child {
                color: $gray-font-color;
              }

              > div {
                display: flex;
                flex-direction: row;
                align-items: center;

                > div {
                  border: 1px solid $gray-line;
                  border-radius: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;

                  > p {
                    cursor: pointer;
                  }
                }

                > p {
                  color: $gray-font-color;
                }
              }
            }

            &__defi-wrapper {
              border: 1px solid $gray-line;
              position: absolute;
              background: #ffffff;

              > div {
                display: flex;
                flex-direction: row;
                align-items: center;

                > p {
                  color: $gray-font-color;
                }
              }
            }
          }
        }
      }
    }

    &__graph {
      &__wrapper {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        > div {
          width: 100%;
          height: 300px;
          position: relative;

          > div:last-child {
            border-top: 1px solid black;
            position: absolute;
            width: 100%;
            height: 30px;
            bottom: -25px;

            > div {
              position: absolute;
              margin-top: 5px;
              width: 120px;
              text-align: center;
            }
          }
        }
      }

      &__converter {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        border-bottom: 1px solid $gray-line;

        &__button {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 3px solid #333333;
          position: relative;
          top: 1px;
          cursor: pointer;

          &.disable {
            border-bottom: 3px solid #b7b7b7;

            > h2 {
              color: $gray-font-color;
            }
          }

          > h2 {
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  // detail end

  .market {
    position: relative;

    &__guide {
      width: 1190px;
      margin: 0 auto;
      padding-top: 150px;

      &__header {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 150px;

        > h1 {
          font-size: 30px;
          margin-bottom: 30px;
        }

        > span {
          font-size: 12px;
          color: $gray-font-color;
        }
      }

      &__image {
        width: 90%;
        margin: 10px auto 30px;
        display: block;
      }

      &__content {
        span {
          font-size: 12px;
          color: $gray-font-color;
        }
      }
    }

    &__header {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 100px;

      > h1 {
        font-size: 30px;
        margin-bottom: 2px;
      }

      > div {
        width: 54px;
        height: 18px;
        background: #0087ff;
        border-radius: 5px;
        margin: 0 auto 10px;

        > p {
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
          font-family: $montserrat--bold;
        }
      }
    }

    &__content {
      > h2 {
        font-size: 18px;
        color: $font-color;
        width: 100%;
        border-bottom: 1px solid #00bfff;
        padding-bottom: 17px;
        margin: 47px 0;
      }

      > article {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        align-items: center;
        align-content: flex-start;
        width: 100%;
        height: initial;
        text-align: center;
        justify-items: center;
        gap: 35px 15px;

        > a {
          transition: all 200ms;

          &.disable {
            position: relative;
            z-index: 1;
            pointer-events: none;
            cursor: not-allowed;

            &::after {
              content: '';
              z-index: 2;
              position: absolute;
              width: 345px;
              height: 305px;
              left: 0;
              top: 0;
              border-radius: 5px;
              background: #00000013;
            }
          }

          > section {
            width: 345px;
            height: 305px;
            padding: 10px;
            box-shadow: 0 0 6px #00000029;
            border-radius: 5px;
            cursor: pointer;
            background: #ffffff;

            > figure {
              margin: 0;
              position: relative;

              > img {
                width: 325px;
                height: 210px;
                object-fit: cover;
              }

              > figcaption {
                top: 10px;
                left: 10px;
                position: absolute;
                width: 120px;
                height: 25px;
                line-height: 25px;
                font-size: 13px;
                text-align: center;
                border-radius: 13px;
                background-color: #d3f4ff;
                color: #00beff;

                &.completed {
                  font-weight: bold;
                  background-color: #d9edff;
                  color: #3ca3ff;
                }
              }
            }

            > div {
              margin-top: 10px;

              > p {
                color: #9d9d9d;
                text-align: left;
                font-size: 12px;
              }

              > b {
                margin-top: 5px;
                color: $font-color;
                text-align: right;
                font-size: 12px;
                display: block;

                > span {
                  margin-left: 5px;
                  font-size: 18px;

                  &::after {
                    content: '%';
                  }
                }
              }
            }

            > progress {
              width: 100%;
              margin-top: 5px;
              height: 10px;
              border: 0;
              vertical-align: 0;

              &::-webkit-progress-bar {
                height: 10px;
                border-radius: 5px;
                border: 0;
                background: #e6e6e6;
              }

              &::-webkit-progress-value {
                background: #0099cc;
                border: 0;
                height: 10px;
                border-radius: 5px;
              }

              &::-moz-progress-bar {
                background: #0099cc;
                border: 0;
                height: 10px;
                border-radius: 5px;
              }
            }
          }
        }

        > a:hover {
          transform: translateY(-4px);
        }
      }
    }
  }

  // market end

  .nft-details {
    &__guide {
      color: #00bfff;
      text-align: right;
      display: block;
      cursor: pointer;
      font-size: 13px;
    }

    &__header {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > div {
        > h1 {
          font-size: 30px;
          margin-bottom: 16.75px;
          color: $font-color;
        }

        > p {
          font-size: 15px;
          color: $font-color;
        }
      }
    }

    &__current-nfts {
      > article {
        display: flex;
        background-color: #ffffff;
        box-shadow: 0 0 6px #00000029;
        margin-top: 10px;
        border-radius: 10px;

        > section {
          flex: 1;

          &:last-child {
            border-left: 1px solid #e6e6e6;
          }

          > div {
            display: flex;
            padding: 18px 0 11px 28px;
            border-bottom: 1px solid #e6e6e6;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-right: 20px;

            > b {
              font-size: 18px;
              color: $font-color;

              > span {
                margin-left: 7px;
              }

              span.private {
                color: $sub-font-color;
                font-style: italic;
              }
            }
          }

          > section {
            height: 125px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > p {
              margin-top: 22px;
              font-size: 18px;
              color: $font-color;
              text-align: right;
              padding-right: 30px;
              display: block;
            }

            > b {
              margin-top: 22px;
              font-size: 25px;
              color: $font-color;
              text-align: right;
              padding-right: 30px;
              display: block;

              > span {
                color: #646464;
              }
            }

            > section {
              display: flex;
              gap: 30px;
              margin: 21px auto 19px;

              > a {
                @include hover__box-shadow;

                width: 250px;
                height: 30px;
                background: #3679b5;
                border-radius: 15px;
                cursor: pointer;
                font-size: 15px;
                color: #ffffff;
                border: 0;
                line-height: 30px;
                align-items: center;
                display: flex;
                text-align: center;
                justify-content: center;
                gap: 5px;

                &.green {
                  background-color: #55b96b;
                }
              }

              > button {
                @include hover__box-shadow;

                width: 250px;
                height: 30px;
                background: #00bfff;
                border-radius: 15px;
                cursor: pointer;
                font-size: 15px;
                color: #ffffff;
                border: 0;

                &.disabled {
                  pointer-events: none;
                  cursor: not-allowed;
                  background-color: #f0f0f1;
                  color: $font-color;
                }
              }
            }
          }
        }
      }
    }

    &__content {
      margin-top: 20px;
      padding: 40px 30px;
      background-color: #ffffff;
      box-shadow: 0 0 6px #00000029;
      border-radius: 5px;
    }

    &__purchase {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 20px;
      gap: 20px;

      &__header {
        display: flex;
        padding: 18px 28px 11px 28px;
        border-bottom: 1px solid #e6e6e6;
        flex-direction: row;
        align-items: center;
        height: initial !important;
        position: relative;

        > h2 {
          font-size: 18px;
          color: $font-color;
        }

        > a {
          margin-left: 7px;
          position: relative;
          cursor: pointer;

          > img {
            position: absolute;
            top: -10px;
            width: 18px;
            height: 18px;
          }
        }

        span.claim-completed {
          position: absolute;
          right: 28px;
          font-weight: bold;
          font-size: 13px;
          color: #707070;
          border-radius: 13px;
        }
      }

      &__progress-section {
        padding: 0 28px;
        padding-bottom: 18px;
        border-top: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all 0.3s ease-in;

        > section {
          margin-top: 24px;

          > b {
            font-size: 18px;
          }

          > div {
            margin-top: 10px;
            padding: 21px;
            border: 1px solid #e6e6e6;
            border-radius: 10px;

            > div {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              justify-content: space-between;

              > p {
                color: #707070;
                font-size: 15px;
              }

              > b {
                font-size: 18px;
                color: #333333;
              }
            }

            > progress {
              width: 100%;
              margin-top: 10px;
              height: 10px;
              border: 0;
              vertical-align: 0;

              &::-webkit-progress-bar {
                height: 10px;
                border-radius: 10px;
                border: 0;
                background: #e6e6e6;
              }

              &::-webkit-progress-value {
                background: #3c70e5;
                border: 0;
                border-radius: 10px;
                height: 10px;
              }

              &::-moz-progress-bar {
                background: #3c70e5;
                border: 0;
                height: 10px;
                border-radius: 10px;
              }
            }
          }
        }

        > span {
          font-size: 10px;
          display: block;
          text-align: right;
          color: $gray-font-color;
          margin-top: 8px;
        }
      }

      &__claims-section {
        padding: 0 28px;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in;

        div.nft-details__purchase__claim-details {
          margin-top: 27px;
          background: #f6f6f8;
          border: 1px solid #f0f0f1;
          border-radius: 5px;
          height: 100%;
          overflow: auto;

          table {
            border-collapse: collapse;
            table-layout: fixed;
            text-align: center;
            width: 100%;

            .number {
              width: 20%;
            }

            .address {
              width: 40%;
            }

            .amount {
              width: 40%;
            }

            th {
              font-size: 15px;
              color: #333333;
              padding: 10px 0;
              font-weight: 500;
            }

            td {
              font-weight: bold;
              font-size: 15px;
              padding: 10px 20px;

              span {
                font-family: Inter-Bold;
                font-size: 15px;
                color: inherit;
              }
            }

            tr.self {
              color: #00a6ff;
            }

            thead {
              tr {
                border-bottom: 1px solid #e6e6e6;
              }
            }

            th:last-child,
            td:last-child {
              text-align: right;
              padding-right: 30px;
            }
          }
        }
      }

      &__footer {
        border-top: 1px solid #e6e6e6;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;

        > p {
          margin: 9px;
          color: #333333;
          font-size: 15px;
        }
      }

      &__arrow {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-top: 2px solid #3d81db;
        border-right: 2px solid #3d81db;
        text-align: center;
        transition: all 0.3s ease-in;
      }

      &__claims {
        flex: 1;
        height: initial;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > section {
          &:nth-child(2) {
            margin-top: 20px;
          }

          border-radius: 10px;
          box-shadow: 0 0 6px #00000029;
          background-color: #ffffff;
        }

        div.nft-details__purchase__amount {
          padding: 9px 28px;
          display: flex;
          justify-content: flex-end;
          font-size: 18px;
        }
      }

      &__status {
        flex: 1;
        // width: 585px;
        height: initial;
        border-radius: 10px;
        box-shadow: 0 0 6px #00000029;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > div {
          height: 114px;
        }
      }

      > section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &__round {
        // width: 585px;
        height: initial;
        border-radius: 10px;
        box-shadow: 0 0 6px #00000029;
        background-color: #ffffff;

        > div {
          height: 114px;
        }
      }

      &__round {
        > div {
          padding: 18px 28px 18px;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            > b {
              font-size: 30px;
              color: $font-color;
            }

            > div {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 10px;
              width: 44px;

              > b {
                font-size: 30px;
                color: $font-color;
              }

              > p {
                font-size: 13px;
                color: #707070;
              }
            }
          }
        }
      }

      &__status {
        > div {
          padding: 18px 28px;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            > p {
              color: #707070;
              font-size: 15px;
            }

            > b {
              font-size: 18px;
              color: #333333;
            }
          }

          > progress {
            width: 100%;
            margin: 10px 0;
            height: 10px;
            border: 0;
            vertical-align: 0;

            &::-webkit-progress-bar {
              height: 10px;
              border-radius: 10px;
              border: 0;
              background: #e6e6e6;
            }

            &::-webkit-progress-value {
              background: #0099cc;
              border: 0;
              border-radius: 10px;
              height: 10px;
            }

            &::-moz-progress-bar {
              background: #0099cc;
              border: 0;
              height: 10px;
              border-radius: 10px;
            }
          }
        }
      }
    }

    &__product-point {
      margin-bottom: 50px;

      > h2 {
        font-size: 18px;
        margin-bottom: 40px;
      }

      > div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: center;
        justify-items: center;
        grid-gap: 35px 15px;

        > section {
          width: 400px;
          display: flex;
          flex-direction: row;

          > img {
            width: 90px;
            height: 90px;
            object-fit: scale-down;
          }

          > div {
            margin-left: 18px;
            display: flex;
            flex-direction: column;
            text-align: left;
            flex: 1;

            > h3 {
              font-size: 15px;
              color: $font-color;
              margin-bottom: 10px;
            }

            > p {
              font-size: 15px;
              color: #646464;
            }
          }
        }
      }
    }

    &__tab {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &__wrapper {
        > div {
          display: flex;
          flex-direction: row;
          margin-bottom: 40px;
          align-items: center;
          margin-top: 70px;

          > h2 {
            font-size: 18px;
            margin-right: 10px;
          }

          > a {
            height: 18px;
          }
        }
      }

      > div {
        flex: 1;
        text-align: center;
        border-top: 3px solid #00bfff;
        border-left: 1px solid #e4e5e4;
        border-right: 1px solid #e4e5e4;
        cursor: pointer;

        > b {
          margin: 15px auto 11px;
          color: #000000;
          cursor: pointer;
          display: block;
          font-size: 16px;
        }

        &.disable {
          background-color: #f8f8f7;
          border: 1px solid #e4e5e4;
          transition: all 0.2s ease;

          &:hover {
            background-color: #f0f0f1;
          }

          &:active {
            background-color: #e4e5e4;
          }

          > b {
            color: #b7b7b7;
          }
        }
      }
    }

    &__nft-info {
      margin-top: 50px;

      > section {
        display: flex;
        flex-direction: row;
        align-items: center;

        > h2 {
          font-size: 18px;
          margin-right: 8px;
        }

        > a {
          position: relative;
          cursor: pointer;

          > img {
            position: absolute;
            top: -10px;
            width: 18px;
            height: 18px;
          }
        }
      }

      > div.nft-info__contents {
        display: flex;
        flex-direction: row;
        margin-top: 22px;

        > figure {
          margin: 0;

          > video {
            width: 527px;
            border-radius: 5px;
          }
        }

        > table {
          margin-left: 24px;
          box-shadow: 0 0 0 1px #e3e3e3;
          border-collapse: collapse;
          border-radius: 10px;
          border-style: hidden;
          font-size: 15px;

          tbody > tr {
            height: 38px;

            > th {
              width: 131px;
              background-color: #f8f9fa;
            }

            > td {
              padding-left: 20px;

              > ul {
                margin: 0;
                padding: 0;

                > li {
                  margin: 10px 0 10px 10px;

                  &::marker {
                    font-size: 10px;
                  }
                }
              }

              > div {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                padding-right: 20px;

                > a {
                  @include hover__sub_box-shadow;

                  height: 35px;
                  border: 1px solid #3679b5;
                  border-radius: 5px;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  color: #3679b5;
                  padding: 5px 10px 5px 0;
                  cursor: pointer;
                  font-size: 12px;

                  > img {
                    margin: 0 10px;
                  }
                }
              }
            }

            > th,
            > td {
              border: 1px solid #e3e3e3;
            }

            &:first-child {
              > th {
                border-radius: 10px 0 0 0;
              }
            }

            &:last-child {
              > th {
                border-radius: 0 0 0 10px;
              }
            }
          }
        }
      }
    }

    &__borrower {
      margin-top: 60px;

      &__youtube {
        width: 100%;
        height: 620px;
      }

      > table {
        width: 100%;
        box-shadow: 0 0 0 1px #e3e3e3;
        border-collapse: collapse;
        border-radius: 10px;
        border-style: hidden;
        font-size: 15px;
        table-layout: fixed;

        > tr {
          height: 38px;
          text-align: left;

          > th {
            padding: 10px 17px;
            width: 282px;
            background-color: #f8f9fa;
          }

          > td {
            padding: 10px 17px;
            word-break: break-all;

            > div {
              display: flex;
              flex-direction: column;
              gap: 8px;

              > a {
                @include hover__sub_box-shadow;

                width: max-content;
                padding: 5px 10px 5px 0;
                height: 35px;
                border: 1px solid #3679b5;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                color: #3679b5;
                cursor: pointer;

                > img {
                  margin: 0 10px;
                }
              }
            }
          }

          > th,
          > td {
            border: 1px solid #e3e3e3;
          }

          &:last-child {
            > th {
              border-radius: 0 0 0 10px;
            }
          }
        }
      }
    }

    // KR상품의 width를 조정하기 위함

    &__real-estate-info__table__kr {
      tr {
        td {
          color: #707070;
        }
      }
    }

    &__real-estate-info {
      margin-top: 45px;
      font-family: SpoqaHanSansNeo;

      > h2 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      > table {
        width: 100%;
        box-shadow: 0 0 0 1px #e3e3e3;
        border-collapse: collapse;
        border-radius: 10px;
        border-style: hidden;
        font-size: 15px;
        table-layout: fixed;
        margin: 10px 0 5px;

        > tr {
          height: 38px;
          text-align: left;

          > th {
            padding: 11px 17px;
            width: 131px;
            background-color: #f8f9fa;
          }

          > td {
            padding: 11px 17px;

            > div {
              display: flex;
              align-items: center;
              transition: all 0.2s ease;

              &:hover {
                text-shadow: 0 0 15px #00a7ff66;
                transform: translateY(-3px);

                &::after {
                  position: absolute;
                  transform: translateY(6px);
                }
              }

              &:active {
                transform: translateY(-1px);
                text-shadow: 0 0 5px #00a7ff66;
              }

              > a {
                margin-right: 5px;
                color: $elysia-color;
              }

              > p {
                color: $elysia-color;
              }
            }
          }

          > th,
          > td {
            border: 1px solid #e3e3e3;
          }

          &:last-child {
            > th {
              border-radius: 0 0 0 10px;
            }
          }
        }
      }

      &__asset-feature {
        margin-top: 70px;

        > h2 {
          font-size: 18px;
          margin-bottom: 20px;
        }

        > div {
          display: flex;
          flex-direction: row;

          > section {
            height: 284px;

            &:first-child {
              display: flex;
              flex-direction: row;
              margin-right: 40px;

              > img {
                width: 410px;
                margin-right: 6px;
              }

              > div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 6px;

                > figure {
                  position: relative;
                  flex: 1;
                  cursor: pointer;
                  transition: all 0.2s ease;

                  &:not(.disable) {
                    &:hover {
                      filter: brightness(120%);
                    }
                  }

                  &.disable {
                    &::after {
                      content: '';
                      width: 113px;
                      height: 100%;
                      z-index: 2;
                      left: 0;
                      border-radius: 5px;
                      position: absolute;
                      background: rgba(0, 0, 0, 0.6);
                      transition: all 0.2s ease;
                    }

                    &:hover {
                      &::after {
                        transition: all 0.2s ease;
                        background: rgba(0, 0, 0, 0.3);
                      }
                    }

                    &:active {
                      &::after {
                        transition: all 0.2s ease;
                        background: rgba(0, 0, 0, 0);
                      }
                    }
                  }

                  > img {
                    width: 113px;
                    height: 100%;
                    border-radius: 5px;
                    position: relative;
                    object-fit: cover;
                  }
                }
              }
            }

            &:last-child {
              height: 284px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;

              > div {
                display: flex;

                &:last-child {
                  margin: 0;
                }

                > div {
                  display: flex;
                  flex-direction: column;

                  > b {
                    font-size: 15px;
                    margin-bottom: 10px;
                  }

                  > p {
                    font-size: 15px;
                    color: #646464;
                  }
                }

                > b {
                  color: #00a6ff;
                  margin-right: 13px;
                }
              }
            }
          }
        }
      }

      &__around-asset {
        margin-top: 70px;

        > h2 {
          font-size: 18px;
          margin-bottom: 10px;
        }

        > p {
          margin-bottom: 20px;
          font-size: 15px;
        }

        > section {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 10px 13px 14px;
          border: 1px solid #e3e3e3;

          > div {
            width: 330px;
            transition: all 0.2s ease;

            &:hover {
              transform: translateY(-3px);

              &::after {
                position: absolute;
                transform: translateY(6px);
              }
            }

            &:active {
              transform: translateY(-1px);
            }

            > img {
              border-radius: 5px;
              margin-bottom: 7px;
              width: 330px;
              height: 222px;
              object-fit: cover;
            }

            > b {
              color: $font-color;
              font-size: 15px;
              margin-bottom: 7px;
            }

            > table {
              width: 100%;
              box-shadow: 0 0 0 1px #e3e3e3;
              border-collapse: collapse;
              border-style: hidden;
              font-size: 15px;
              table-layout: fixed;
              margin: 12px 0 5px;

              > tr {
                height: 29px;
                text-align: left;

                > th {
                  padding: 5px 12px;
                  width: 131px;
                  background-color: #f8f9fa;
                }

                > td {
                  padding: 5px 12px;

                  > div {
                    display: flex;
                    align-items: center;

                    > a {
                      margin-right: 5px;
                    }
                  }
                }

                > th,
                > td {
                  border: 1px solid #e3e3e3;
                }
              }
            }
          }
        }

        > span {
          font-size: 12px;
          color: $gray-font-color;
          text-align: right;
          margin-top: 7px;
          display: block;
        }
      }
    }

    &__terms {
      margin-top: 65px;

      > b {
        font-size: 18px;
        margin: 0 0 20px;
        display: block;
      }

      > ul {
        padding: 0;

        > li {
          margin-left: 15px;
        }
      }
    }

    & > div {
      text-align: center;

      > button {
        @include hover__box-shadow--white;

        margin-top: 50px;
        width: 148px;
        height: 35px;
        background: #00bfff;
        border-radius: 35px;
        cursor: pointer;
        font-size: 16px;
        line-height: 35px;
        color: #ffffff;
        border: 0;
        flex: 1;
        padding: 0 !important;

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          background-color: #f0f0f1;
          color: $font-color;
        }
      }
    }

    &__faq {
      margin-top: 50px;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > h2 {
          font-size: 18px;
        }

        > a {
          font-size: 15px;
          color: $elysia-color;
          cursor: pointer;

          &::after {
            content: ' >';
          }
        }
      }

      &__content {
        margin-top: 30px;

        > div {
          @include hover__sub_box-shadow;

          border: 1px solid #f0f0f1;
          border-radius: 5px;
          padding: 18px 30px;
          background-color: $background-color;
          margin-bottom: 20px;
          overflow: hidden;
          transition: all 0.5s cubic-bezier(0.28, 0.6, 0.57, 0.96);

          > div {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > h3 {
              margin: 0;
              font-size: 18px;

              > span {
                font-family: SpoqaHanSansNeo-Bold;
                font-weight: 900;
                color: $elysia-color;
                margin-right: 10px;
              }
            }
          }

          > p {
            margin-top: 30px;
            font-size: 15px;
            color: $sub-font-color;
            margin-bottom: 14px;
            line-height: 25px;
          }
        }
      }
    }

    &__news {
      margin-top: 80px;

      > h2 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      > section {
        display: grid;
        grid-template-columns: repeat(3, 340px);
        grid-template-rows: 1fr;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 30px;
        border: 0;
        grid-gap: 45px;
        gap: 45px;

        > a {
          @include hover__sub_box-shadow;

          border: none;
          cursor: pointer;
          width: 340px;
          box-shadow: 0 0 6px #00000029;
          border-radius: 5px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          > img {
            width: 320px;
            height: 222px;
            object-fit: fill;
          }

          > b {
            margin-top: 20px;
            font-size: 12px;
            color: #262626;
            display: block;
            margin-bottom: 6px;
          }

          > p {
            margin-top: 7px;
            font-size: 15px;
            color: #262626;
          }

          > div {
            width: 148px;
            height: 35px;
            background: #333333;
            border-radius: 30px;
            cursor: pointer;
            color: #ffffff;
            display: block;
            text-align: center;
            line-height: 35px;
            margin: 10px auto 0;
          }
        }
      }
    }
  }

  // nft-details end

  .faq {
    &__switch {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      margin-top: 20px;

      > button {
        border: 0;
        width: 150px;
        height: 30px;
        background: #00bfff;
        border-radius: 15px;
        cursor: pointer;
        font-size: 15px;
        color: #ffffff;

        &.disable {
          border: 1px solid #00bfff;
          background-color: #ffffff;
          color: #00bfff;
        }
      }
    }
  }

  .governance {
    &__asset {
      box-shadow: 0 0 6px #00000029;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-7px);
        box-shadow: 0 0 15px #00a7ff66;
        transition: all 0.3s ease;
      }

      &__progress-bar {
        width: 100%;
        color: $gray-line;
        border: 0;

        &::-webkit-progress-value {
          background: $governance-progress-00;
        }

        &.index-0 {
          &::-webkit-progress-value {
            background: $governance-progress-00;
          }

          &::-moz-progress-bar {
            background: $governance-progress-00;
          }
        }

        &.index-1 {
          &::-webkit-progress-value {
            background: $governance-progress-01;
          }

          &::-moz-progress-bar {
            background: $governance-progress-01;
          }
        }

        &.index-2 {
          &::-webkit-progress-value {
            background: $governance-progress-02;
          }

          &::-moz-progress-bar {
            background: $governance-progress-02;
          }
        }

        &__content {
          width: 100%;
          height: 29px;
          top: 5px;

          &__fill {
            > p {
              font-size: 10px;
              margin-right: 3.5px;
              cursor: pointer;
            }
          }
        }
      }

      > div {
        > img {
          width: 100%;
        }

        &:last-child {
          > div {
            display: flex;
            border-bottom: 1px solid $gray-line;

            > p {
              cursor: pointer;
              color: $font-color;
            }

            &:last-child {
              display: block;
              padding: 0 30px 20px;

              > div > h2 {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    &__grid {
      display: grid;
      grid-template-rows: 1fr;
      box-sizing: border-box;
      justify-content: space-between;
    }

    &__header {
      > div:first-child {
        border-bottom: 1px solid $elysia-color;
      }
    }

    &__elyfi-graph {
      border: 1px solid $gray-line;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      background: $background-color;

      &__arrow-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .arrow-wrapper {
          width: 100%;
          display: flex;
          -webkit-align-items: center;
          align-items: center;
          -webkit-flex-direction: row;
          flex-direction: row;

          > .line {
            height: 0;
            width: 100%;
            border-bottom: 1px dashed $elysia-color;
          }

          > .left-arrow {
            width: 0;
            height: 0;
            border-right: 10px solid $elysia-color;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
          }

          > .right-arrow {
            width: 0;
            height: 0;
            border-left: 10px solid $elysia-color;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
          }
        }
      }

      > div {
        flex: 1;

        &:first-child {
          display: flex;
          justify-content: right;

          > div {
            background-color: $background-color;
            box-shadow: 0 0 6px #00000029;
            border-radius: 10px;
            width: 90%;

            > p {
              font-family: $bold;
              color: $font-color;
              text-align: center;
            }
          }
        }

        &:last-child {
          display: flex;
          justify-content: left;

          > div {
            background-color: $background-color;
            box-shadow: 0 0 6px #00000029;
            border-radius: 10px;
            width: 90%;

            > p {
              font-family: $bold;
              color: $font-color;
              text-align: center;
            }
          }
        }
      }
    }

    &__content {
      text-align: center;
      margin-top: 30px;

      &__button {
        margin: 0 40px;
        width: 183px;

        // temp
        cursor: pointer;
        box-shadow: 0 0 6px #00000029;

        > p {
          cursor: pointer;
        }

        &__wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;

          > div:first-child {
            @include hover__sub_box-shadow;

            background-color: $background-color;
          }

          > div:last-child {
            @include hover__sub_box-shadow;

            background-color: $background-color;
          }
        }
      }
    }

    .zero {
      width: 100%;
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border: 1px solid #e6e6e6;
      padding: 10px;
      text-align: center;

      > p {
        color: #707070;
        font-size: 15px;
      }
    }
  }

  // governance end

  .loan {
    &__list {
      &--null {
        border: 1px solid $gray-line;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        height: 280px;
        width: 100%;
        border-radius: 5px;

        > p {
          font-size: 15px;
          color: $gray-font-color !important;
        }
      }
    }

    > div {
      border-bottom: 1px solid $gray-line;

      > h2 {
        color: $font-color;
      }

      > p {
        color: $font-color;
      }
    }
  }

  .portfolio {
    &__view-button {
      width: 100%;
      background: #ffffff;
      cursor: pointer;
      border: 1px solid #e6e6e6;
      color: #333333;
      border-radius: 5px;
    }

    &__borrower {
      border-radius: 5px;
      border: 1px solid $gray-line;

      &__title {
        color: $font-color;
        border-bottom: 1px solid $gray-line;
      }

      &__header {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $gray-line;

        > div {
          > p {
            text-align: center;

            &:first-child {
              color: $gray-font-color;
            }

            &:last-child {
              color: $font-color;
            }
          }
        }
      }

      &__data {
        display: flex;

        > div {
          flex: 1;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            > p {
              &:first-child {
                color: $gray-font-color;
              }

              &:last-child {
                color: $font-color;
              }
            }
          }
        }

        &--right {
          border-left: 1px solid $gray-line;
        }
      }
    }

    &__collateral {
      border: 1px solid $gray-line;
      border-radius: 5px;

      > h2 {
        border-bottom: 1px solid $gray-line;
      }

      &__data {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $gray-line;

        > div {
          flex: 1;
        }

        &--left {
          > div {
            width: 100%;
            height: 100%;
          }
        }

        &--right {
          border-left: 1px solid $gray-line;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &:last-child {
              margin: 0;
            }

            > p {
              &:first-child {
                color: $gray-font-color;
              }

              &:last-child {
                color: $font-color;
              }
            }
          }
        }
      }
    }
  }

  // portfolio end

  .staking {
    &__v2 {
      width: 1190px;
      margin: 0 auto;
      padding-top: 100px;
      position: relative;

      &__title {
        text-align: center;
        margin-top: 80px;
        margin-bottom: 220px;

        > h2 {
          font-size: 30px;
          margin-bottom: 16px;
          color: $font-color;
        }

        > p {
          color: $font-color;
          font-size: 15px;
        }
      }

      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > div:first-child {
          display: flex;
          align-items: center;
          width: 100%;
          margin-left: 30px;
          margin-bottom: 18px;

          > div {
            display: flex;
            flex-direction: row;

            > img {
              width: 37px;
              height: 37px;

              &:last-child {
                margin-left: -6px;
              }
            }
          }

          > h2 {
            font-size: 18px;
            color: $font-color;
            margin-left: 8px;
          }
        }
      }

      &__container {
        margin-bottom: 100px;
      }

      &__content {
        margin-top: 40px;

        > div {
          background-color: $background-color;

          &:first-child {
            border-radius: 10px;
            border: 1px solid $gray-line;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            z-index: 2;
            position: relative;

            > div {
              flex: 1;
              padding: 15px 30px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              &:first-child {
                border-right: 1px solid $gray-line;
              }

              > p {
                font-size: 15px;
                color: $font-color;
              }

              > h2 {
                font-size: 15px;
                color: $font-color;
              }
            }
          }

          &:last-child {
            width: calc(100% - 4px);
            margin: 0 auto;
            padding: 20px 30px 16px;
            position: relative;
            bottom: 5px;
            border: 1px solid $gray-line;
            border-radius: 0 0 10px 10px;
            z-index: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > div {
              width: 179px;
              height: 30px;
              border-radius: 30px;
              background: #ffffff;
              @include hover__sub_box-shadow;

              box-shadow: 0 0 6px #00000029;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              > p {
                cursor: pointer;
                color: $font-color;
              }
            }
          }
        }
      }
    }

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 80px;

      > h2 {
        color: $font-color;
      }

      > p {
        text-align: center;
        color: $font-color;
      }

      &__button {
        background: #ffffff;
        @include hover__box-shadow;

        box-shadow: 0 0 6px #00000029;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > div {
          cursor: pointer;

          > p {
            cursor: pointer;
            color: $font-color;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $elysia-color;

        > p {
          width: 100%;
        }

        > div {
          flex: 1;
        }

        &__token-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          > img:nth-child(2) {
            margin-left: -7px;
          }

          > h2 {
            color: $font-color;
          }
        }
      }
    }

    &__round {
      display: flex;

      $current-data-height: 110px;

      .token-amount {
        color: $sub-font-color;
      }

      &__border {
        border: 1px dotted $elysia-color;
        border-right: 0;
        margin-top: calc(#{$current-data-height} / 2);
      }

      &__container {
        flex: 1;
      }

      &__button {
        &__wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;
        }

        @include hover__sub_box-shadow;

        box-shadow: 0 0 6px #00000029;
        cursor: pointer;

        > p {
          margin: 0;
          cursor: pointer;
          text-align: center;
        }

        &.disable {
          pointer-events: none;
          border: 0;
          cursor: not-allowed;
          background-color: $gray-line;
          box-shadow: none;

          > p {
            cursor: not-allowed;
            color: $gray-font-color;
          }
        }
      }

      &__current-data {
        border: 1px solid $gray-line;
        border-radius: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: $current-data-height;
        align-items: center;
        background-color: #ffffff;

        > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          height: 100%;

          > h2 {
            color: $font-color;
          }

          > div {
            display: flex;
            justify-content: flex-start;

            > p {
              color: $gray-font-color;

              strong {
                color: $elysia-color;
              }
            }
          }

          &:last-child {
            text-align: right;

            > p {
              color: $sub-font-color;
            }

            > h2 {
              color: $font-color;
            }
          }
        }
      }

      // current-data end

      &__previous {
        &__wrapper {
          border: 1px solid $gray-line;
          box-sizing: border-box;
          border-radius: 10px;
          display: flex;
          flex-direction: column-reverse;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        background-color: #ffffff;

        &:last-child {
          border: 0;
        }

        &__title {
          border-radius: 10px;
          background-color: #f8f8f8;
          text-align: center;
          height: 100%;
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;

          > h2 {
            color: $gray-font-color;
          }

          > p {
            color: $gray-font-color;
          }
        }

        &__body {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 100%;

          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > p {
              color: $font-color;
            }

            > h2 {
              color: $font-color;
            }
          }
        }

        > div:first-child {
          display: flex;
          height: 100%;
        }
      }

      // previous end

      &__header {
        margin: 45px 0 0;
        height: 45px;
        border-radius: 10px;
        border: 1px solid $gray-line;
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        > div {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 15px 23px;

          &:first-child {
            border: 1px solid $gray-line;
          }

          > p {
            font-size: 15px;
          }

          > h2 {
            font-size: 15px;
          }
        }
      }

      &__remaining-data {
        border-radius: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        border: 1px solid $gray-line;
        background-color: #ffffff;

        &__title {
          border-right: 1px solid #e6e6e6;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          > div:first-child {
            > h2 {
              color: $elysia-color;
            }

            > p {
              color: $font-color;
            }
          }

          > div:last-child {
            border: 1px solid $gray-line;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > p {
              color: $font-color;
            }

            > h2 {
              color: $font-color;
            }
          }
        }

        &__body {
          display: flex;
          flex: 1;

          > div {
            width: 100%;

            &:first-child {
              border-right: 1px solid $gray-line;
            }

            > h2 {
              color: $font-color;
            }

            > div {
              border-top: 1px solid #e6e6e6;
              display: flex;
              flex-direction: column;

              > h2 {
                text-align: right;
                color: $font-color;

                > span {
                  margin-left: 5px;
                }
              }

              > p {
                text-align: right;
                color: $gray-font-color;

                > span {
                  color: $gray-font-color;
                }
              }

              > div {
                margin: 0 auto;
              }
            }
          }
        }
      }

      .waiting {
        background-color: #f8f8f8;

        .staking__round__remaining-data__title {
          > div:first-child {
            > p,
            h2 {
              color: #888888;
            }
          }

          > div:last-child {
            background-color: #ffffff;
            justify-content: flex-end;
          }
        }
      }
    }

    &__lp {
      &__header {
        text-align: center;
        margin-top: 80px;

        > h2 {
          color: $font-color;
        }

        > p {
          color: $font-color;
        }

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;

          > div {
            background: #ffffff;
            box-shadow: 0 1px 6px #00000029;
            text-align: center;
            cursor: pointer;
            @include hover__soft-background;

            > p {
              color: $font-color;
              cursor: pointer;
            }

            &.active {
              border: 1px solid $elysia-color;

              > p {
                font-family: $bold;
              }
            }
          }
        }
      }

      &__detail-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__container {
          flex: 1;
        }

        &__header {
          display: flex;
          flex-direction: row;
          justify-content: left;
          align-items: center;
          box-shadow: 0 0 6px #00000029;
          border-radius: 5px;
          position: relative;
          background: $background-color;

          > img {
            &.second-token {
              position: relative;
            }
          }

          > h2 {
            color: $font-color;
          }
        }

        &__body {
          border: 1px solid $gray-line;
          border-radius: 0 0 5px 5px;
          background-color: #ffffff;
        }

        &__item-header {
          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $gray-line;

            > div {
              display: flex;
              align-items: center;
              justify-content: center;

              > p {
                color: $font-color;
              }
            }

            > h2 {
              color: $font-color;
            }

            &:last-child {
              border: 0;
            }
          }
        }

        &__receive-token {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }

          &__button {
            @include hover__box-shadow;

            box-shadow: 0 1px 6px #00000029;
            text-align: center;
            cursor: pointer;
            background: #ffffff;

            > p {
              cursor: pointer;
              color: $font-color;
            }
          }

          &__content {
            > p {
              color: $sub-font-color;
            }
          }
        }

        &__staking {
          &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > div:first-child {
              display: flex;
              align-items: center;
            }
          }

          &__button {
            @include hover__box-shadow;

            box-shadow: 0 1px 6px #00000029;
            text-align: center;
            cursor: pointer;
            background: #ffffff;

            > p {
              cursor: pointer;
              color: $font-color;
            }

            &.disable {
              background: $gray-line;
              cursor: not-allowed;
              pointer-events: none;

              > p {
                color: $gray-font-color;
                cursor: not-allowed;
                pointer-events: none;
              }
            }
          }

          &__value {
            text-align: right;

            > h2 {
              color: $font-color;
            }
          }
        }

        &__time-data {
          text-align: right;
          color: $sub-font-color;
        }
      }

      // detail-box end

      &__staked {
        &__header {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $elysia-color;

          > h2 {
            color: $font-color;
          }
        }

        &__table {
          border: 1px solid $gray-line;
          background-color: #ffffff;

          &__header {
            display: flex;
            justify-content: space-between;

            &__container {
              display: flex;

              &--left {
                flex: 5;
                display: flex;
                flex-direction: row;
                text-align: center;
                color: $font-color;

                > div {
                  &:nth-child(1) {
                    flex: 2;
                  }

                  &:nth-child(2) {
                    flex: 2;
                  }

                  &:nth-child(3) {
                    flex: 3;
                  }

                  &:nth-child(4) {
                    flex: 4;
                  }
                }
              }

              &--right {
                flex: 2;
                text-align: center;
                color: $font-color;
              }
            }
          }

          &__content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__button {
              box-shadow: 0 1px 6px #00000029;
              text-align: center;
              cursor: pointer;
              background: $background-color;

              > p {
                cursor: pointer;
                color: $font-color;
              }
            }

            &--left {
              flex: 5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              border: 1px solid $gray-line;
              border-radius: 5px;

              > div {
                display: flex;
                justify-content: center;
                align-items: center;

                > h2 {
                  color: $font-color;
                }

                &:nth-child(1) {
                  flex: 2;
                }

                &:nth-child(2) {
                  flex: 2;
                }

                &:nth-child(3) {
                  flex: 3;
                }

                &:nth-child(4) {
                  flex: 4;
                  flex-direction: column;
                }
              }
            }

            &--center {
              display: flex;
              justify-content: center;

              > div {
                width: 0;
                height: 0;
                border-left: 10px solid $font-color;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
              }
            }

            &--right {
              flex: 2;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              border: 1px solid $gray-line;
              border-radius: 5px;

              > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              }

              &__image {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;

                > h2 {
                  color: $font-color;
                }
              }

              &__reward {
                display: flex;
                flex-direction: row;

                &__amount {
                  color: $font-color;
                }

                &__unit {
                  color: $gray-font-color;
                }
              }
            }
          }
        }

        &__reward {
          box-shadow: 0 0 6px #00000029;
          border-radius: 5px;
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          background: $background-color;

          &__total-liquidity {
            flex: 2;
            justify-content: space-evenly;
            display: flex;
            border-right: 1px solid $gray-line;

            > h2 {
              color: $font-color;

              &:first-child {
                color: $gray-font-color;

                &::after {
                  content: ':';
                  margin-left: 5px;
                }
              }
            }
          }

          &__amount {
            display: flex;
            flex: 5;
            justify-content: space-around;
            flex-direction: row;
            align-items: center;

            > h2 {
              color: $gray-font-color;

              &:first-child {
                color: $gray-font-color;

                &::after {
                  content: ':';
                  margin-left: 5px;
                }
              }
            }

            > div {
              display: flex;
              flex-direction: row;
              align-items: center;

              > span {
                color: $font-color;
              }

              > h2 {
                color: $gray-font-color;
              }
            }
          }
        }
      }

      &__reward {
        &__button {
          @include hover__box-shadow;

          box-shadow: 0 1px 6px #00000029;
          text-align: center;
          cursor: pointer;
          background: $background-color;

          > p {
            cursor: pointer;
            color: $font-color;
          }

          &__wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
          }
        }

        &__header {
          display: flex;
          align-items: center;
          border-bottom: 1px solid $elysia-color;
        }

        &__content {
          border: 1px solid $gray-line;

          &__container {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $gray-line;
            background-color: #ffffff;

            > div {
              flex: 1;
            }
          }

          &__wrapper {
            border-right: 1px solid $gray-line;

            &:last-child {
              border: 0;
            }
          }

          &__amount {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;

            > h2 {
              color: $font-color;

              &:last-child {
                color: $gray-font-color;
              }
            }
          }
        }

        &__image {
          display: flex;
          flex-direction: row;
          justify-content: left;
          align-items: center;

          > h2 {
            color: $font-color;
          }
        }
      }
    }

    &__coming-soon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: #ffffff;

      &.el {
        border: 1px solid #3679b5;

        > div {
          border: 1px solid #3679b5;
        }
      }

      &.elfi {
        border: 1px solid $elysia-color;

        > div {
          border: 1px solid $elysia-color;
        }
      }

      &.lp {
        border: 1px solid $elysia-color;

        > div {
          border: 1px solid #627eea;

          &:last-child {
            border: 1px solid #fabf41;
          }
        }
      }

      > div {
        border-radius: 100%;
        background: #ffffff;
        position: absolute;

        &:first-child {
          border: 0;
          width: 100%;
          text-align: center;
        }

        > h2 {
          color: $font-color;
        }
      }
    }

    &__el {
      height: calc(100vh - 322px);
      min-height: 100%;
      margin: 0 auto;
      width: 1190px;

      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
        height: 100%;
        text-align: center;

        > h2 {
          font-size: 30px;
          color: $font-color;
          margin-bottom: 25px;
        }

        > p {
          font-size: 15px;
          line-height: 25px;
          color: $font-color;
          margin-bottom: 25px;
        }

        > div {
          @include hover__box-shadow;

          width: 220px;
          height: 35px;
          border-radius: 35px;
          box-shadow: 0 0 6px #00000029;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          > p {
            font-size: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }

  // staking end

  .legacy {
    min-height: calc(100vh - 322px);
    margin: 0 auto;
    width: 1190px;
    padding-top: 100px;

    .lp {
      &__header {
        &__button {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-around;
          margin-top: 35px;

          > div {
            width: 210px;
            height: 47px;
            border-radius: 24px;
            transition: all 0.2s ease;
            background: #ffffff;
            box-shadow: 0 1px 6px #00000029;
            text-align: center;
            cursor: pointer;
            @include hover__soft-background;

            > p {
              color: $font-color;
              cursor: pointer;
              line-height: 47px;
              font-size: 15px;
            }

            &.active {
              border: 1px solid $elysia-color;

              > p {
                font-family: $bold;
                line-height: 47px;
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 80px;
      text-align: center;
      padding-bottom: 200px;

      > h2 {
        font-size: 30px;
        color: $font-color;
        margin-bottom: 25px;
      }

      > p {
        font-size: 15px;
        line-height: 25px;
        color: $font-color;
        margin-bottom: 25px;
      }
    }

    &__body {
      width: 100%;
      background: $background-color;
      border: 1px solid $gray-line;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &--left {
        border-right: 1px solid $gray-line;
      }

      &__all-round {
        width: 182px;
        height: 205px;
        background: $background-color;
        border: 1px solid $gray-line;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 14px 33px 14px 28px;

        > h2 {
          font-size: 15px;
          color: $elysia-color;
          margin-bottom: 30px;
        }

        > p {
          width: 90px;
          text-align: center;
          font-size: 12px;
          color: $font-color;
        }
      }

      &--right {
        display: flex;
        width: 100%;
        justify-content: center;
      }

      &__undefined-data {
        > h2 {
          font-size: 15px;
          text-align: center;
        }
      }
    }
  }

  .guide {
    margin: 0 auto;
    width: 1190px;

    &__handler {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      &__button {
        flex: 1;
        text-align: center;
        border-bottom: 5px solid $elysia-color;
        cursor: pointer;
        font-size: 20px;

        > p {
          color: $font-color;
          cursor: pointer;
          margin: 15px;
        }

        &.disable {
          border-bottom: 5px solid $gray-line;

          > p {
            color: $gray-font-color;
          }
        }
      }
    }

    &__image {
      width: 100%;
      box-shadow: 0 0 3px $elysia-color;
      border-radius: 5px;
      transition: 0.2s all ease-in-out;
      cursor: pointer;
      position: relative;

      &:hover {
        box-shadow: 0 0 20px $elysia-color;
      }
    }

    &__link {
      color: #00bfff;
      text-decoration: underline;
      cursor: pointer;
      font-family: $bold;
    }

    &__container {
      border-bottom: 1px solid #b7b7b7;
      margin-top: 50px;
      margin-bottom: 50px;
      padding-bottom: 30px;

      > h3 {
        font-size: 20px;
      }

      &:last-child {
        border: 0;
      }
    }

    &__content {
      margin: 40px 20px 0;

      &__bold {
        font-size: 16px;
      }

      > p {
        font-size: 15px;
      }

      > ul {
        margin: 30px 0;
      }

      > div {
        > h2 {
          font-size: 18px;
          margin: 30px 0;
        }
      }

      > ol {
        margin: 20px 0;

        > li {
          > div {
            margin: 30px 0;
            transition: 0.2s all ease-in-out;

            > p {
              font-size: 14px;
              text-align: center;
              color: $gray-font-color;
            }
          }
        }
      }
    }
  }

  // guide end

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    overflow: hidden;
    backdrop-filter: blur(4px);

    &__button {
      box-shadow: 0 0 6px #00000029;
      background: $elysia-color;
      text-align: center;
      cursor: pointer;

      > p {
        color: #ffffff;
        cursor: pointer;
      }

      &.disable {
        background: $gray-line;
        cursor: default;
        box-shadow: none;

        > p {
          color: $gray-font-color;
          cursor: default;
        }
      }
    }

    &__container {
      background-color: $background-color;
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      border-bottom: 1px solid $gray-line;

      &__image {
        &--sub-token {
          border-radius: 100%;
        }
      }

      &__name {
        color: $font-color;
      }

      &__content {
        display: flex;
        justify-content: left;
        align-items: center;
      }
    }

    &__converter {
      display: flex;

      &__column {
        flex: 1;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid $gray-line;

        > h2 {
          color: #b7b7b7;
          cursor: pointer;
        }

        &.selected {
          flex: 1;
          text-align: center;
          border-bottom: 3px solid #00bfff;
          cursor: pointer;

          > h2 {
            color: $elysia-color;
          }
        }
      }
    }

    &__input {
      background-color: $gray-line;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: center;
      align-items: center;

      &__maximum {
        color: $gray-font-color;
        display: flex;
        cursor: pointer;
        white-space: nowrap;

        &.is-max {
          color: $elysia-color;
          transition: all 0.3s linear;
        }
      }

      &__value {
        color: $font-color;

        &__amount {
          text-align: right;
          padding: 0;
          border: 0;
          background: none;
          color: $font-color;
          width: 100%;
          float: right;
          font-family: $bold;
          outline: 0;
          transition: all 0.3s linear;
        }
      }
    }

    &__deposit {
      border-bottom: 2px solid $gray-line;

      &__despositable-amount {
        -webkit-justify-content: space-between;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f1;

        > p {
          color: $gray-font-color;
        }

        > div {
          display: flex;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-align-items: center;
          align-items: center;

          > h2 {
            color: $font-color;
          }
        }
      }

      &__despositable-value {
        > p {
          color: $gray-font-color;
        }

        > div {
          display: flex;
          -webkit-justify-content: space-between;
          justify-content: space-between;

          > h2 {
            color: $font-color;
          }
        }
      }
    }

    &__withdraw {
      border-bottom: 2px solid $gray-line;

      &__withdrawalable {
        &__amount {
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          border-bottom: 1px solid $gray-line;
        }

        &__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > h2 {
            color: $font-color;
          }
        }

        &__title {
          display: flex;
          -webkit-justify-content: space-between;
          justify-content: space-between;

          > p {
            color: $gray-font-color;
          }
        }
      }
    }

    &__confirm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > div {
        > h2 {
          color: $font-color;
        }

        > p {
          color: $gray-font-color;
        }
      }

      text-align: center;
    }

    &__incentive {
      &__body {
        overflow-y: clip;
        background-color: $gray-line;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__value {
        text-align: right;
        width: 100%;
      }
    }

    &__account {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__mainnet-info {
        width: 100%;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > div:first-child {
          > p {
            color: #b7b7b7;
          }

          > div {
            display: flex;
            align-items: center;
          }
        }

        > div:last-child {
          border-radius: 3px;
          border: 1px solid #646464;
          cursor: pointer;
          @include hover__soft-background;

          > p {
            cursor: pointer;
          }
        }

        &__button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border: 1px solid #e6e6e6;
          border-top: 0;
          box-sizing: border-box;
          color: #333333;
          cursor: pointer;

          > div {
            width: 50%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s linear;

            > p {
              color: #636363;
              cursor: pointer;
            }

            &:first-child {
              border-right: 1px solid $gray-line;
            }

            &:hover {
              background-color: #e6e6e6;
            }
          }

          > a {
            width: 50%;
            text-decoration: none;
            user-select: none;

            > div {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.2s linear;

              &:hover {
                background-color: #e6e6e6;
              }

              > p {
                color: #636363;
                cursor: pointer;
              }
            }
          }
        }
      }

      &__add-tokens {
        width: 100%;

        > h2 {
          color: $font-color;
        }

        > div {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          border: 1px solid $gray-line;

          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0 0 6px #00000029;
            width: 100%;

            > div {
              display: flex;
              flex-direction: row;
              align-items: center;

              > div {
                position: relative;
              }
            }

            > p {
              color: $font-color;
            }
          }
        }
      }

      &__status {
        width: 100%;

        .disable {
          cursor: default;

          > div {
            > p {
              cursor: default;
            }
          }
        }

        > a {
          text-decoration: none;
          user-select: none;
          cursor: pointer;

          > div {
            border: 1px solid #e6e6e6;
            justify-content: space-between;
            display: flex;
            align-items: center;

            > p {
              cursor: pointer;
            }

            > div {
              display: flex;
              align-items: center;

              > p {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    &__staking {
      &__container {
        border-bottom: 2px solid $gray-line;
        display: flex;
        flex-direction: column;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > h2 {
            color: $font-color;
          }
        }
      }
    }

    &__migration {
      &__wrapper {
        border-bottom: 2px solid #f0f0f1;

        > div {
          > h2 {
            color: $font-color;
          }
        }
      }

      &__input {
        background-color: #f0f0f1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > p {
          > input {
            text-align: right;
            padding: 0;
            border: 0;
            background: none;
            color: #1c1c1c;
            float: right;
            font-family: $bold;
            outline: 0;
            transition: all 0.3s linear;
          }
        }
      }

      &__popup {
        &__info {
          display: inline-flex;
          justify-content: left;

          > h2 {
            color: $font-color;
          }
        }
      }

      &__nth {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        > p {
          color: $gray-font-color;
        }
      }

      &__content {
        border-bottom: 2px solid $gray-line;

        > p {
          color: $gray-font-color;
        }

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          > h2 {
            color: $font-color;
          }
        }
      }
    }

    &__alert {
      .modal__header {
        border: none;
      }

      &__content {
        width: 90%;
        margin: 0 auto;

        > h2 {
          color: $font-color;
          text-align: center;
        }
      }

      &__wrapper {
        border: 1px solid #e6e6e6;

        > p {
          color: $font-color;
        }

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > p {
            color: $font-color;
          }
        }
      }
    }

    &__lp {
      &__reward {
        &__container {
          border-bottom: 2px solid $gray-line;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $gray-line;

            &:last-child {
              border: 0;
            }

            > div {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              > h2 {
                color: $font-color;
              }
            }

            > h2 {
              color: $font-color;

              > span {
                color: $gray-font-color;
              }
            }
          }
        }
      }

      &__staking {
        &__body {
          border-bottom: 2px solid $gray-line;
        }

        &__undefined {
          > h2 {
            color: $sub-font-color;
            text-align: center;
          }
        }
      }
    }

    &__connect {
      &__content {
        margin: 0 auto;
        text-align: center;
        border-bottom: 2px solid $gray-line;

        > p {
          color: $font-color;
        }
      }
    }

    &__network-change-modal__button {
      &__wrapper {
        display: flex;
        flex-direction: row;
        padding: 20px 30px;
        gap: 10px;
      }

      height: 48px;
      border: 1px solid $gray-line;
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.2s ease-in;

      &:hover {
        border: 1px solid $elysia-color;
      }

      > h2 {
        font-size: 15px;
        font-family: $montserrat--bold;
        cursor: pointer;
        margin-right: 8px;
      }
    }

    &__increate-allowance {
      padding: 30px;
      border-bottom: 2px solid $gray-line;

      > h2 {
        font-size: 18px;
        padding: 20px 0 40px;
        text-align: center;
      }

      > p {
        font-size: 13px;
        margin: 10px 0 0;

        > strong {
          color: $elysia-color;
        }
      }

      &__content {
        border: 1px solid $gray-line;
        border-radius: 10px;
        text-align: center;
        padding: 32px 25px;

        > p {
          font-size: 15px;
          line-height: 25px;

          > strong {
            color: $elysia-color;
          }
        }
      }
    }
  }

  // modal end

  .component {
    &__loan-list {
      border-radius: 5px;
      box-shadow: 0 0 6px #00000029;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-7px);
        box-shadow: 0 0 15px #00a7ff66;
        transition: all 0.3s ease;
      }

      &__image {
        height: 256px;

        > img {
          border-radius: 5px 5px 0 0;
          width: 100%;
        }
      }

      &__data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        background: #ffffff;

        > div {
          flex: 1;
          text-align: center;

          &:first-child {
            border-right: 1px solid $gray-line;
          }

          > p {
            cursor: pointer;
          }

          > p:first-child {
            color: $gray-font-color;
          }
        }
      }
    }

    // loan-list end

    &__text-navigation {
      display: flex;
      align-content: center;
      color: $gray-font-color;
      cursor: default;

      > p {
        color: $gray-font-color;

        &.pointer {
          cursor: pointer;
        }
      }
    }

    // text navigation end

    &__progress-bar {
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &:first-child {
          > p {
            color: $gray-font-color;
          }
        }

        &:nth-child(2) {
          > p {
            color: $font-color;

            &:last-child {
              color: $gray-font-color;

              > span {
                color: $gray-font-color;
              }
            }

            > span {
              color: $font-color;
            }
          }
        }
      }

      progress {
        color: #e6e6e6;
        width: 100%;

        &::-webkit-progress-bar {
          border: 0;
          background: #e6e6e6;
        }

        &::-webkit-progress-value {
          border: 0;
          background: #00bfff;
        }

        &::-moz-progress-bar {
          border: 0;
          background: #00bfff;
        }
      }
    }

    // progress bar end

    &__data-info {
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > p {
          &:first-child {
            color: $gray-font-color;
          }

          &:last-child {
            color: $font-color;
          }
        }
      }
    }

    // data info end

    &__popup-info {
      display: inline-flex;
      cursor: pointer;

      &__hover-mark {
        border-radius: 100%;
        border: 1px solid #333333;
        cursor: pointer;
        flex: 1;
        text-align: center;
      }

      &__content {
        position: absolute;
        background: #ffffff;
        box-shadow: 0 0 6px #00000029;
      }
    }
  }

  // component end

  .percent {
    &::after {
      content: '%';
      color: $sub-font-color;
    }
  }

  .amount {
    &::before {
      content: '$';
      color: $sub-font-color;
    }
  }

  .equal_amount {
    &::before {
      content: '= $';
      color: $sub-font-color;
    }
  }

  .footer {
    height: 120px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    > h2 {
      font-family: Inter;
      color: $font-color;
      font-weight: normal;
      font-size: 13px;
    }

    > div {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        display: flex;
        flex-direction: row;
      }
    }

    &__link {
      display: flex;
      flex-direction: row;

      > a {
        width: 38px;
        height: 38px;
        border-radius: 38px;
        box-shadow: 0 0 6px #00000029;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 25px;
        transition: all 0.2s ease;

        &:hover {
          transform: translateY(-3px);
          box-shadow: 0 0 15px #00a7ff66;
          transition: all 0.2s ease;
        }

        &:active {
          transform: translateY(-1px);
          box-shadow: 0 0 5px #00a7ff66;
        }
      }
    }

    &__lang {
      position: relative;
      cursor: pointer;
      box-shadow: 0 0 6px #00000029;
      display: flex;
      justify-content: center;
      border-radius: 20px;
      transition: all 0.2s ease;
      width: 101px;
      height: 38px;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 0 15px #00a7ff66;
        transition: all 0.2s ease;
      }

      &:active {
        transform: translateY(-1px);
        box-shadow: 0 0 5px #00a7ff66;
      }

      &__wrapper {
        display: flex;
        width: 82px;
        height: 100%;
        justify-content: space-between;
        align-items: center;

        > img {
          width: 21px;
          height: 21px;
        }

        > p {
          font-size: 15px;
          cursor: pointer;
          color: $font-color;
        }
      }

      &__image-handler {
        justify-content: space-between;
        flex-direction: column;
        position: absolute;
        bottom: 50px;
        height: 52px;
        cursor: default;

        &__wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 82px;
          cursor: pointer;

          > img {
            width: 21px;
            height: 21px;
          }

          > p {
            font-size: 15px;
            cursor: pointer;
            color: $font-color;
          }
        }
      }
    }
  }

  // footer end

  .reward-plan-button {
    display: inline-block;
    width: 100%;
    text-align: right;

    > a {
      display: inline-block;

      > p {
        cursor: pointer;
      }
    }
  }

  .logo-wrapper {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  .scroll-arrow {
    // display: flex;
    // flex-direction: column;
    position: absolute;
    align-items: center;

    &.down {
      align-items: center;
      display: flex;
      flex-direction: column;

      > span {
        &:last-child {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid #00a7ff;
        }
      }
    }

    &.up {
      transform: rotate(180deg);
      top: 7px;
      display: flex;
      flex-direction: column;

      > span {
        &:last-child {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid #00a7ff;
        }
      }
    }

    &.left {
      display: flex;
      transform: rotate(180deg);
      left: 7px;

      > span {
        -webkit-transform: rotate(-45deg) skew(20deg, 20deg);
        transform: rotate(-45deg) skew(20deg, 20deg);

        &:last-child {
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #00a7ff;
        }
      }
    }

    &.right {
      display: flex;

      > span {
        -webkit-transform: rotate(-45deg) skew(20deg, 20deg);
        transform: rotate(-45deg) skew(20deg, 20deg);

        &:last-child {
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #00a7ff;
        }
      }
    }

    > span {
      display: block;
      -webkit-transform: rotate(45deg) skew(20deg, 20deg);
      transform: rotate(45deg) skew(20deg, 20deg);
      border-right: 2px solid $elysia-color;
      border-bottom: 2px solid $elysia-color;
      margin: 0;
      width: 10px;
      height: 10px;
      -webkit-animation: mouse-scroll 1.4s infinite;
      animation: mouse-scroll 1.4s infinite;
    }

    .arrow {
      &--00 {
        -webkit-animation-delay: 0.1s;
        -moz-animation-delay: 0.1s;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        animation-delay: alternate;
        width: 1px;
        height: 1px;
        margin: 2px;
        background: $elysia-color;
        border-radius: 100%;
        transform: none !important;
      }

      &--01 {
        -webkit-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        animation-delay: alternate;
        width: 1px;
        height: 1px;
        margin: 2px;
        background: $elysia-color;
        border-radius: 100%;
        transform: none !important;
      }

      &--02 {
        -webkit-animation-delay: 0.3s;
        -moz-animation-delay: 0.3s;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        animation-delay: alternate;
        width: 1px;
        height: 1px;
        margin: 2px;
        background: $elysia-color;
        border-radius: 100%;
        transform: none !important;
      }

      &--03 {
        -webkit-animation-delay: 0.4s;
        -moz-animation-delay: 0.4s;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        animation-delay: alternate;
        width: 1px;
        height: 1px;
        margin: 2px;
        background: $elysia-color;
        border-radius: 100%;
        transform: none !important;
      }

      &--04 {
        -webkit-animation-delay: 0.5s;
        -moz-animation-delay: 0.5s;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        animation-delay: alternate;
        width: 1px;
        height: 1px;
        margin: 2px;
        background: $elysia-color;
        border-radius: 100%;
        transform: none !important;

        &:last-child {
          width: 0;
          background: transparent;
          border-radius: 0;
          height: 0;
          border: none;
        }
      }
    }
  }

  @keyframes hovers {
    from {
      width: 0%;
      left: -20px;
      opacity: 0.5;
    }

    to {
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }

  @-moz-keyframes hovers {
    from {
      width: 0%;
      left: -20px;
      opacity: 0.5;
    }

    to {
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }

  @-webkit-keyframes hovers {
    from {
      width: 0%;
      left: -20px;
      opacity: 0.5;
    }

    to {
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }

  @-ms-keyframes hovers {
    from {
      width: 0%;
      left: -20px;
      opacity: 0.5;
    }

    to {
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }

  @-o-keyframes hovers {
    from {
      width: 0%;
      left: -20px;
      opacity: 0.5;
    }

    to {
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }

  .close-button {
    width: 25px;
    height: 25px;
    display: inline-block;
    cursor: pointer;

    &--1 {
      height: 25px;
      width: 1px;
      margin-left: 12.5px;
      background-color: #b7b7b7;
      transform: rotate(45deg);
      z-index: 1;
    }

    &--2 {
      height: 25px;
      width: 1px;
      background-color: #b7b7b7;
      transform: rotate(90deg);
      z-index: 2;
    }
  }

  .google-visualization-tooltip {
    border: 0 !important;
    -webkit-box-shadow: none !important;
    background-color: #ffffff !important;
    // box-shadow: 0px 0px 6px #00000029 !important;
    border-radius: 5px;
    padding: 8px;
    width: 220px !important;

    ul li {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      > span {
        color: #888888 !important;
        font-family: Inter-Bold !important;

        &:nth-child(2) {
          flex: 1;
          font-family: Inter-Bold !important;
        }

        &:last-child {
          color: #333333 !important;
        }
      }

      > div {
        display: none !important;
      }

      &:first-child {
        display: none !important;
      }

      &:last-child {
        &::after {
          content: '%';
          color: #333333 !important;
          font-family: $bold !important;
        }
      }
    }
  }

  .indicator {
    border-bottom: 2px solid #f0f0f1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    height: 260px;
    align-items: center;

    > p {
      bottom: 15px;
      font-size: 15px;
      margin-top: 50px;
    }

    .lds-spinner {
      color: official;
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
      margin: 0;
    }

    .lds-spinner div {
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;
    }

    .lds-spinner div:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: $elysia-color;
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 2px #00bfff44, 0 0 3px #00bfff44, 0 0 4px #00bfff44,
        0 0 5px #00bfff44, 0 0 6px #00bfff44, 0 0 7px #00bfff44,
        0 0 10px #00bfff44;
    }

    to {
      box-shadow: 0 0 2px #ffffff44, 0 0 4px #ffffff44, 0 0 10px #ffffff44,
        0 0 14px #ffffff44, 0 0 17px #ffffff44, 0 0 19px #ffffff44,
        0 0 25px #ffffff44;
    }
  }

  .pin {
    position: absolute;
    border-radius: 50%;
    border: 8px solid #00bfff;
    width: 8px;
    height: 8px;
  }

  .pin::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -30px;
    left: -6px;
    border: 10px solid transparent;
    border-top: 17px solid #00bfff;
  }

  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0 -10px 0 0 #ffffff, 10px -10px 0 0 rgba(255, 255, 255, 0.2),
        10px 0 0 0 rgba(255, 255, 255, 0.2),
        10px 10px 0 0 rgba(255, 255, 255, 0.2),
        0 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 0 0 0 rgba(255, 255, 255, 0.5),
        -10px -10px 0 0 rgba(255, 255, 255, 0.7);
    }

    12.5% {
      box-shadow: 0 -10px 0 0 rgba(255, 255, 255, 0.7), 10px -10px 0 0 #ffffff,
        10px 0 0 0 rgba(255, 255, 255, 0.2),
        10px 10px 0 0 rgba(255, 255, 255, 0.2),
        0 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 0 0 0 rgba(255, 255, 255, 0.2),
        -10px -10px 0 0 rgba(255, 255, 255, 0.5);
    }

    25% {
      box-shadow: 0 -10px 0 0 rgba(255, 255, 255, 0.5),
        10px -10px 0 0 rgba(255, 255, 255, 0.7), 10px 0 0 0 #ffffff,
        10px 10px 0 0 rgba(255, 255, 255, 0.2),
        0 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 0 0 0 rgba(255, 255, 255, 0.2),
        -10px -10px 0 0 rgba(255, 255, 255, 0.2);
    }

    37.5% {
      box-shadow: 0 -10px 0 0 rgba(255, 255, 255, 0.2),
        10px -10px 0 0 rgba(255, 255, 255, 0.5),
        10px 0 0 0 rgba(255, 255, 255, 0.7), 10px 10px 0 0 #ffffff,
        0 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 0 0 0 rgba(255, 255, 255, 0.2),
        -10px -10px 0 0 rgba(255, 255, 255, 0.2);
    }

    50% {
      box-shadow: 0 -10px 0 0 rgba(255, 255, 255, 0.2),
        10px -10px 0 0 rgba(255, 255, 255, 0.2),
        10px 0 0 0 rgba(255, 255, 255, 0.5),
        10px 10px 0 0 rgba(255, 255, 255, 0.7), 0 10px 0 0 #ffffff,
        -10px 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 0 0 0 rgba(255, 255, 255, 0.2),
        -10px -10px 0 0 rgba(255, 255, 255, 0.2);
    }

    62.5% {
      box-shadow: 0 -10px 0 0 rgba(255, 255, 255, 0.2),
        10px -10px 0 0 rgba(255, 255, 255, 0.2),
        10px 0 0 0 rgba(255, 255, 255, 0.2),
        10px 10px 0 0 rgba(255, 255, 255, 0.5),
        0 10px 0 0 rgba(255, 255, 255, 0.7), -10px 10px 0 0 #ffffff,
        -10px 0 0 0 rgba(255, 255, 255, 0.2),
        -10px -10px 0 0 rgba(255, 255, 255, 0.2);
    }

    75% {
      box-shadow: 0 -10px 0 0 rgba(255, 255, 255, 0.2),
        10px -10px 0 0 rgba(255, 255, 255, 0.2),
        10px 0 0 0 rgba(255, 255, 255, 0.2),
        10px 10px 0 0 rgba(255, 255, 255, 0.2),
        0 10px 0 0 rgba(255, 255, 255, 0.5),
        -10px 10px 0 0 rgba(255, 255, 255, 0.7), -10px 0 0 0 #ffffff,
        -10px -10px 0 0 rgba(255, 255, 255, 0.2);
    }

    87.5% {
      box-shadow: 0 -10px 0 0 rgba(255, 255, 255, 0.2),
        10px -10px 0 0 rgba(255, 255, 255, 0.2),
        10px 0 0 0 rgba(255, 255, 255, 0.2),
        10px 10px 0 0 rgba(255, 255, 255, 0.2),
        0 10px 0 0 rgba(255, 255, 255, 0.2),
        -10px 10px 0 0 rgba(255, 255, 255, 0.5),
        -10px 0 0 0 rgba(255, 255, 255, 0.7), -10px -10px 0 0 #ffffff;
    }
  }

  /* hamburger bar */

  @-webkit-keyframes in-m {
    50% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(45deg);
    }
  }

  @keyframes in-m {
    50% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(45deg);
    }
  }

  @-webkit-keyframes out-m {
    50% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(45deg);
    }
  }

  @keyframes out-m {
    50% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(45deg);
    }
  }

  @-webkit-keyframes in-t {
    0% {
      -webkit-transform: translateY(0) rotate(0deg);
    }

    50% {
      -webkit-transform: translateY(5px) rotate(0deg);
    }

    100% {
      -webkit-transform: translateY(5px) rotate(135deg);
    }
  }

  @keyframes in-t {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(7px) rotate(0deg);
    }

    100% {
      transform: translateY(7px) rotate(135deg);
    }
  }

  @-webkit-keyframes out-t {
    0% {
      -webkit-transform: translateY(0) rotate(0deg);
    }

    50% {
      -webkit-transform: translateY(5px) rotate(0deg);
    }

    100% {
      -webkit-transform: translateY(5px) rotate(135deg);
    }
  }

  @keyframes out-t {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(7px) rotate(0deg);
    }

    100% {
      transform: translateY(7px) rotate(135deg);
    }
  }

  @-webkit-keyframes in-btm {
    0% {
      -webkit-transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-7px) rotate(0deg);
    }

    100% {
      transform: translateY(-7px) rotate(135deg);
    }
  }

  @keyframes in-btm {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-7px) rotate(0deg);
    }

    100% {
      transform: translateY(-7px) rotate(135deg);
    }
  }

  @-webkit-keyframes out-btm {
    0% {
      -webkit-transform: translateY(0) rotate(0deg);
    }

    50% {
      -webkit-transform: translateY(-7px) rotate(0deg);
    }

    100% {
      -webkit-transform: translateY(-7px) rotate(135deg);
    }
  }

  @keyframes out-btm {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-7px) rotate(0deg);
    }

    100% {
      transform: translateY(-7px) rotate(135deg);
    }
  }

  /* loading indicator */

  .lds-spinner {
    color: #00bfff;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }

  .lds-spinner div {
    transform-origin: 12px 12px;
    animation: lds-spinner 1.2s linear infinite;
  }

  .lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 12px;
    width: 2px;
    height: 6px;
    border-radius: 20%;
    background: #00bfff;
  }

  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }

  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }

  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }

  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }

  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }

  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }

  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }

  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }

  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }

  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }

  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .select_box_Item {
    background-color: #ffffff;

    > div {
      > div {
        color: #333333;
      }
    }
  }

  .select_box_Item:hover {
    background-color: rgb(116, 116, 116);

    > div {
      > div {
        color: #ffffff;
      }
    }
  }

  @-webkit-keyframes mouse-scroll {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes mouse-scroll {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes mouse-scroll {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes mouse-scroll {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 6px #00000029, 0 0 1px #00000029,
        0 0 0 0 rgba($elysia-color, 0.3), 0 0 0 0 rgba($elysia-color, 0.3);
    }

    100% {
      box-shadow: 0 0 6px #00000029, 0 0 6px #00000029,
        0 0 3px 20px rgba($elysia-color, 0), 0 0 3px 30px rgba($elysia-color, 0);
    }
  }

  @-webkit-keyframes ripple {
    0% {
      -webkit-box-shadow: 0 0 8px #1c1c1c29, 0 0 0 #ffffff, 0 0 10px #1c1c1c29;
    }

    15% {
      -webkit-box-shadow: 0 0 2px #1c1c1c29, 0 0 0 #ffffff, 0 0 10px #1c1c1c29;
    }

    50% {
      -webkit-box-shadow: 0 0 4px #1c1c1c29, 0 0 0 #ffffff, 0 0 10px #1c1c1c29;
    }

    95% {
      -webkit-box-shadow: 0 0 10px #1c1c1c29, 0 0 0 #ffffff, 0 0 10px #1c1c1c29;
    }

    100% {
      -webkit-box-shadow: 0 0 10px #1c1c1c29, 0 0 0 #ffffff, 0 0 10px #1c1c1c29;
    }
  }

  .market__detail__graph__DAI div div div div svg g {
    // transform: scale(1.2) translate(-10%, -10%);

    > g rect {
      rx: 5;
      ry: 5;

      &:hover {
        fill: #f9ae19;
        stroke: transparent;
        z-index: 99;
        rx: 5;
        ry: 5;
      }
    }

    > g g g rect {
      fill: #f9ae19;
      stroke: transparent;
      z-index: 99;
      rx: 5;
      ry: 5;
    }

    > g g circle {
      stroke: transparent;
      fill: #f9ae19;
      r: 6;
    }
  }

  .market__detail__graph__USDT div div div div svg g {
    // transform: scale(1.2) translate(-10%, -10%);

    > g rect {
      rx: 5;
      ry: 5;

      &:hover {
        fill: #26a17b;
        stroke: transparent;
        z-index: 99;
        rx: 5;
        ry: 5;
      }
    }

    > g g g rect {
      fill: #26a17b;
      stroke: transparent;
      z-index: 99;
      rx: 5;
      ry: 5;
    }

    > g g circle {
      stroke: transparent;
      fill: #26a17b;
      r: 6;
    }
  }

  .market__detail__graph__DAI {
    .google-visualization-tooltip {
      border: 1px solid #f9ae19 !important;
    }
  }

  .market__detail__graph__USDT {
    .google-visualization-tooltip {
      border: 1px solid #26a17b !important;
    }
  }

  textarea {
    font-family: Inter;
  }

  .error {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9997;

    &__container {
      &__button {
        box-shadow: 0 0 6px #00000029;
        background: #00bfff;
        text-align: center;
        cursor: pointer;

        > p {
          color: #ffffff;
        }
      }
    }
  }

  .wallet_select_modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
    z-index: 9999;

    &__content {
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0 3px 6px #00000029;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__line {
        width: 100%;
        height: 1;
        border-top: 1px solid #f0f0f1;
      }

      &__wallet_btn {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .change_network_modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
    z-index: 9999;

    &__content {
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0 3px 6px #00000029;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__line {
        width: 100%;
        height: 1;
        border-top: 1px solid #f0f0f1;
      }

      &__wallet_btn {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .market_modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    overflow: hidden;
    backdrop-filter: blur(4px);

    &__wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 512px;
      border-radius: 10px;
      // padding: 29.75px 19px 24.5px 19px;
      background-color: $background-color;
    }

    &__header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-bottom: 25.75px;
      padding: 29.75px 19px 25.75px 19px;

      > h3 {
        font-family: Inter-Bold;
        font-size: 18px;
        color: $font-color;
        margin: 0;
      }

      > div {
        cursor: pointer;
        position: relative;
        width: 24px;
        height: 24px;

        > div {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          border: 1.5px solid #b7b7b7;
          background-color: #b7b7b7;
          border-radius: 5px;
        }

        > div:first-child {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        > div:last-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      > div:active {
        > div {
          border-color: $font-color;
          background-color: $font-color;
        }
      }
    }

    &__header::after {
      position: absolute;
      content: '';
      width: 100%;
      border-top: 1px solid #f0f0f1;
      left: 0;
      bottom: 0;
    }

    .nft::after {
      display: none;
    }

    &__section {
      position: relative;
      width: 100%;
      font-size: 15px;
      font-family: SpoqaHanSansNeo-Medium;
      text-align: center;
      color: $font-color;
      padding-top: 56px;
      padding-bottom: 53.25px;
      line-height: 25px;
      font-weight: 600;

      > p:nth-child(2) {
        font-family: SpoqaHanSansNeo-Bold;
        color: $gray-font-color;
      }
    }

    &__section::after {
      position: absolute;
      content: '';
      width: 100%;
      border-top: 1px solid #f0f0f1;
      left: 0;
      bottom: 0;
    }

    &__button {
      width: 100%;
      padding: 25px 19px 24.5px 19px;
      display: flex;
      justify-content: center;
      align-items: center;

      > button {
        cursor: pointer;
        width: 470px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $background-color;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        font-weight: 600;

        > p {
          font-family: 'Montserrat-bold SpoqaHanSansNeo-Bold';
          color: #262626;
          font-size: 15px;
        }
      }

      > button:active {
        background-color: #f0f0f1;

        > p {
          font-family: 'Montserrat-bold SpoqaHanSansNeo-Bold';
          color: $background-color;
        }
      }
    }

    &__step {
      position: relative;
      width: 100%;
      display: flex;
      height: 61px;
      overflow: hidden;

      > div {
        width: 50%;
        display: flex;
        align-items: center;
        font-size: 15px;

        > p {
          font-family: Inter-Bold, Montserrat-bold;
          color: $background-color;
        }
      }

      > div:nth-child(1) {
        cursor: pointer;
        padding-left: 23px;

        > div:last-child {
          position: absolute;
          left: 49.9%;
          top: -1px;
          border-top: 32px solid transparent;
          border-bottom: 32px solid transparent;
        }
      }

      > div:nth-child(1):hover {
        background-color: #666666;

        > div:last-child {
          border-left-color: #666666;
        }
      }

      > div:nth-child(2) {
        text-align: right;
        padding-right: 23px;
        justify-content: end;
      }

      .current {
        background-color: #666666;
      }

      .prev {
        background-color: #e6e6e6;
      }

      .current_polygon {
        border-left: 31px solid #666666;
      }

      .prev_polygon {
        border-left: 31px solid #e6e6e6;
      }
    }

    &__input {
      width: 100%;
      background-color: $gray-line;
      height: 145px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 30.25px;

      > section {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: SpoqaHanSansNeo-Bold;
        font-weight: 700;
        padding-right: 50px;

        > div {
          font-family: Inter-Bold;
          cursor: pointer;

          > p {
            font-size: 18px;
            color: #b7b7b7;
          }
        }

        > div:hover {
          color: $font-color;
        }

        > input {
          font-family: SpoqaHanSansNeo-Bold;
          font-weight: 700;
          width: 80%;
          text-align: right;
          height: 65px;
          background-color: $gray-line;
          border: none;
          font-size: 50px;
          padding: 5px 0 5px 0;
        }

        > input:focus {
          outline: none;
        }
      }

      > section::after {
        position: absolute;
        right: 0;
        bottom: 20%;
        font-size: 15px;
        color: #646464;
        content: 'NFT(s)';
      }

      > p {
        width: 100%;
        text-align: right;
        font-size: 12px;
        color: $gray-font-color;
      }
    }

    &__crypto {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12.5px 19.5px;
      border-bottom: 1px solid $gray-line;

      > p {
        font-family: Inter-Bold;
        font-weight: 700;
        font-size: 15px;
      }

      > div {
        cursor: pointer;
        position: relative;
        width: 70%;
        height: 48px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        font-weight: 700;
        font-size: 15px;

        > ul {
          position: absolute;
          left: -1px;
          top: 48px;
          list-style: none;
          width: 100.6%;
          text-align: right;
          background-color: $background-color;
          border: 1px solid #e6e6e6;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;

          > li {
            font-weight: 700;
            font-size: 15px;
            height: 46px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20.25px;
            margin: 0;

            > img {
              margin-right: 10.25px;
            }
          }

          > li:not(li:last-child) {
            border-bottom: 1px solid #e6e6e6;
          }

          > li:hover {
            background-color: #00bfff;
          }
        }

        > div {
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 20.25px;

          > img {
            margin-right: 10.25px;
          }

          > div {
            position: absolute;
            width: 10px;
            height: 10px;
            left: 18px;

            > div {
              position: absolute;
              left: 50%;
              top: 50%;
              width: 10px;
              border: 1px solid #262626;
              background-color: #262626;
            }

            > div:first-child {
              transform: translate(-78%, -50%) rotate(47deg);
            }

            > div:last-child {
              transform: translate(-22%, -50%) rotate(-47deg);
            }
          }
        }
      }
    }

    &__amount {
      padding: 20.16px 19.5px;
      font-family: SpoqaHanSansNeo-Bold;
      font-weight: 700;
      font-size: 15px;
      border-bottom: 1px solid $gray-line;

      > div {
        font-family: Inter-Bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      > div:first-child {
        > div > span {
          color: #646464;
        }
      }

      > div:last-child {
        padding-top: 24px;
        align-items: flex-start;

        > div:last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          > div:first-child {
            padding-bottom: 20px;
          }

          > div > span {
            color: #646464;
            margin-left: 5px;
          }
        }
      }
    }

    &__purchase_btn {
      width: 100%;
      padding: 23px 19.5px;

      > button {
        cursor: pointer;
        font-family: SpoqaHanSansNeo-Medium;
        width: 100%;
        height: 47px;
        text-align: center;
        background-color: #00bfff;
        border-radius: 24px;
        color: $background-color;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 2px;
        border: none;
      }
    }

    .purchase_loading > button {
      background-color: $gray-line;
      color: $gray-font-color;
    }

    &__confirm {
      padding: 22.25px 19.5px;
      border-bottom: 1px solid $gray-line;

      > h3 {
        margin: 0;
        font-size: 15px;
        font-family: SpoqaHanSansNeo;
        color: $gray-font-color;
        margin-bottom: 19.75px;
      }

      > div:nth-child(2),
      div:nth-child(3) {
        font-family: SpoqaHanSansNeo-Bold;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
      }

      > div:nth-child(2) {
        margin-bottom: 12px;
      }

      > div:nth-child(3) {
        > div:last-child {
          > div:first-child > span {
            color: #646464;
            margin-left: 5px;
          }

          > div:last-child {
            font-family: SpoqaHanSansNeo;
            text-align: right;
            font-size: 12px;
            color: $gray-font-color;
            margin-top: 2.5px;
            font-weight: normal;
          }
        }
      }

      > div:last-child {
        width: 100%;
        height: 216px;
        background-color: #f6f6f8;
        border: 1px solid $gray-line;
        border-radius: 5px;
        padding: 22px 16px;
        margin-top: 21.5px;
        overflow: scroll;

        > h5 {
          margin: 0;
        }

        > ul {
          width: 100%;
          padding-left: 12px;
          font-size: 13px;
          line-height: 20px;

          > li:first-child > span {
            font-size: 10px;
            color: $governance-progress-02;
          }
        }

        > ul > li::marker {
          margin-right: 20px;
        }
      }
    }

    &__twitter {
      width: 470px;
      margin: 0 auto;
      text-align: center;

      > figure {
        > video {
          width: 250px;
        }

        > figcaption {
          font-size: 20px;
        }

        margin-bottom: 30px;
      }

      > section {
        height: 103px;
        background-color: #f6f6f8;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding: 0 100px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 10px;

          > b {
            font-size: 20px;
            color: #333333;
          }

          > p {
            font-size: 13px;
            color: #707070;
          }
        }

        > b {
          font-size: 20px;
          color: #333333;
        }
      }
    }

    &__visible {
      position: absolute;
      bottom: -30px;
      display: flex;
      align-items: center;

      > div {
        width: 16px;
        height: 16px;
        border: 1px solid #ffffff;
        border-radius: 2px;
        margin-right: 9px;
        cursor: pointer;

        &:hover {
          background-color: #ffffff33;
        }

        &:active {
          background-color: #ffffff88;
        }
      }

      > b {
        font-size: 15px;
        color: #ffffff;
      }
    }

    &__token-reward {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0 30px;

      > b {
        font-size: 20px;
        line-height: 28px;
      }

      > video {
        width: 250px;
      }

      > section {
        width: 450px;
        margin: 10px auto 0;
        // height: 120px;
        border-radius: 10px;
        background-color: #f6f6f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > p {
          font-size: 13px;
          color: #333333;

          &:first-child {
            color: #707070;
          }
        }

        > b {
          font-size: 20px;
          margin-bottom: 12.5px;
        }
      }
    }

    &__approve {
      width: 100%;
      height: 388.25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 19.5px;
      border-bottom: 1px solid $gray-line;

      > h5 {
        font-size: 18px;
        margin: 0;
        // padding: 40px 0px;
        padding-bottom: 40px;
        text-align: center;
      }

      > div {
        border: 1px solid #e6e6e6;
        padding: 32px 24px;

        > p {
          text-align: center;
          font-size: 15px;

          > strong {
            font-weight: 700;
            color: $elysia-color;
          }
        }
      }

      > p {
        font-size: 12px;
        text-align: center;
        margin-top: 15px;

        > span {
          font-weight: 700;
          color: $elysia-color;
        }
      }
    }

    &__investment-reward {
      &__burn {
        padding: 18px 25px 25px 25px;

        > b {
          font-size: 15px;
          color: $font-color;
        }

        > div {
          margin-top: 13px;
          width: 100%;
          border-radius: 10px;
          background: #f0f0f1;
          padding: 20px 30px;

          > b {
            font-size: 30px;
            text-align: right;
            display: block;

            > span {
              font-size: 25px;
              margin-left: 5px;
              color: $gray-font-color;
            }
          }

          margin-bottom: 10px;
        }

        > p {
          font-size: 12px;
          color: #646464;
          text-align: right;
        }

        border-bottom: 1px solid $gray-line;
      }

      &__current {
        padding: 30px 25px 25px 25px;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          > b {
            font-size: 15px;
            color: $font-color;

            > span {
              margin-left: 5px;
              color: #646464;
            }
          }

          margin-bottom: 14px;
        }

        > section {
          border: 1px solid #e6e6e6;
          border-radius: 10px;
          padding: 18px 25px;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &:first-child {
              margin-bottom: 10px;
            }

            > p {
              font-size: 15px;
              color: $font-color;

              > span {
                margin-left: 5px;
                color: #646464;
              }
            }
          }
        }

        border-bottom: 1px solid $gray-line;
      }

      &__button {
        display: flex;

        > button {
          @include hover__box-shadow--white;

          flex: 1;
          height: 47px;
          margin: 25px;
          background: #00bfff;
          border-radius: 35px;
          cursor: pointer;
          font-size: 18px;
          line-height: 47px;
          color: #ffffff;
          border: 0;

          &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            background-color: #f0f0f1;
            color: $font-color;
          }
        }
      }
    }
  }

  @keyframes ldio-17gxvvu9k8u {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .ldio-17gxvvu9k8u div {
    box-sizing: content-box;
    left: 50%;
    top: 35%;
    position: absolute;
    animation: ldio-17gxvvu9k8u linear 1s infinite;
    background: #d0d8de;
    width: 10.05;
    height: 24.12;
    border-radius: 0 / 0;
    transform: translate(-50%, -50%);
    transform-origin: 5.025 48.24;
  }

  .ldio-17gxvvu9k8u div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.88889;
    background: #d0d8de;
  }

  .ldio-17gxvvu9k8u div:nth-child(2) {
    transform: rotate(40deg);
    animation-delay: -0.77778;
    background: #d0d8de;
  }

  .ldio-17gxvvu9k8u div:nth-child(3) {
    transform: rotate(80deg);
    animation-delay: -0.66667;
    background: #d0d8de;
  }

  .ldio-17gxvvu9k8u div:nth-child(4) {
    transform: rotate(120deg);
    animation-delay: -0.55556;
    background: #d0d8de;
  }

  .ldio-17gxvvu9k8u div:nth-child(5) {
    transform: rotate(160deg);
    animation-delay: -0.44444;
    background: #d0d8de;
  }

  .ldio-17gxvvu9k8u div:nth-child(6) {
    transform: rotate(200deg);
    animation-delay: -0.33333;
    background: #d0d8de;
  }

  .ldio-17gxvvu9k8u div:nth-child(7) {
    transform: rotate(240deg);
    animation-delay: -0.22222;
    background: #d0d8de;
  }

  .ldio-17gxvvu9k8u div:nth-child(8) {
    transform: rotate(280deg);
    animation-delay: -0.11111;
    background: #d0d8de;
  }

  .ldio-17gxvvu9k8u div:nth-child(9) {
    transform: rotate(320deg);
    animation-delay: 0s;
    background: #d0d8de;
  }

  .loadingio-spinner-spinner-ho6pfhrsix4 {
    width: 100%;
    height: 388.25px;
    overflow: hidden;
    border-bottom: 1px solid $gray-line;
    // background: rgba(NaN, NaN, NaN, 0);
  }

  .ldio-17gxvvu9k8u {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;

    /* see note above */
  }

  /* generated by https://loading.io/ */
}
