@import '_palette';

@mixin hover__soft-background {
  transition: all 0.2s ease;
  user-select: none;

  &:hover {
    box-shadow: 0 0 6px #00a7ff66;
    transition: all 0.2s ease;
  }
}

@mixin hover__box-shadow {
  box-shadow: 0 0 0 #00000029;
  transition: all 0.2s ease;
  border: 1px solid $elysia-color;
  user-select: none;

  > p {
    border-bottom: 0;
    background: linear-gradient(
      to right,
      $background-color,
      $background-color 50%,
      $background-color 50%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    text-decoration: none;
    transition: all 0.4s ease;
  }

  &:hover {
    box-shadow: 0 0 15px #00a7ff66;
    transform: translateY(-3px);

    &::after {
      position: absolute;
      transform: translateY(6px);
    }
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 0 5px #00a7ff66;
  }
}
@mixin hover__sub_box-shadow {
  // box-shadow: 0px 0px 0px #00000029;
  transition: all 0.2s ease;
  border: 1px solid $elysia-color;
  user-select: none;

  > p {
    border-bottom: 0;
    background: linear-gradient(
      to right,
      $font-color,
      $font-color 50%,
      $font-color 50%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    text-decoration: none;
    transition: all 0.4s ease;
  }

  &:hover {
    box-shadow: 0 0 15px #00a7ff66;
    transform: translateY(-3px);

    &::after {
      position: absolute;
      transform: translateY(6px);
    }
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 0 5px #00a7ff66;
  }
}
@mixin hover__box-shadow--white {
  box-shadow: 0 0 0 #00000029;
  transition: all 0.2s ease;
  user-select: none;

  > p {
    border-bottom: 0;
    background: linear-gradient(to right, #f0f0f1, #f0f0f1 50%, #ffffff 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    text-decoration: none;
    transition: all 0.4s ease;
  }

  &:hover {
    box-shadow: 0 0 15px #00a7ff66;
    transition: all 0.2s ease;

    > p {
      font-weight: 800;
      transition: all 0.4s ease;
      background-position: 0 100%;
    }
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 0 5px #00a7ff66;
  }
}
