$font-color: #333333;
$sub-font-color: #646464;
$elysia-color: #00bfff;
$background-color: #ffffff;
$gray-line: #f0f0f1;
$gray-font-color: #888888;
$el-color: #3679b5;
$elysia-color--deep: #0099cb;
$governance-progress-00: #0099cc;
$governance-progress-01: #3d5299;
$governance-progress-02: #707070;
