@import 'palette', '_mixin';

$bold: 'Inter-Bold', 'Montserrat-bold';
$device-width: 1190px;
$loan-list-size: 315px;

@mixin page-container {
  width: $device-width;
  margin: 0 auto;
  padding-top: 150px;
}

.view-w {
  background: none;

  .mobile-only {
    display: none;
  }

  &.elysia {
    .navigation {
      &__margin {
        margin-bottom: 1px;
      }

      &__mainnet {
        &__current {
          width: 170px;
          height: 48px;
          border-radius: 5px;

          > h2 {
            font-size: 15px;
          }
        }

        &__change-network {
          &__wrapper {
            width: 170px;
            padding-top: 10px;

            > p {
              padding: 8px;
              font-size: 12px;
            }

            &.network-error {
              width: 185px;
            }
          }

          > h2 {
            font-size: 15px;
          }
        }
      }

      &__apy {
        display: flex;
        align-items: center;
        height: 33px;
        max-width: 1190px;
        margin: auto;

        > div {
          display: flex;

          > h3 {
            font-family: Inter;
            margin: 0;
            font-size: 13px;
          }

          > p {
            font-size: 12px;
          }

          > h3:nth-child(3),
          h3:nth-child(5),
          h3:nth-child(7) {
            padding-left: 13px;
          }

          > p:nth-child(2),
          p:nth-child(4),
          p:nth-child(6) {
            padding-right: 13px;
            border-right: 1px solid $font-color;
          }
        }
      }

      &__apy::after {
        content: '';
        position: absolute;
        border-bottom: 1px solid #efefef;
        height: 33px;
        left: 0;
        width: 100%;
      }

      &__wallet {
        display: inline-flex;
        background: $background-color;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid $elysia-color--deep;
        width: 165px;
        height: 48px;
        border-radius: 48px;
        margin-left: 15px;

        &__status {
          color: $elysia-color--deep;
        }

        &--connected {
          width: 170px;
          height: 48px;
          margin-left: 15px;
          display: inline-flex;
          border-radius: 5px;
          background: $background-color;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          position: relative;
          cursor: pointer;
          border: 1px solid $gray-line;

          &:hover {
            border: 1px solid $elysia-color;
          }

          &.unknown-chain {
            > img {
              width: 20px;
              height: 20px;
            }

            > h2 {
              font-size: 15px;
              font-family: Montserrat-bold;
            }
          }

          &.FAIL {
            box-shadow: 0 0 6px #ff1d1d81;
            border: 1px solid #ff1d1d;
          }

          &.PENDING {
            border: 2px solid #f5f5f5;
            color: #00bfff;
            box-shadow: inset 0 0 0 1px rgba(#00bfff, 0.5);

            &::before,
            &::after {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              content: '';
              z-index: -1;
              margin: -1 * 0.05 * 100%;
              box-shadow: inset 0 0 0 2px;
              animation: clip-me 2s linear infinite;
              // background: #00BFFF;
            }

            &::before {
              animation-delay: 2s * -0.5;
            }

            @keyframes clip-me {
              0%,
              100% {
                clip: rect(0, 184px, 2px, 0);
              }

              25% {
                clip: rect(0, 2px, 60px, 0);
              }

              50% {
                clip: rect(58px, 184px, 60px, 0);
              }

              75% {
                clip: rect(0, 183px, 60px, 181px);
              }
            }

            .loader {
              left: 15px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              position: relative;
              -webkit-animation: load5 1.1s infinite ease;
              animation: load5 1.1s infinite ease;
              -webkit-transform: translateZ(0);
              -ms-transform: translateZ(0);
              transform: translateZ(0);
            }
          }

          &.CONFIRM {
            box-shadow: 0 0 6px #23eb1b81;
            border: 1px solid #23eb1b;
          }
        }
      }
    }

    .main {
      width: $device-width;
      margin: 0 auto;
      margin-top: 100px;

      &__popup {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.7);
        overflow: hidden;
        backdrop-filter: blur(4px);

        &__container {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: fixed;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > img {
              margin-top: 20px;
              max-height: 80vh;
              cursor: pointer;
            }

            > div {
              display: flex;
              flex-direction: row;
              justify-content: left;
              align-items: center;
              gap: 10px;
              margin-left: 5px;

              > p {
                color: #ffffff;
                font-size: 20px;
                cursor: pointer;
              }
            }
          }
        }

        &__button {
          border: 1px solid #ffffff;
          width: 20px;
          height: 20px;
          cursor: pointer;

          &:hover {
            background-color: #ffffff50;
          }
        }
      }

      &__image-wrapper {
        width: 515px;

        .dom {
          top: -50px;
          z-index: 1;
          animation: floating alternate 4s ease-in-out infinite;
        }

        .pit {
          top: 200px;
        }

        @keyframes floating {
          from {
            top: -50px;
          }

          to {
            top: 40px;
          }
        }
      }

      &__title {
        height: calc(100vh - 100px);
        bottom: 50px;
        justify-content: space-between;
        gap: 30px;

        &__text-container {
          margin-bottom: 55px;

          > p {
            &:first-child {
              font-size: 37px;
              margin-bottom: 25px;

              strong {
                font-size: 37px;
                line-height: 50px;
              }
            }

            &:last-child {
              font-size: 20px;
              line-height: 25px;
            }
          }

          > h2 {
            font-size: 45px;
          }
        }

        &__button {
          > div {
            > p {
              font-size: 14px;
              color: $gray-font-color;
              width: 226px;
              margin-right: 30px;
              margin-bottom: 10px;
            }

            > a {
              width: 226px;
              height: 56px;
              margin-right: 30px;
              border-radius: 28px;

              > p {
                font-size: 20px;
                line-height: 56px;
              }
            }
          }
        }
      }

      &__section {
        min-height: 800px;
      }

      &__content {
        flex: 2;

        &__image-container {
          flex: 1;
          height: 800px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          &--01 {
            &--01 {
              top: 100px;
              position: relative;

              $pit-height: 292px;

              > img {
                object-fit: none;
              }

              .pit {
                height: $pit-height;
                top: calc(#{$pit-height} / 2 * -1);
                position: absolute;
              }

              .bottom {
                position: relative;
                z-index: 3;
              }
            }

            .token-low {
              position: relative;
              z-index: 2;
              margin-bottom: -60px;
              animation-duration: 15s;
              animation-name: down-fade;
              animation-iteration-count: infinite;
              width: 513px;
            }

            .token-many {
              position: relative;
              z-index: 2;
              margin-top: -210px;
              animation-duration: 15s;
              animation-name: up-fade;
              animation-iteration-count: infinite;
              width: 513px;
            }
          }

          &--02 {
            position: relative;

            .coin {
              position: absolute;
              top: 100px;
              left: 10px;
              animation-duration: 6s;
              animation-name: floating-image;
              animation-iteration-count: infinite;
            }

            .pit {
              position: absolute;
              bottom: -35px;
              left: -20px;
            }
          }

          &--03 {
            position: relative;

            .governance--image {
              width: 515px;
            }

            > div {
              position: absolute;
              right: 0;
              top: -150px;

              .paper--o {
                left: 50px;
                position: relative;
                z-index: 2;
                animation-delay: 1s;
                animation-duration: 6s;
                animation-name: floating-image;
                animation-iteration-count: infinite;
              }

              .paper--x {
                top: 20px;
                position: relative;
                z-index: 3;
                animation-duration: 6s;
                animation-name: floating-image;
                animation-iteration-count: infinite;
              }
            }

            .pit {
              position: absolute;
              right: 0;
            }
          }
        }

        @keyframes floating-image {
          0% {
            transform: translateY(0);
          }

          50% {
            transform: translateY(40px);
          }
        }

        @keyframes down-fade {
          0% {
            transform: translateY(0);
            opacity: 0;
          }

          10% {
            transform: translateY(40px);
            opacity: 1;
          }

          30% {
            transform: translateY(500px);
            opacity: 1;
          }

          100% {
            transform: translateY(500px);
          }
        }

        @keyframes up-fade {
          0% {
            transform: translateY(0);
            opacity: 1;
          }

          30% {
            transform: translateY(0);
          }

          60% {
            transform: translateY(-400px);
          }

          70% {
            transform: translateY(-380px);
          }

          80% {
            transform: translateY(-400px);
          }

          90% {
            transform: translateY(-380px);
            opacity: 1;
          }

          100% {
            transform: translateY(-400px);
            opacity: 0;
          }
        }

        > div {
          &:first-child {
            > h2 {
              font-size: 40px;
              margin-left: 25px;
              margin-right: 25px;
            }
          }

          &:last-child {
            > p {
              &:first-child {
                font-size: 40px;
                margin-bottom: 30px;
                line-height: 55px;
              }
            }

            strong {
              font-size: 40px;
            }

            > div {
              width: 226px;
              height: 56px;

              > p {
                font-size: 20px;
                line-height: 56px;
              }
            }
          }
        }

        &__details {
          font-size: 20px;
          line-height: 35px;
          margin-bottom: 55px;
        }
      }

      &__advantages {
        > h2 {
          font-size: 40px;
        }

        &__container {
          margin-top: 130px;
          margin-bottom: 130px;

          > div {
            width: 500px;
            margin-bottom: 110px;

            > img {
              width: 100px;
              height: 90px;
            }

            > div {
              margin-left: 18px;

              > h2 {
                font-size: 25px;
                line-height: 90px;
              }

              > p {
                font-size: 20px;
              }
            }
          }
        }
      }

      &__service {
        &__graph {
          &--circle {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;

            &.circle_01 {
              transform: scale(1.1);
              background: #f7fbff;
            }

            &.circle_02 {
              background: #f7fbff;
            }

            &.circle_03 {
              transform: scale(1.25);
              position: relative;
              background: #f7fbff;

              > div {
                &:first-child {
                  width: 21px;
                  height: 21px;
                  position: absolute;
                  border: 1px solid $elysia-color;
                  border-radius: 100%;
                  right: 8px;
                  top: 30px;
                  background: #ffffff;
                }

                &:last-child {
                  width: 13px;
                  height: 13px;
                  position: absolute;
                  border: 1px solid #00bfff;
                  border-radius: 100%;
                  right: 0;
                  top: 18px;
                  background: #ffffff;
                }
              }
            }

            > p {
              font-size: 20px;
              color: $font-color;
            }
          }

          &__line-wrapper {
            position: relative;
            flex: 0 !important;
          }

          width: 100%;

          &--section01 {
            display: flex;
            height: 200px;

            &--01,
            &--03,
            &--05 {
              > div {
                background-color: #ffffff;
                border: 1px solid $elysia-color;
                width: 200px;
                height: 200px;
                border-radius: 200px;
                z-index: 9;
                position: relative;
              }
            }

            &--02 {
              width: 255px;
              display: flex;
              flex-direction: column;
              height: 50%;
              margin-top: 50px;

              > div {
                flex: 1;

                &:first-child {
                  // border-bottom: 1px dashed $elysia-color;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }

            &--04 {
              display: flex;
              flex-direction: column;
              flex: 1;

              > p {
                font-size: 17px;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
              }

              > div {
                flex: 1;
                // border-top: 1px dashed $elysia-color;
                // border-bottom: 1px dashed $elysia-color;
              }
            }
          }

          &--section02 {
            display: flex;
            flex-direction: row;

            &--01 {
              display: flex;
              flex-direction: column;

              &--01 {
                width: 205px;
                height: 85px;
                display: flex;
                flex-direction: row;

                > div {
                  flex: 2;
                  height: 100%;

                  &:nth-child(3) {
                    flex: 0.5;
                  }

                  &:last-child {
                    // border-left: 1px dashed $elysia-color;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding-left: 20px;
                    text-align: center;
                  }
                }
              }

              &--02 {
                > div {
                  background-color: #ffffff;
                  border: 1px solid $elysia-color;
                  width: 200px;
                  height: 200px;
                  border-radius: 200px;
                  z-index: 9;
                  position: relative;
                }
              }
            }

            &--02 {
              display: flex;
              flex-direction: column;
              align-items: center;

              > div {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 350px;
                height: 180px;

                &:first-child {
                  .scroll-arrow {
                    height: 179px;
                  }

                  > div {
                    width: 342px;
                    height: 187px;
                    // border-right: 1px dashed $elysia-color;
                    // border-bottom: 1px dashed $elysia-color;
                  }
                }

                &:nth-child(2) {
                  > div {
                    width: 343px;
                  }
                }
              }
            }
          }
        }
      }

      &__partners {
        height: auto;

        > div {
          > h2 {
            font-size: 40px;
            margin-bottom: 45px;

            strong {
              color: $elysia-color;
            }
          }

          &:last-child {
            margin-top: 180px;

            > h2 {
              margin-bottom: 35px;
            }
          }
        }

        > div:nth-child(2) {
          margin-top: 180px;

          > h2 {
            margin-bottom: 35px;
          }

          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            > img:first-child {
              width: 261px;
              height: 72px;
              margin-right: 158px;
            }

            > img:last-child {
              width: 237px;
              height: 66px;
            }
          }
        }

        &__lawfirm {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr;
          width: 100%;
          gap: 30px 20px;
          align-items: center;
          justify-items: center;
        }
      }

      &__governance {
        > h2 {
          font-size: 40px;
        }

        &__table {
          width: $device-width;
          margin-top: 50px;

          > hr {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        &__converter {
          gap: 45px;

          &__button {
            height: 55px;

            > h2 {
              font-size: 20px;
              line-height: 55px;
            }
          }
        }

        &__header {
          padding: 21px 50px 9px;

          > p {
            font-size: 18px;
          }
        }

        &__body {
          margin: 0 25px;
          padding: 25px 0;

          > p {
            margin: 0 25px;
            font-size: 20px;
          }
        }

        &__more {
          margin-top: 20px;

          > p {
            font-size: 18px;
            line-height: 60px;
          }
        }
      }
    }

    .deposit {
      @include page-container;

      padding-top: 100px;

      &__title {
        .react-loading-skeleton {
          margin-top: 20px;
        }

        height: 300px;
        margin-bottom: 45px;

        > p {
          padding-top: 100px;
          font-size: 25px;
        }

        > h2 {
          padding-top: 16px;
          font-size: 50px;
        }

        &__round {
          margin-top: 35px;
          margin-bottom: 130px;

          > div:first-child {
            margin-left: 130px;
          }

          > div:last-child {
            margin-right: 130px;
          }

          > div {
            width: 210px;
            height: 66px;
            border-radius: 33px;
            background: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > p {
              line-height: 26px;
              font-size: 15px;
            }
          }
        }
      }

      &__table {
        margin-bottom: 50px;

        &__wrapper {
          margin-top: 20px;
        }

        &__header {
          padding: 20px 29px 20px 29px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &__token-info {
            padding: 10px 12px 10px;
            // min-width: 150px;

            > img {
              width: 50px;
              height: 50px;
            }

            > section {
              margin-left: 18px;

              > p:first-child {
                font-size: 15px;
                margin-bottom: 8px;
              }

              > p:last-child {
                font-size: 18px;
              }
            }
          }

          &__token-info::after {
            content: '';
            height: 48px;
            // width: 60px;
            border: 1px solid #e6e6e6;
            margin-left: 50px;
          }

          &__data-grid {
            width: 900px;
            gap: 10px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            justify-items: flex-end;

            > div {
              width: 210px;

              > p:first-child {
                display: flex;
                align-items: center;
                font-size: 15px;
                margin-bottom: 7px;

                > p {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 6px;
                }
              }

              > p:last-child {
                font-size: 18px;
              }
            }
          }
        }

        &__body {
          padding: 30px;

          &__reward {
            display: flex;
            align-items: center;
            justify-content: center;

            > h2:first-child {
              margin-right: auto;
              margin-bottom: 20px;
            }

            > div:nth-child(2) {
              margin-bottom: 20px;
            }
          }

          &__amount {
            &__button {
              border-radius: 15px;
              width: 160px;
              height: 30px;
              margin-bottom: 20px;

              > p {
                color: $font-color;
                font-size: 15px !important;
              }
            }

            > div {
              .amounts {
                font-size: 20px;
              }

              > h2 {
                font-size: 20px;
                margin-bottom: 5px;

                > span {
                  font-size: 17px;
                }
              }

              > p {
                font-size: 10px;
              }
            }

            &__wrapper {
              padding-top: 20px;
              padding-bottom: 20px;

              &.left {
                padding-right: 25px;
              }

              &.right {
                padding-left: 25px;
              }

              > div {
                > h2 {
                  font-size: 18px;
                }
              }
            }
          }

          &__event-box {
            margin-top: 15px;
            margin-bottom: 15px;

            &__content {
              border-bottom: 1px solid #f0f0f1;
              padding: 10px 0 20px;

              .event-button {
                margin-bottom: 0;
              }

              > div:first-child {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > .DAI {
                  strong {
                    color: #f9ae1a;
                  }

                  font-size: 18px;
                }

                > .USDT {
                  strong {
                    color: #26a17b;
                  }

                  font-size: 18px;
                }
              }

              > div:nth-child(2) {
                text-align: right;
                margin-top: 20px;

                > div {
                  font-size: 20px;
                  margin-bottom: 6px;

                  > span:last-child {
                    font-family: $bold;
                    font-size: 17px;
                  }
                }

                > p {
                  font-size: 12px;
                  color: $gray-font-color;
                }
              }
            }
          }

          &__loan-list {
            > div {
              > h2 {
                margin-top: 25px;
                font-size: 18px;
              }
            }

            &__more-button {
              height: 61px;
              line-height: 61px;
              cursor: pointer;

              > a > p {
                font-size: 15px;
              }
            }
          }

          &__strategy {
            padding-top: 25px;
            padding-bottom: 30px;

            > h2 {
              font-size: 18px;
              margin-bottom: 20px;
            }

            > div {
              width: 100%;
              height: 30px;

              > div {
                width: 100%;

                &:first-child {
                  border-radius: 15px 0 0 15px;

                  > p {
                    font-size: 15px;
                    line-height: 30px;
                  }
                }

                &:last-child {
                  border-radius: 0 15px 15px 0;

                  > p {
                    font-size: 15px;
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }
      }

      &__remote-control {
        width: 120px;

        &__wrapper {
          width: 120px;
          left: -150px;
        }

        > a > div {
          padding: 10px 0 14px;
        }

        &__name {
          > p {
            font-size: 14px;
          }
        }

        &__apy {
          font-size: 15px;
          line-height: 25px;
        }

        &__mining {
          width: 91px;
          height: 40px;

          > p {
            font-size: 10px;
            line-height: 15px;
          }
        }
      }
    }

    // deposit end

    .reward {
      @include page-container;

      padding-top: 100px;

      &__progress-bar {
        height: 13px;
        border-radius: 13px;
        margin-top: 5px;

        &__wrapper {
          margin-bottom: 12px;
        }

        &__content {
          height: 29px;
          top: 5px;

          &__fill {
            > p {
              font-size: 10px;
              margin-right: 3.5px;
            }
          }
        }
      }

      &__token {
        margin-top: 25px;

        &__image-container {
          > img {
            width: 60px;
            height: 60px;
          }
        }

        > img {
          width: 60px;
          height: 60px;
        }

        > h2 {
          margin-top: 15px;
          font-size: 25px;
        }

        &__elfi {
          width: 216px;
          height: 30px;
          border-radius: 15px;
          margin-top: 24px;
          margin-bottom: 90px;

          > p {
            font-size: 15px;
            line-height: 30px;
          }
        }

        &__container {
          margin-top: 130px;
          padding: 31px 29px 15px;
          background: #ffffff;
        }

        &__header {
          padding-bottom: 27px;

          > img {
            width: 37px;
            height: 37px;
          }

          > h2 {
            font-size: 18px;
            margin-left: 8px;
          }
        }

        &__staking {
          > div {
            padding: 25px 0 20px;

            &:first-child {
              padding-right: 25px;
            }

            &:last-child {
              padding-left: 25px;
            }

            > p {
              font-size: 15px;
            }

            > div {
              > h2 {
                font-size: 20px;
              }

              > span {
                margin-left: 5px;
                font-size: 20px;
              }
            }
          }
        }

        &__data {
          > div {
            &:first-child {
              padding-right: 25px;
            }

            &:last-child {
              padding-left: 25px;
            }
          }

          &__content {
            padding: 25px 0 12px;

            > h2 {
              font-size: 15px;
            }

            > div {
              margin: 17px 0;

              > p {
                font-size: 15px;
              }
            }
          }
        }

        @mixin arrow-transform($angle, $x: 0, $y: 0) {
          i:first-child {
            transform: translate($x, $y) rotate($angle);
          }

          i:last-child {
            transform: translate($x, -$y) rotate(-$angle);
          }
        }

        &__array-handler {
          top: 360px;

          > div {
            margin: 10px;
            width: 40px;
            height: 40px;
            transform: translate3d(0, 0, 0); // fixes flicker in webkit
            margin-top: -20px;
            filter: drop-shadow(0 3px 0 #00000029);
            -webkit-filter: drop-shadow(0 3px 0 #00000029);

            > i {
              position: absolute;
              top: 40%;
              left: 0;
              width: 35px;
              height: 1px;
              border-radius: calc(1px / 2);
              background: $elysia-color;
              transition: all 0.15s ease;
            }
          }

          &--left {
            left: -80px;

            @include arrow-transform(40deg, 0, -0);

            &:hover {
              @include arrow-transform(30deg, 0, 0);
            }

            &:active {
              @include arrow-transform(25deg, 0, -0);
            }

            &.disabled {
              @include arrow-transform(0deg, -5px, 0);

              &:hover {
                @include arrow-transform(0deg, -5px, 0);
              }
            }
          }

          &--right {
            right: -80px;
            @include arrow-transform(40deg, 0, 0);

            &:hover {
              @include arrow-transform(30deg, 0, 0);
            }

            &:active {
              @include arrow-transform(25deg, 0, 0);
            }

            &.disabled {
              @include arrow-transform(0deg, 5px, 0);

              &:hover {
                @include arrow-transform(0deg, 5px, 0);
              }
            }
          }

          .disabled {
            opacity: 0.3;
            cursor: default;
          }
        }

        &__lp {
          &__container {
            margin-top: 110px;
            padding: 30px 30px 20px;
          }

          &__title {
            padding-bottom: 27px;

            > img {
              width: 37px;
              height: 37px;

              &.last-token {
                margin-left: -5px;
                border-radius: 37px;
                margin-right: 10px;
              }
            }

            > h2 {
              font-size: 18px;
            }
          }

          &__content {
            > div {
              flex: 1;
              padding-top: 25px;
              padding-bottom: 25px;

              > p {
                font-size: 15px;
              }

              > h2 {
                font-size: 20px;
              }
            }

            &--left {
              padding-right: 25px;
            }

            &--right {
              padding-left: 25px;
            }
          }
        }
      }

      &__token-deposit {
        padding: 25px 30px 24px;
        margin-bottom: 42px;

        &__header {
          padding-bottom: 18px;

          > img {
            width: 27px;
            height: 27px;
            margin-right: 8px;
          }

          > div {
            > p {
              &:first-child {
                font-size: 18px;
                margin-bottom: 10px;
              }

              &:last-child {
                font-size: 15px;
              }
            }
          }
        }

        &__apy {
          > div {
            flex: 1;
            padding: 28px 0 25px;

            > div {
              > p {
                font-size: 15px;
              }

              > h2 {
                font-size: 20px;
                line-height: 35px;
              }
            }
          }

          &--left {
            padding-right: 25px !important;
          }

          &--right {
            padding-left: 25px !important;
          }
        }

        &__data {
          > div {
            flex: 1;
            padding: 21px 0 18px;

            &:first-child {
              padding-right: 25px !important;
            }

            &:last-child {
              padding-left: 25px !important;
            }
          }
        }
      }
    }

    // reward end

    .detail {
      @include page-container;

      padding-top: 150px;

      &__header {
        margin-top: 25px;
        margin-bottom: 90px;

        > img {
          width: 60px;
          height: 60px;
        }

        > h2 {
          margin-top: 15px;
          font-size: 25px;
        }

        &__round {
          margin-top: 35px;

          > div:first-child {
            margin-left: 130px;
          }

          > div:last-child {
            margin-right: 130px;
          }

          > div {
            width: 210px;
            height: 66px;
            border-radius: 33px;
            background: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > p {
              line-height: 26px;
              font-size: 15px;
            }
          }
        }
      }

      &__data-wrapper {
        padding: 19px 29px 0 29px;
        margin-bottom: 40px;

        &__title {
          padding-bottom: 13px;

          > h2 {
            font-size: 18px;
          }
        }

        &__total {
          > div {
            padding-top: 15px;
            padding-bottom: 9px;

            &:first-child {
              padding-right: 23px;
            }

            &:last-child {
              padding-left: 23px;
            }

            > h2 {
              font-size: 15px;
            }
          }
        }

        &__info {
          > div {
            padding-top: 35px;
            padding-bottom: 35px;

            &:first-child {
              padding-right: 23px;
              height: 274px;

              > div {
                &:last-child {
                  margin: 0;
                }

                > p {
                  font-size: 15px;
                }
              }
            }

            &:last-child {
              padding-left: 23px;
              height: 235px;
            }
          }

          &__deposit {
            &__wrapper {
              > div {
                margin-bottom: 15px;

                > p {
                  font-size: 15px;
                }
              }
            }

            > div {
              width: 13px;
              height: 13px;
              border-radius: 13px;
              margin-right: 8px;
            }

            > p {
              font-size: 15px;
            }

            &__utilization {
              > h2 {
                font-size: 15px;
              }
            }

            &__strategy {
              width: calc(100% - 21px);
              margin: 0 0 0 21px;
              padding: 15px;
              border-radius: 5px;
              gap: 15px;

              > div {
                > p {
                  font-size: 14px;
                }

                > div {
                  gap: 5px;

                  > div {
                    width: 14px;
                    height: 14px;

                    > p {
                      font-size: 10px;
                    }
                  }

                  > p {
                    font-size: 14px;
                  }
                }
              }

              &__defi-wrapper {
                width: calc(100% - 15px) !important;
                bottom: -40px;
                left: 15px;
                padding: 15px 20px;

                > div {
                  > img {
                    width: 19px;
                    height: 19px;
                    margin-right: 8px;
                  }

                  > p {
                    font-size: 15px;
                  }
                }
              }
            }
          }

          &__circle-wrapper {
            margin-left: 40px;
          }
        }
      }

      &__graph {
        &__wrapper {
          &__tooltip {
            width: 226px;
            height: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px 16px 12px 21px;
            border-radius: 5px;

            &__payload {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 7px;

              > div:first-child {
                font-family: Inter;
                font-size: 12px;
                color: $gray-font-color;
              }

              > div:last-child {
                font-size: 15px;
                color: $font-color;
              }
            }
          }
        }

        &__converter {
          &__button {
            width: 120px;
            height: 40px;

            > h2 {
              font-size: 18px;
            }
          }
        }
      }
    }

    // detail end

    .market {
      @include page-container;
    }

    // market end

    .nft-details {
      @include page-container;

      margin-bottom: 200px;

      &__bond-nft > div {
        margin-top: 70px;

        > table {
          box-shadow: 0 0 0 1px #e3e3e3;
          border-collapse: collapse;
          border-radius: 10px;
          border-style: hidden;
          font-size: 15px;
          word-wrap: break-word;
          width: 100%;

          > tr {
            height: 38px;
            text-align: left;

            > th {
              padding: 18px 17px;
              width: 282px;
              background-color: #f8f9fa;
            }

            > td {
              padding: 18px 17px;
              word-break: break-all;

              &.image-wrapper {
                width: 100%;
              }

              > img {
                margin: 29px auto 20px;
                display: block;
                width: 100%;
              }

              section {
                margin-top: 12px;
                display: flex;
                align-items: center;

                > .table-button {
                  @include hover__sub_box-shadow;

                  display: flex;
                  align-items: center;
                  padding: 6px 10px;
                  margin-right: 5px;
                  border: 1px solid #3679b5;
                  border-radius: 5px;
                  cursor: pointer;

                  > img {
                    margin-right: 8px;
                  }

                  > p {
                    font-size: 12px;
                    color: #3679b5;
                    background: none;
                    -webkit-text-fill-color: initial;
                  }
                }
              }
            }

            > th,
            > td {
              border: 1px solid #e3e3e3;
            }

            &:last-child {
              > th {
                border-radius: 0 0 0 10px;
              }
            }
          }
        }
      }
    }

    .nft-details__current-nfts {
      margin-top: 150px;
    }

    // --kr styles

    .nft-details__real-estate-info__asset-feature--kr {
      margin-top: 70px;

      h2 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      div {
        display: flex;
        flex-direction: row;
        // height: 284px;

        .images {
          display: flex;
          flex-direction: column;
          margin-right: 40px;

          img {
            border-radius: 5px;
          }

          > img:first-child {
            width: 410px;
            height: 284px;
          }

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            margin-top: 10px;

            > figure {
              position: relative;
              flex: 1;
              cursor: pointer;
              transition: all 0.2s ease;

              &:not(.disable) {
                &:hover {
                  filter: brightness(120%);
                }
              }

              &.disable {
                &::after {
                  content: '';
                  width: 128px;
                  height: 100%;
                  z-index: 2;
                  left: 0;
                  border-radius: 5px;
                  position: absolute;
                  background: rgba(0, 0, 0, 0.6);
                  transition: all 0.2s ease;
                }

                &:hover {
                  &::after {
                    transition: all 0.2s ease;
                    background: rgba(0, 0, 0, 0.3);
                  }
                }

                &:active {
                  &::after {
                    transition: all 0.2s ease;
                    background: rgba(0, 0, 0, 0);
                  }
                }
              }

              > img {
                width: 128px;
                height: 100%;
                position: relative;
                object-fit: cover;
              }
            }
          }
        }

        .descriptions {
          height: 395px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px 0;
          line-height: 25px;

          > div {
            display: flex;

            &:last-child {
              margin: 0;
            }

            > div {
              display: flex;
              flex-direction: column;

              > b {
                font-size: 15px;
              }

              > p {
                font-size: 15px;
                color: #646464;

                &.bold {
                  font-family: SpoqaHanSansNeo;
                  color: #262626;
                }
              }
            }

            > b {
              color: #00a6ff;
              margin-right: 13px;
            }
          }
        }
      }
    }

    .nft-details__real-estate-info__around-asset--kr {
      margin-top: 70px;

      > h2 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      > section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 13px 14px;
        border: 1px solid #e3e3e3;

        img {
          width: 100%;
        }
      }
    }

    .nft-details__real-estate-info__fail-safe--kr {
      div.line {
        padding: 5px;
      }

      margin-top: 45px;

      > h2 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      > table {
        width: 100%;
        box-shadow: 0 0 0 1px #e3e3e3;
        border-collapse: collapse;
        border-radius: 10px;
        border-style: hidden;
        table-layout: fixed;
        margin: 10px 0 5px;
        font-family: SpoqaHanSansNeo;
        line-height: 25px;
        font-size: 15px;
        color: #262626;

        > tr {
          height: 38px;
          text-align: left;

          th {
            padding: 11px 17px;
            width: 250px;
            background-color: #f8f9fa;

            > div {
              display: flex;
            }

            b {
              color: #00a6ff;
              margin-right: 13px;
            }
          }

          > td {
            padding: 11px 17px;

            > div.paragraph {
              margin-top: 15px;

              &:last-child {
                margin-bottom: 15px;
              }
            }

            > div {
              section {
                padding-left: 21px;
              }
            }

            .week {
              color: #656565;
            }
          }

          > th,
          td {
            border: 1px solid #e3e3e3;
          }

          &:last-child {
            > th {
              border-radius: 0 0 0 10px;
            }
          }
        }
      }
    }

    // --kr styles

    .bond-nft--kr {
      margin-top: 70px;

      > table {
        > tr {
          > th {
            width: 275px;
          }

          > td {
            img.product_structure {
              width: 766px;
            }

            .paragraph {
              margin-top: 24px;

              b {
                padding-right: 8px;
              }
            }
          }
        }
      }
    }

    .nft-details__nft-info .nft-info__holder-benefits--kr {
      h2 {
        margin-top: 60px;
        margin-bottom: 10px;
        font-size: 18px;
        margin-right: 10px;
      }

      section {
        font-size: 15px;
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        opacity: 1;
        padding: 20px;

        div.row {
          display: flex;

          div.card {
            margin: 5px 8px;
            padding: 15px 0 15px 30px;
            width: 50%;
            display: flex;
            border: 1px solid #e6e6e6;
            border-radius: 5px;
            opacity: 1;

            .circle {
              flex-shrink: 0;
              background-color: #e7f1fd;
              width: 86px;
              height: 86px;
              border-radius: 50%;
              text-align: center;
              line-height: 86px;
              display: inline-block;
              font-weight: bold;
            }

            .description {
              padding: 10px 20px;

              p {
                // font: normal normal bold 15px/28px Spoqa Han Sans Neo;
                font-size: 15px;
                font-family: SpoqaHanSansNeo;
                line-height: 28px;
                font-weight: bold;
                letter-spacing: 0;
                color: #333333;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    // nft-details end

    .governance {
      @include page-container;

      &__asset {
        width: 379px;

        &__progress-bar {
          height: 13px;
          border-radius: 13px;

          &::-webkit-progress-bar {
            height: 13px;
            border: 0;
            border-radius: 13px;
            background: #e6e6e6;
          }

          &::-webkit-progress-value {
            height: 13px;
            border: 0;
            border-radius: 13px;
          }

          &::-moz-progress-bar {
            height: 13px;
            border-radius: 13px;
            border: 0;
          }
        }

        > div {
          &:last-child {
            > div {
              padding: 10px 30px;

              > p {
                font-size: 15px;
              }

              &:last-child {
                padding: 0 30px 20px;

                > div > h2 {
                  font-size: 15px;
                  line-height: 25px;
                  margin-top: 13px;
                }
              }
            }
          }
        }
      }

      &__grid {
        margin-bottom: 125px;
        grid-gap: 50px 25px;
        grid-template-columns: repeat(2, 1fr);

        > a {
          cursor: pointer;

          > article {
            background: $background-color;
            border-radius: 10px;
            box-shadow: 0 0 6px #1e54711d;
            padding: 22px 41px 23px 29px;

            > header {
              display: flex;
              height: 25px;
              width: 90px;
              align-items: center;
              background-color: #d9edff;
              border-radius: 13px;
              margin-bottom: 15px;

              &.ELYIP {
                background-color: #d3f4ff;

                > p {
                  color: #00beff;
                }
              }

              > p {
                cursor: pointer;
                font-size: 13px;
                width: 90px;
                font-family: SpoqaHanSansNeo-Bold, Inter-bold;
                color: #3ca3ff;
                text-align: center;
              }
            }

            > section {
              margin-bottom: 35px;
              height: 100px;

              > p:first-child {
                cursor: pointer;
                font-family: SpoqaHanSansNeo-Bold, Inter-bold;
                font-size: 15px;
                color: #262626;
                margin-bottom: 10px;
                height: 41px;
              }

              > p:last-child {
                cursor: pointer;
                font-family: SpoqaHanSansNeo, Inter;
                color: #707070;
                font-size: 13px;
                height: 30px;
              }
            }

            > div {
              font-family: SpoqaHanSansNeo, Inter;
              display: flex;
              align-items: center;
              font-size: 13px;

              > p {
                cursor: pointer;
                color: #262626;
                margin-right: 25px;
              }

              > div {
                display: flex;
                align-items: center;
                justify-content: center;

                > div {
                  width: 10px;
                  height: 10px;
                  background-color: #57b275;
                  border-radius: 10px;
                }

                > p {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      &__elfi {
        &__info {
          width: 100%;
          background-color: #ffffff;
          box-shadow: 0 0 6px #1e54711d;
          border-radius: 10px;
          padding: 44px 31px 48px 29px;

          &__first {
            margin-bottom: 82px;

            > header {
              font-family: SpoqaHanSansNeo-Bold, Inter-Bold;
              font-size: 15px;
              margin-bottom: 32px;

              > div {
                display: flex;
                align-items: center;

                > span {
                  color: $elysia-color;
                  margin-right: 5px;
                }

                > a {
                  margin-left: auto;

                  > p {
                    cursor: pointer;
                    font-family: SpoqaHanSansNeo-All-Bold;
                    color: #00a7ff;
                    // font-weight: 900;
                  }
                }
              }

              > p {
                margin-top: 13px;
                font-size: 13px;
                color: #707070;
                margin-left: 25px;
              }
            }

            > section {
              width: 100%;
              display: flex;

              > figure {
                margin-left: 25px;

                > img {
                  width: 136px;
                  height: 136px;
                }
              }

              > article {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                max-width: 952px;
                margin-left: 33px;

                > div {
                  width: 452px;
                  height: 66px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-radius: 10px;
                  border: 1px solid #e6e6e6;
                  padding: 22px 25px 18px 25px;

                  > p:first-child {
                    font-size: 13px;
                    color: #707070;

                    > span {
                      margin-left: 5px;
                      // display: flex;
                      // align-items: center;
                    }
                  }

                  > p:last-child {
                    font-family: Inter-Number-Bold, Inter-Bold;
                    font-weight: bold;
                    font-size: 22px;
                    color: #262626;
                  }
                }
              }
            }
          }

          &__second {
            margin-bottom: 82px;

            > header {
              font-family: SpoqaHanSansNeo-Bold, Inter-Bold;
              font-size: 15px;
              margin-bottom: 32px;

              > div {
                display: flex;

                > span {
                  color: $elysia-color;
                  margin-right: 5px;
                }

                > a {
                  margin-left: auto;

                  > p {
                    cursor: pointer;
                    font-family: SpoqaHanSansNeo-All-Bold;
                    color: #00a7ff;
                  }
                }
              }

              > p {
                margin-top: 13px;
                font-size: 13px;
                color: #707070;
                margin-left: 25px;
              }
            }

            > section {
              width: 100%;

              > article {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-left: 25px;

                > div {
                  width: 530px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-radius: 10px;
                  border: 1px solid #e6e6e6;
                  padding: 22px 25px 18px 25px;

                  > p:first-child {
                    font-size: 13px;
                    color: #707070;
                  }

                  > p:last-child {
                    font-family: Inter-Number-Bold, Inter-Bold;
                    font-weight: bold;
                    font-size: 22px;
                    color: #262626;
                  }
                }
              }
            }
          }

          &__third {
            > header {
              font-family: SpoqaHanSansNeo-Bold, Inter-Bold;
              font-size: 15px;
              margin-bottom: 32px;

              > div {
                display: flex;

                > span {
                  color: $elysia-color;
                  margin-right: 5px;
                }

                > a {
                  margin-left: auto;

                  > p {
                    cursor: pointer;
                    font-family: SpoqaHanSansNeo-All-Bold;
                    color: #00a7ff;
                    font-weight: 900;
                  }
                }
              }

              > p {
                margin-top: 13px;
                font-size: 13px;
                color: #707070;
                margin-left: 25px;
              }
            }

            > section {
              width: 100%;

              > article {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-left: 25px;

                > a {
                  cursor: pointer;

                  > div {
                    width: 530px;
                    border-radius: 10px;
                    border: 1px solid #e6e6e6;
                    padding: 25px 0 27px 25px;

                    > img {
                      width: 55px;
                      height: 55px;
                    }

                    > p:nth-child(2) {
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      font-family: Inter-Bold;
                      font-size: 15px;
                      color: #262626;
                      margin-top: 11px;
                      margin-bottom: 11px;

                      > img {
                        margin-left: 6.76px;
                      }
                    }

                    > p:last-child {
                      cursor: pointer;
                      font-size: 13px;
                      color: #707070;
                      height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &__header {
        > div:first-child {
          padding-bottom: 17px;
          margin-bottom: 20px;

          > header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > h3 {
              display: flex;
              margin: 0;

              > span {
                margin-left: 5px;
                display: flex;
                align-items: center;
              }
            }

            > a {
              > p {
                cursor: pointer;
                font-family: SpoqaHanSansNeo-Bold, Inter-Bold, Montserrat-bold;
                color: $elysia-color;
                font-weight: 900;
                font-size: 15px;
              }
            }
          }
        }
      }

      &__elyfi-graph {
        padding: 30px;
        margin-bottom: 80px;

        > div {
          &:first-child {
            > div {
              border-radius: 15px;
              height: 50px;

              > p {
                line-height: 50px;
              }
            }
          }

          &:last-child {
            > div {
              border-radius: 15px;
              height: 50px;

              > p {
                line-height: 50px;
              }
            }
          }
        }
      }

      &__content {
        h2 {
          font-size: 30px;
          margin-bottom: 30px;
        }

        &__button {
          margin: 0 40px;
          border-radius: 30px;
          padding: 5px 30px;

          &__wrapper {
            margin: 40px;

            p {
              font-size: 15px;
            }
          }
        }
      }
    }

    // governance end

    .loan {
      margin-top: 100px;
      margin-bottom: 100px;

      > div {
        margin-bottom: 25px;
        padding-bottom: 15px;

        > h2 {
          font-size: 18px;
          margin-bottom: 15px;
        }

        > p {
          font-size: 15px;
        }
      }
    }

    .portfolio {
      @include page-container;

      &__view-button {
        height: 61px;
        font-size: 15px;
      }

      &__borrower {
        padding: 35px 30px 0;

        &__title {
          font-size: 18px;
          padding-bottom: 10px;
        }

        &__header {
          padding-top: 20px;
          padding-bottom: 20px;

          > img {
            width: 160px;
          }

          > div {
            > p {
              font-size: 15px;
              line-height: 25px;
            }
          }
        }

        &__data {
          > div {
            padding-top: 22px;

            > div {
              padding-bottom: 20px;

              > p {
                font-size: 15px;
              }
            }
          }

          &--left {
            position: relative;
            padding-right: 25px;
          }

          &--right {
            position: relative;
            padding-left: 25px;
          }
        }
      }

      &__collateral {
        margin-top: 25px;
        padding: 35px 30px 25px;
        margin-bottom: 100px;

        > h2 {
          padding-bottom: 10px;
          font-size: 18px;
        }

        &__data {
          &--left {
            padding-right: 25px;
            padding-top: 30px;
            padding-bottom: 30px;
          }

          &--right {
            padding-left: 25px;
            padding-top: 30px;
            padding-bottom: 30px;
            position: relative;

            > div {
              margin-bottom: 20px;

              > p {
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    // portfolio end

    .staking {
      @include page-container;

      position: relative;

      &__title {
        > h2 {
          font-size: 30px;
          margin-bottom: 15px;
        }

        > p {
          line-height: 25px;
          font-size: 15px;
        }

        &__token {
          display: flex;
          width: 680px;
          justify-content: space-between;
          gap: 50px;
        }

        &__button {
          min-width: 220px;
          min-height: 35px;
          border-radius: 35px;
          padding: 5px 23px;
          margin-top: 30px;
          margin-bottom: 100px;

          > div {
            > p {
              font-size: 15px;
            }

            > img {
              width: 34px;
              height: 34px;
              margin-left: -10px;
              margin-right: 5px;
            }
          }
        }

        &__content {
          > p {
            font-size: 15px;
            line-height: 25px;
            flex: 4;
          }

          > div {
            flex: 1;
          }

          &__token-wrapper {
            margin-bottom: 12px;

            > img {
              width: 37px;
              height: 37px;
            }

            > h2 {
              font-size: 18px;
              margin-left: 10px;
            }
          }
        }
      }

      &__round {
        margin-top: 50px;

        $current-data-height: 110px;

        .token-amount {
          margin-left: 5px;
        }

        &__border {
          width: 27px;
          margin-top: calc(#{$current-data-height} / 2);
        }

        &__container {
          flex: 1;
        }

        &__button {
          border-radius: 15px;
          width: 210px;
          height: 30px;
          line-height: 30px;
          padding: 0 20px;

          > p {
            font-size: 13.5px;
          }
        }

        &__current-data {
          padding: 26px 63px 25px 28px;
          height: $current-data-height;

          > div {
            > h2 {
              font-size: 15px;
            }

            > div {
              > p {
                font-size: 15px;

                strong {
                  font-size: 15px;
                }
              }
            }

            &:last-child {
              > p {
                font-size: 15px;
              }

              > h2 {
                font-size: 25px;
              }
            }
          }
        }

        // current-data end

        &__previous {
          &__wrapper {
            width: 100%;
            border-radius: 10px;
            padding: 20px 30px 5px;
          }

          height: 135px;
          padding: 15px 25px;
          border: 1px solid $gray-line;
          border-radius: 10px;
          margin-bottom: 15px;

          &__title {
            border-radius: 10px;

            > h2 {
              font-size: 15px;
              margin: 21px 0 15px;
            }

            > p {
              font-size: 12px;
              line-height: 17px;
              margin: 0 13px 17px;
            }
          }

          &__body {
            margin: 0 15px 0 31px;

            > div {
              > p {
                font-size: 15px;
              }

              > h2 {
                font-size: 20px;
              }
            }
          }

          > div:first-child {
            width: 800px;
          }
        }

        // previous end

        &__remaining-data {
          margin: 45px 0 0;
          height: 200px;

          &__title {
            padding: 23px 28px 16px 28px;

            > div:first-child {
              > h2 {
                font-size: 15px;
                margin-bottom: 15px;
              }

              > p {
                font-size: 12px;
                line-height: 17px;
                margin-bottom: 16px;
              }
            }

            > div:last-child {
              width: 180px;
              padding: 15px;

              > p {
                font-size: 15px;
              }

              > h2 {
                font-size: 20px;
              }
            }
          }

          &__body {
            > div {
              > h2 {
                margin: 14px 0 14px 23px;
                font-size: 15px;
              }

              > div {
                > h2 {
                  font-size: 25px;
                  margin-top: 25px;
                  margin-right: 25px;
                  margin-bottom: 11px;
                }

                > p {
                  font-size: 18px;
                  margin-right: 25px;
                  margin-bottom: 11px;
                }
              }
            }
          }
        }
      }

      &__lp {
        &__header {
          > h2 {
            font-size: 30px;
            margin-bottom: 16px;
          }

          > p {
            font-size: 15px;
          }

          > div {
            margin-top: 35px;
            margin-bottom: 130px;

            > div {
              width: 210px;
              height: 47px;
              border-radius: 24px;

              > p {
                line-height: 47px;
                font-size: 15px;
              }
            }
          }
        }

        &__detail-box {
          margin-top: 10px;
          gap: 25px;

          &__container {
            flex: 1;
            position: relative;
          }

          &__header {
            padding: 20px 30px;
            top: 2px;
            z-index: 2;

            > img {
              width: 37px;
              height: 37px;

              &.second-token {
                left: -5px;
                border-radius: 37px;
              }
            }

            > h2 {
              font-size: 18px;
              margin-left: 5px;
            }
          }

          &__item-header {
            padding: 2px 30px 4px;

            > div {
              > div {
                padding-top: 20px;
                padding-bottom: 16px;

                > p {
                  font-size: 15px;
                }
              }

              > h2 {
                font-size: 20px;
              }
            }
          }

          &__receive-token {
            padding: 25px 30px;

            &__header {
              margin-bottom: 20px;
            }

            &__button {
              width: 179px;
              height: 30px;
              border-radius: 15px;
              line-height: 30px;

              > p {
                font-size: 15px;
              }
            }

            &__content {
              > p {
                font-size: 15px;
              }
            }
          }

          &__staking {
            padding: 20px 30px 34px;

            &__button {
              width: 179px;
              height: 30px;
              border-radius: 15px;
              line-height: 30px;

              > p {
                font-size: 15px;
              }
            }

            &__value {
              margin-top: 30px;

              > h2 {
                font-size: 25px;
              }
            }
          }

          &__time-data {
            margin-top: 15px;
            font-size: 12px;
          }
        }

        // detail-box end

        &__staked {
          margin-top: 60px;

          &__header {
            margin-top: 86px;
            padding-bottom: 10px;

            > h2 {
              font-size: 18px;
            }
          }

          &__table {
            border-radius: 10px 10px 0 0;
            margin-top: 20px;
            padding: 35px 30px 0;

            &__header {
              &__container {
                &--left {
                  flex: 5;
                  margin-bottom: 7px;
                  font-size: 15px;

                  > div {
                    &:nth-child(1) {
                      flex: 2;
                    }

                    &:nth-child(2) {
                      flex: 2;
                    }

                    &:nth-child(3) {
                      flex: 3;
                    }

                    &:nth-child(4) {
                      flex: 4;
                    }
                  }
                }

                &--center {
                  width: 35px;
                }

                &--right {
                  flex: 2;
                  margin-bottom: 7px;
                  font-size: 15px;
                }
              }
            }

            &__content {
              margin-bottom: 20px;

              &__button {
                width: 179px;
                height: 30px;
                border-radius: 15px;
                line-height: 30px;

                > p {
                  font-size: 15px;
                }
              }

              &--left {
                flex: 5;
                padding: 21px 0;

                > div {
                  > h2 {
                    font-size: 15px;
                  }

                  &:nth-child(1) {
                    flex: 2;
                  }

                  &:nth-child(2) {
                    flex: 2;
                  }

                  &:nth-child(3) {
                    flex: 3;
                  }

                  &:nth-child(4) {
                    flex: 4;
                    gap: 18px;
                  }
                }
              }

              &--center {
                width: 35px;
              }

              &--right {
                flex: 2;
                gap: 18px;
                padding: 21px 0 15px;

                > div {
                  padding: 0 15px;
                }

                &__image {
                  gap: 8px;

                  > img {
                    width: 30px;
                    height: 30px;
                  }

                  > h2 {
                    font-size: 15px;
                  }
                }

                &__reward {
                  &__amount {
                    font-size: 20px;
                  }

                  &__unit {
                    font-size: 20px;
                  }
                }
              }
            }
          }

          &__reward {
            padding: 11px;
            top: -2px;

            &__total-liquidity {
              flex: 2.5;

              > h2 {
                font-size: 17px;
                margin: 15px 0;
              }
            }

            &__amount {
              flex: 5;
              padding: 0 20px 0 44px;

              &__unit {
                display: flex;

                &__wrapper {
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  flex: 3;
                  justify-content: space-around;

                  > div {
                    display: flex;
                    -webkit-flex-direction: row;
                    flex-direction: row;
                    -webkit-align-items: center;
                    align-items: center;

                    > span {
                      font-size: 17px;
                      color: $font-color;
                    }

                    > h2 {
                      font-size: 17px;
                      color: $sub-font-color;
                      margin-left: 5px;
                    }
                  }
                }
              }

              > h2 {
                flex: 1;
                font-size: 17px;
              }

              > div {
                display: flex;
                flex: 2;
                justify-content: space-around;
                align-items: center;

                > div {
                  display: flex;
                  align-items: center;

                  > span {
                    font-size: 17px;
                  }

                  > h2 {
                    font-size: 17px;
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }

        &__reward {
          margin-top: 73px;

          &__button {
            width: 179px;
            height: 30px;
            border-radius: 15px;
            line-height: 30px;

            > p {
              font-size: 15px;
            }

            &__wrapper {
              padding: 31px 0 21px;
            }
          }

          &__header {
            padding-bottom: 10px;

            > h2 {
              font-size: 18px;
            }
          }

          &__content {
            margin-top: 20px;
            border-radius: 10px;

            &__container {
              > div {
                flex: 1;
              }
            }

            &__wrapper {
              padding: 26px 30px 27px 20px;
            }

            &__amount {
              > h2 {
                font-size: 20px;

                &:last-child {
                  font-size: 17px;
                  margin-left: 5px;
                }
              }
            }
          }

          &__image {
            margin-bottom: 17px;

            > img {
              width: 30px;
              height: 30px;
            }

            > h2 {
              font-size: 20px;
              margin-left: 8px;
            }
          }
        }
      }

      &__coming-soon {
        width: 335px;
        height: 335px;
        border-radius: 100%;
        margin: 70px auto;

        > div {
          border-radius: 100%;
          background: #ffffff;
          position: absolute;

          &:nth-child(2) {
            left: 0;
            width: 20px;
            height: 20px;
            top: 100px;
          }

          &:nth-child(3) {
            right: 2px;
            width: 10px;
            height: 10px;
            bottom: 85px;
          }

          &:last-child {
            right: 15px;
            width: 20px;
            height: 20px;
            bottom: 69px;
          }

          > h2 {
            font-size: 30px;
          }
        }
      }
    }

    // staking end

    .modal {
      &__button {
        width: 452px;
        height: 47px;
        margin: 30px auto;
        line-height: 47px;
        border-radius: 47px;

        > p {
          font-size: 18px;
        }
      }

      &__container {
        border-radius: 10px;
        width: 514px;
      }

      &__header {
        padding: 20px 30px;

        &__image {
          width: 41px;
          height: 41px;
          margin-right: 15px;

          &--sub-token {
            width: 41px;
            height: 41px;
            margin-left: -22px;
            margin-right: 10px;
          }
        }

        &__name {
          font-size: 18px;
        }
      }

      &__converter {
        &__column {
          flex: 1;

          > h2 {
            font-size: 15px;
            margin-top: 15px;
            margin-bottom: 15px;
          }

          &.selected {
            flex: 1;
          }
        }
      }

      &__input {
        height: 144px;
        padding: 0 20px;

        &__maximum {
          font-size: 20px;
          margin-right: 15px;
        }
      }

      &__deposit {
        &__despositable-amount {
          margin: 29px 26px 0;
          padding-bottom: 30px;

          > p {
            font-size: 15px;
          }

          > div {
            margin-top: 20px;

            > h2 {
              font-size: 15px;
            }
          }
        }

        &__despositable-value {
          margin: 29px 26px 31px;

          > p {
            margin-bottom: 16px;
            font-size: 15px;
          }

          > div {
            > h2 {
              margin: 7.5px 0;
              font-size: 15px;
            }
          }
        }
      }

      &__withdraw {
        &__withdrawalable {
          &__amount {
            margin: 29px 26px 0;
            padding-bottom: 30px;
          }

          &__value {
            margin: 29px 26px 31px;
          }

          &__content {
            > h2 {
              margin: 7.5px 0;
              font-size: 15px;
            }
          }

          &__title {
            margin-bottom: 20px;

            > p {
              font-size: 15px;
            }
          }
        }
      }

      &__confirm {
        padding: 20px 15px 25px;

        > img {
          width: 152px;
        }

        > div {
          > h2 {
            margin-top: 50px;
            margin-bottom: 30px;
            font-size: 16px;
          }
        }
      }

      &__incentive {
        &__body {
          height: 140px;
        }

        &__value {
          padding: 20px;
          font-size: 50px;
        }
      }

      &__account {
        margin: 30px;

        &__mainnet-info {
          border-radius: 10px 10px 0 0;
          padding: 14px 20px;

          > div:first-child {
            > p {
              margin: 0 0 5px 0;
            }

            > div {
              > div {
                width: 14px;
                height: 14px;
                border-radius: 14px;
                margin-right: 6px;
              }
            }
          }

          > div:last-child {
            > p {
              margin: 7px 29px;
            }
          }

          &__button {
            border-radius: 0 0 10px 10px;

            > div {
              > p {
                margin: 10px;
              }

              &:first-child {
                border-radius: 0 0 0 10px;
              }
            }

            > a {
              > div {
                > p {
                  margin: 10px;
                }
              }
            }
          }
        }

        &__status {
          margin-top: 25px;
          margin-bottom: 10px;

          > a {
            > div {
              margin-top: 10px;
              padding: 10px 20px;

              > div {
                > img {
                  width: 24px;
                  height: 24px;
                  margin-right: 5px;
                }
              }
            }
          }
        }

        &__add-tokens {
          margin-top: 25px;

          > h2 {
            font-size: 18px;
          }

          > div {
            padding: 20px;
            border-radius: 10px;
            margin-top: 10px;

            > div {
              border-radius: 10px;
              padding: 10px 15px;

              &:not(:last-child) {
                margin-bottom: 15px;
              }

              > div {
                > img {
                  width: 24px;
                  height: 24px;

                  &:first-child {
                    margin-right: 12px;
                  }
                }

                > div {
                  > img {
                    width: 24px;
                    height: 24px;

                    &:first-child {
                      margin-right: 12px;
                    }
                  }
                }
              }

              > p {
                font-size: 15px;
              }
            }
          }
        }
      }

      &__staking {
        &__container {
          padding: 28px 27px 30px 26px;

          > div {
            > h2 {
              font-size: 15px;
              margin-top: 19px;
            }
          }
        }
      }

      &__migration {
        &__wrapper {
          padding: 15px 31px 26px;

          > div {
            > h2 {
              font-size: 15px;
              margin-bottom: 13px;
            }
          }
        }

        &__input {
          height: 70px;
          border-radius: 10px;
          padding: 0 20px 0 20px;
        }

        &__popup {
          &__info {
            > h2 {
              font-size: 15px;
              margin-bottom: 13px;
            }
          }
        }

        .arrow-wrapper {
          height: 55px;
          margin: 15px 15px 0;
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;

          > .line {
            height: 0;
            width: 100%;
            border: 1px dashed $elysia-color;
          }

          > .left-arrow {
            width: 0;
            height: 0;
            border-right: 10px solid $elysia-color;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
          }

          > .right-arrow {
            width: 0;
            height: 0;
            border-left: 10px solid $elysia-color;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
          }
        }

        &__nth {
          margin-top: 9px;

          > img {
            margin: 0 8px;
          }

          > p {
            font-size: 12px;
          }
        }

        &__content {
          padding: 0 31px 35px;

          > p {
            margin-top: 35px;
            font-size: 15px;
          }

          > div {
            > h2 {
              margin-top: 20px;
              font-size: 15px;
            }
          }
        }
      }

      &__alert {
        &__content {
          > h2 {
            font-size: 15px;
            margin-top: 50px;
            line-height: 25px;
          }
        }

        &__wrapper {
          padding: 21px 30px;
          margin: 40px 0;
          border-radius: 10px;

          > p {
            font-size: 15px;
            margin-bottom: 10px;
          }

          > div {
            > p {
              font-size: 15px;
            }
          }
        }
      }

      &__lp {
        &__reward {
          &__container {
            > div {
              margin: 0 30px;

              > div {
                padding: 20px 0;

                > img {
                  width: 26px;
                  height: 26px;
                  margin-right: 10px;
                }

                > h2 {
                  font-size: 18px;
                }
              }

              > h2 {
                font-size: 20px;

                > span {
                  font-size: 15px;
                  margin-left: 5px;
                }
              }
            }
          }
        }

        &__staking {
          &__undefined {
            > h2 {
              font-size: 15px;
              padding: 100px 0;
            }
          }
        }
      }

      &__connect {
        &__content {
          padding: 40px;

          > p {
            font-size: 15px;
            line-height: 25px;
          }
        }
      }
    }

    // modal end

    .component {
      &__loan-list {
        width: $loan-list-size;

        &__container {
          display: grid;
          grid-template-columns: repeat(3, $loan-list-size);
          grid-template-rows: 1fr;
          justify-content: space-between;
          margin-top: 25px;
          margin-bottom: 30px;
          border: 0;
          gap: 45px;
        }

        &__image {
          height: 256px;
        }

        &__data {
          padding: 15px;

          > div {
            > p:last-child {
              font-size: 18px;
              color: $font-color;
            }

            > p:first-child {
              font-size: 12px;
              margin-bottom: 5px;
            }
          }
        }
      }

      // loan-list end

      &__text-navigation {
        padding-top: 30px;
        font-size: 15px;

        > p {
          font-size: 15px;
        }
      }

      // text navigation end

      &__progress-bar {
        > div {
          margin-bottom: 12px;

          &:first-child {
            > p {
              font-size: 15px;
            }
          }

          &:nth-child(2) {
            > p {
              font-size: 13px;

              &:last-child {
                font-size: 13px;

                > span {
                  font-size: 15px;
                }
              }

              > span {
                font-size: 15px;
              }
            }
          }
        }

        progress {
          height: 13px;
          border-radius: 13px;
          margin-top: 5px;

          &::-webkit-progress-bar {
            border-radius: 13px;
          }

          &::-webkit-progress-value {
            border-radius: 13px;
          }
        }
      }

      // progress bar end

      &__data-info {
        padding: 25px 0 12px;

        > div {
          > p {
            font-size: 15px;
            margin-bottom: 10px;
          }
        }
      }

      // data info end

      &__popup-info {
        margin-left: 5px;
        width: 15px;
        height: 15px;
        margin-top: 3px;

        &__hover-mark {
          border-radius: 100%;
          font-size: 12px;
          line-height: 14px;
        }

        &__content {
          top: 30px;
          padding: 20px 25px;
          border-radius: 6px;

          > p {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }

    .percent {
      &::after {
        margin-left: 5px;
      }
    }

    .amount {
      &::before {
        margin-right: 5px;
      }
    }

    .equal_amount {
      &::before {
        margin-right: 2px;
      }
    }

    .reward-plan-button {
      > a {
        > p {
          font-size: 15px;
        }
      }
    }

    .logo-wrapper {
      height: 40px;
    }

    .footer {
      > div {
        width: 1190px;
      }
    }
  }

  .selecte_button {
    width: 180px;
    height: 44.27px;
    border: 1px solid #00bfff;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    box-shadow: 0 1px 6px #00000029;
    border-radius: 5px;
  }

  .swiper-pagination-horizontal {
    bottom: 0 !important;
    width: auto !important;
  }

  .swiper-custom-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    > div:first-child {
      margin-right: 10px;
    }

    > div:last-child {
      margin-left: 10px;
    }
  }

  .swiper-pagination-bullets {
    line-height: 2.2;

    > div:first-child {
      // margin-right: 10px;
    }

    > div:last-child {
      // margin-left: 0px;
    }
  }

  .swiper-pagination-bullet {
    background: none;
    font-size: 12px;
    text-align: center;
    line-height: 2;
  }

  .swiper-pagination-bullet-active {
    width: 24px;
    height: 24px;
    background: #e6e6e6;
  }

  .swiper-navigation {
    color: #888888;
    cursor: pointer;
  }

  .error {
    &__container {
      border: 1px solid #ffffff;
      width: 500px;
      height: 445.5px;
      border-radius: 10px;
      background: #ffffff;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
      // padding-bottom: 31px;

      &__header {
        display: flex;
        justify-content: space-between;
        padding: 29.25px 31px 20.25px 28px;

        > div:first-child {
          font-size: 18px;
          color: #333333;
        }
      }

      &__inquiry {
        padding: 33.25px 37.25px 30.75px 38.75px;
        font-size: 15px;
      }

      &__description {
        background: #f0f0f1;
        padding: 18px 31.25px 21px 27.75px;

        > div:first-child {
          margin-bottom: 10px;
        }

        > div:nth-child(2) {
          display: flex;

          > div {
            width: 100%;

            textarea {
              resize: none;
              width: 100%;
              height: 88px;
              text-align: left;
              padding: 20px;
              border: 1px solid #e6e6e6;
              border-radius: 5px;
            }
          }
        }
      }

      &__button {
        width: 452px;
        height: 47px;
        margin: auto auto;
        margin-top: 20px;
        line-height: 47px;
        border-radius: 47px;

        > p {
          font-size: 18px;
        }
      }

      // > div:last-child {
      //   margin: 20px auto;
      // }
    }
  }

  .wallet_select_modal {
    &__content {
      width: 418px;
      height: 272.5px;

      &__header {
        padding: 24.5px 23px 22.5px 25px;
      }

      &__wallet_btn {
        margin: 25px 24.5px 23px 22.5px;
        width: 373.75px;
        height: 55px;
        border-radius: 5px;
        padding: 15px 0 13px 36.5px;

        img {
          width: 28px;
          height: 27px;
          margin-right: 15px;
        }
      }
    }
  }

  .change_network_modal {
    &__content {
      width: 514px;
      height: 305px;

      &__header {
        font-family: SpoqaHanSansNeo-Bold;
        padding: 26.25px 23px 21px 25px;
      }

      &__wallet_btn {
        margin: 25px 24.5px 23px 22.5px;
        width: 373.75px;
        height: 55px;
        border-radius: 5px;
        padding: 15px 0 13px 36.5px;

        img {
          width: 28px;
          height: 27px;
          margin-right: 15px;
        }
      }

      &__guide {
        > p:first-child {
          font-family: SpoqaHanSansNeo-Medium;
          color: '#333333';
          width: 404px;
          height: 43px;
          margin: auto;
          text-align: center;
          margin-top: 38px;
          margin-bottom: 11px;
          font-size: 15px;
        }

        > div:last-child {
          font-family: SpoqaHanSansNeo;
          color: '#333333';
          width: 352px;
          height: 16px;
          margin: auto;
          text-align: center;
          margin-bottom: 30px;
          font-size: 13px;
        }
      }

      &__button {
        cursor: pointer;
        font-family: Montserrat-bold;
        width: 373.75px;
        height: 55px;
        font-size: 15px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        border: 1px solid #00bfff;
        margin-top: 19px;
      }
    }
  }

  .faq {
    // max-width: 1190px;
    margin-top: 240px;

    &__wrapper {
      max-width: 1190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      &__header {
        font-family: SpoqaHanSansNeo-Bold, Inter-Bold;
      }

      &__section {
        margin-top: 84px;

        > div {
          border: 1px solid #f0f0f1;
          border-radius: 5px;
          padding: 28px 35px 40px 35px;
          background-color: $background-color;
          margin-bottom: 20px;

          > div:first-child {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 28px;

            > h3 {
              margin: 0;
              font-size: 18px;

              > span {
                font-family: SpoqaHanSansNeo-Bold;
                font-weight: 900;
                color: $elysia-color;
              }
            }
          }

          > p {
            font-size: 15px;
            color: $sub-font-color;
            margin-bottom: 14px;
            line-height: 25px;

            > span {
              font-weight: 900;
            }

            > p {
              font-size: 15px;
              color: $sub-font-color;
            }
          }

          > div:last-child {
            background-color: #f8f8f8;
            border: 1px solid #f0f0f1;
            border-radius: 5px;
            padding: 18px 29px 22px 19px;

            > div:not(p:nth-child(4)) {
              margin-bottom: 10px;
            }

            > div:first-child {
              color: $sub-font-color;
              font-size: 15px;
            }

            > div:nth-child(2),
            div:nth-child(3),
            div:nth-child(4) {
              color: $sub-font-color;
              font-size: 15px;
              display: flex;

              > div {
                // width: 70px;
                color: $sub-font-color;
                font-weight: bold;
                margin-right: 5px;
              }

              > p {
                width: 85%;
              }
            }
          }
        }

        > .question_title_box {
          overflow: hidden;
          height: 86px;
        }

        > .box1_down {
          animation: q1-box-down 0.3s ease-in;
          height: auto;
        }

        > .box1_up {
          animation: q1-box-up 0.3s ease-in;
          height: 86px;
        }

        > .box2_down {
          animation: q2-box-down 0.3s ease-in;
          height: auto;
        }

        > .box2_up {
          animation: q2-box-up 0.3s ease-in;
          height: 86px;
        }

        > .box3_down {
          animation: q3-box-down 0.3s ease-in;
          height: auto;
        }

        > .box3_up {
          animation: q3-box-up 0.3s ease-in;
          height: 86px;
        }

        > .box4_down {
          animation: q4-box-down 0.3s ease-in;
          height: auto;
        }

        > .box4_up {
          animation: q4-box-up 0.3s ease-in;
          height: 86px;
        }

        > .box5_down {
          animation: q5-box-down 0.3s ease-in;
          height: auto;
        }

        > .box5_up {
          animation: q5-box-up 0.3s ease-in;
          height: 86px;
        }

        > .box6_down {
          animation: q6-box-down 0.3s ease-in;
          height: auto;
        }

        > .box6_up {
          animation: q6-box-up 0.3s ease-in;
          height: 86px;
        }

        > .box7_down {
          animation: q7-box-down 0.3s ease-in;
          height: auto;
        }

        > .box7_up {
          animation: q7-box-up 0.3s ease-in;
          height: 86px;
        }
      }
    }
  }

  .arrow {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    > div {
      width: 12.86px;
      border: 1px solid $elysia-color;
      position: absolute;
    }

    > div:first-child {
      left: 50%;
      top: 50%;
      transform: translate(-81%, -50%) rotate(45deg);
    }

    > div:last-child {
      left: 50%;
      top: 50%;
      transform: translate(-19%, -50%) rotate(-45deg);
    }
  }

  .arrow_up {
    animation: arrow-up 0.3s ease-in;
    transform: rotate(180deg);
  }

  .arrow_down {
    animation: arrow-down 0.3s ease-in;
    transform: rotate(0deg);
  }

  @keyframes arrow-up {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes arrow-down {
    0% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes q1-box-down {
    0% {
      height: 86px;
    }

    100% {
      height: 394px;
    }
  }

  @keyframes q1-box-up {
    0% {
      height: 394px;
    }

    100% {
      height: 86px;
    }
  }

  @keyframes q2-box-down {
    0% {
      height: 86px;
    }

    100% {
      height: 281px;
    }
  }

  @keyframes q2-box-up {
    0% {
      height: 281px;
    }

    100% {
      height: 86px;
    }
  }

  @keyframes q3-box-down {
    0% {
      height: 86px;
    }

    100% {
      height: 320px;
    }
  }

  @keyframes q3-box-up {
    0% {
      height: 320px;
    }

    100% {
      height: 86px;
    }
  }

  @keyframes q4-box-down {
    0% {
      height: 86px;
    }

    100% {
      height: 331px;
    }
  }

  @keyframes q4-box-up {
    0% {
      height: 331px;
    }

    100% {
      height: 86px;
    }
  }

  @keyframes q5-box-down {
    0% {
      height: 86px;
    }

    100% {
      height: 192px;
    }
  }

  @keyframes q5-box-up {
    0% {
      height: 192px;
    }

    100% {
      height: 86px;
    }
  }

  @keyframes q6-box-down {
    0% {
      height: 86px;
    }

    100% {
      height: 167px;
    }
  }

  @keyframes q6-box-up {
    0% {
      height: 167px;
    }

    100% {
      height: 86px;
    }
  }

  @keyframes q7-box-down {
    0% {
      height: 86px;
    }

    100% {
      height: 370px;
    }
  }

  @keyframes q7-box-up {
    0% {
      height: 370px;
    }

    100% {
      height: 86px;
    }
  }

  .market_modal {
    &__wrapper {
      width: 512px;
    }

    &__header {
      > h3 {
        font-size: 18px;
      }

      > div {
        width: 24px;
        height: 24px;
      }

      > div:active {
        > div {
          border-color: $font-color;
          background-color: $font-color;
        }
      }
    }

    &__section {
      font-size: 15px;
      padding-top: 56px;
      padding-bottom: 53.25px;
      line-height: 25px;
    }

    &__button {
      > button {
        width: 470px;
        height: 48px;

        > p {
          font-size: 15px;
        }
      }
    }

    &__step {
      height: 61px;

      > div {
        font-size: 15px;
      }

      > div:nth-child(1) {
        padding-left: 23px;

        > div:last-child {
          top: -1px;
          border-top: 32px solid transparent;
          border-bottom: 32px solid transparent;
        }
      }

      > div:nth-child(2) {
        padding-right: 23px;
      }
    }

    &__input {
      height: 145px;
      padding: 0 30.25px;

      > section {
        > div {
          font-size: 18px;
        }

        > input {
          height: 65px;
          font-size: 50px;
        }
      }

      > p {
        font-size: 12px;
      }
    }

    &__crypto {
      img {
        width: 28px;
        height: 28px;
      }

      > p {
        font-size: 15px;
      }

      > div {
        height: 48px;
        font-size: 15px;

        > ul {
          top: 45px;

          > li {
            font-size: 15px;
            height: 46px;

            > img {
              margin-right: 10.25px;
            }
          }
        }
      }
    }

    &__amount {
      font-size: 15px;
    }

    &__purchase_btn {
      > button {
        height: 47px;
        font-size: 18px;
      }
    }

    &__confirm {
      > h3 {
        font-size: 15px;
      }

      > div:nth-child(2),
      div:nth-child(3) {
        font-size: 15px;
      }

      > div:last-child {
        height: 216px;

        > ul {
          font-size: 13px;

          > li:first-child > span {
            font-size: 10px;
          }
        }
      }
    }

    &__twitter {
      width: 470px;
      margin: 0 auto;
      text-align: center;

      > figure {
        > video {
          width: 250px;
        }

        > figcaption {
          font-size: 20px;
        }

        margin-bottom: 30px;
      }

      > section {
        height: 103px;
        background-color: #f6f6f8;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding: 0 100px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 10px;

          > b {
            font-size: 20px;
            color: #333333;
          }

          > p {
            font-size: 13px;
            color: #707070;
          }
        }

        > b {
          font-size: 20px;
          color: #333333;
        }
      }
    }

    &__visible {
      position: absolute;
      bottom: -30px;
      display: flex;
      align-items: center;

      > div {
        width: 16px;
        height: 16px;
        border: 1px solid #ffffff;
        border-radius: 2px;
        margin-right: 9px;
        cursor: pointer;

        &:hover {
          background-color: #ffffff33;
        }

        &:active {
          background-color: #ffffff88;
        }
      }

      > b {
        font-size: 15px;
        color: #ffffff;
      }
    }

    &__token-reward {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0 30px;

      > b {
        font-size: 20px;
        line-height: 28px;
      }

      > video {
        width: 250px;
      }

      > section {
        width: 450px;
        margin: 10px auto 0;
        height: 120px;
        border-radius: 10px;
        background-color: #f6f6f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > p {
          font-size: 13px;
          color: #333333;

          &:first-child {
            color: #707070;
          }
        }

        > b {
          font-size: 20px;
          margin-bottom: 12.5px;
        }
      }
    }

    &__approve {
      > h5 {
        font-size: 18px;
        padding-bottom: 40px;
      }

      > div {
        border: 1px solid #e6e6e6;
        padding: 32px 24px;

        > p {
          font-size: 15px;
        }
      }

      > p {
        font-size: 12px;
      }
    }
  }
}
