$device-width: 100%;
$footer-height: 100px;

.view-t {
  min-width: #{$device-width};
  min-height: calc(100vh - #{$footer-height});

  .navigation {
    &__wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;

      > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        margin-left: 20px;
      }

      > a {
        display: flex;
      }
    }

    &__status {
      width: 24px;
      height: 24px;
    }

    &__container {
      max-width: $device-width;
      padding: 8px 10px;
      height: 110px;
      flex-direction: column;
    }

    &__link {
      font-size: 15px;

      &__container {
        width: 95%;
        height: 30px;

        > a {
          display: flex;
        }
      }

      &__wrapper {
        margin: 0 10px;
      }
    }

    &__sub-menu {
      border-radius: 15px;
      top: 140px;
      left: -45px;
      right: -45px;

      &__item {
        padding: 20px;
      }

      :first-child {
        border-radius: 15px 15px 0 0;
      }

      :last-child {
        border-radius: 0 0 15px 15px;
      }
    }

    &__wallet {
      width: 177px;
      height: 37px;
      border-radius: 5px;

      &__container {
        &.pc-only {
          display: none;
        }

        margin-right: 18px;
      }

      &--connected {
        margin: 0;
        // transform: scale(0.8);
      }

      &__status {
        &--connected {
          margin: 0;
          margin-right: 12px;
          font-size: 15px;
        }
      }

      &__mainnet {
        font-size: 15px;
        margin-right: 12px;
      }

      &__sub-menu {
        margin-top: 40px;
        border-radius: 5px;
        top: 40px;
        width: 445px;
        // left: 0px;
        right: 0;
        box-shadow: 0 0 6px #00000088;

        > hr {
          width: 100%;
          height: 1px;
        }

        &__item {
          padding: 10px 10px;
          font-size: 15px;
          text-align: right;
        }

        :first-child {
          border-radius: 5px 5px 0 0;
        }

        :last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }

  .logo-wrapper {
    width: 130px;
  }

  .elysia-logo {
    width: 120px;
    height: 30px;
  }

  .header {
    height: 420px;

    &__title {
      font-size: 30px;

      &__wrapper {
        width: 100%;
        bottom: 42px;
      }
    }
  }

  .deposit {
    background-color: #ffffff;
    position: relative;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;

    &__title {
      &-wrapper {
        width: 360px;
        bottom: 42px;
      }

      &-text {
        font-size: 40px;
      }
    }

    &--disable {
      width: 100%;
      height: calc(100vh - #{$footer-height});

      .deposit {
        &__content {
          font-size: 20px;
          line-height: 23px;
          margin: 10px;
        }

        &__content--bold {
          font-size: 40px;
          line-height: 50px;
          margin: 0 0 10px 0;
        }
      }
    }
  }

  .tokens {
    width: 90%;
    padding-bottom: 100px;

    .pc-only {
      display: none;
    }

    &__container {
      margin-top: 85px;
    }

    &__elfi {
      .tokens {
        &__table {
          &__content {
            flex-direction: column;

            &__data {
              display: flex;
              justify-content: space-between;
              width: 100%;
              box-sizing: border-box;
              padding: 10px 5px;

              > p:first-child {
                font-size: 15px;
              }

              > p:last-child {
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    &__table {
      &__minted {
        > p {
          margin: 7px;
          text-align: center;
        }

        > .content {
          box-shadow: 0 0 6px #00000029;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding: 10px 10px 10px 5px;
          justify-content: space-between;
          margin-bottom: 100px;

          > div {
            &:first-child {
              display: flex;
              align-items: center;

              > p {
                margin: 0 0 0 5px;
              }

              > img {
                width: 30px;
                height: 30px;
              }
            }

            &:last-child {
              display: flex;
              flex-direction: column;
              text-align: right;

              > p {
                margin: 0;
                font-size: 16px;

                &:last-child {
                  margin-top: 5px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      &__image {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 10px 5px;
        border-bottom: 1px solid #00000029;

        &--disable {
          width: 30px;
          height: 30px;
          border-radius: 30px;
        }

        > div {
          > img {
            width: 30px;
            height: 30px;
          }

          > p {
            margin: 0 0 0 5px;
            font-size: 18px;
          }
        }
      }

      &__apr {
        width: 100px;
        height: 18px;
        margin: 10px auto 0;

        > img {
          width: 12px;
          height: 12px;
        }

        > p {
          font-size: 12px !important;
          line-height: 12px;

          &::after {
            margin-left: 1px;
            font-size: 12px;
          }
        }
      }

      &__row {
        width: 100%;
        border-radius: 5px;

        &--disable {
          border-radius: 5px;
        }
      }

      /* mobile only */

      &__content {
        display: flex;
        padding: 10px 5px;
        justify-content: space-between;

        &__data {
          width: 110px;

          > p {
            margin: 0;
            font-size: 16px;
            text-align: center;

            &:first-child {
              color: #888888;
              font-size: 14px;
              margin: 0 0 6px;
              text-align: center;
            }

            &:last-child {
              font-size: 12px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .text {
    &__title {
      margin-top: 80px;
      margin-bottom: 35px;

      > p {
        margin: 0 20px 0 0;
        font-size: 20px;
      }
    }
  }

  .staking {
    width: 90%;
    margin: 100px auto;

    .pc-only {
      display: none;
    }

    &__sign {
      font-size: 20px !important;
    }

    &__round {
      margin-top: 50px;

      &__border {
        width: 27px;
        margin-top: 61px;
      }

      &__container {
        flex: 1;
        width: 100%;
      }

      &__remaining-data {
        margin: 45px 0;
        flex-direction: column;

        &__title {
          padding: 25px 13px;

          > div:first-child {
            display: flex;
            margin-bottom: 15px;
            justify-content: space-evenly;
            align-items: center;
            width: 60%;

            > p:first-child {
              font-size: 17px;
              margin: 0;
            }

            > p:last-child {
              font-size: 12px;
              margin: 0;
            }
          }

          > div:last-child {
            width: 60%;

            > p:first-child {
              font-size: 12px;
              margin: 0;
            }

            > p:last-child {
              font-size: 17px;

              .staking__sign {
                font-size: 17px !important;
              }
            }
          }
        }

        &__body {
          > div {
            > p {
              font-size: 17px;
              margin: 17px 10px 7px;
            }

            > div {
              > h2 {
                font-size: 30px;
                margin-right: 22px;

                .colored {
                  font-size: 30px;
                }

                > span {
                  font-size: 25px;
                }
              }

              .disable {
                width: 200px;
                height: 30px;
                margin: 0 auto 30px;

                > p {
                  font-size: 15px;
                  line-height: 30px;
                }
              }

              > div {
                width: 200px;
                height: 30px;
                margin: 0 auto 30px;

                > p {
                  font-size: 15px;
                  line-height: 30px;
                }
              }
            }

            &:first-child {
              margin-left: 20px;
            }

            &:last-child {
              margin-right: 20px;
            }
          }
        }
      }

      &__previous {
        &__wrapper {
          width: 100%;
          margin: 45px 0 45px auto;
          padding: 4px 45px 0 16px;
        }

        height: 130px;
        padding: 22px 0;
        flex-direction: column;

        > div {
          width: 100%;
        }

        &__title {
          > p {
            &:first-child {
              font-size: 17px;
              margin: 8px;
            }

            &:last-child {
              font-size: 12px;
              margin: 7px;
            }
          }
        }

        &__body {
          margin: 0 20px;

          > div {
            > p {
              margin: 0;

              &:first-child {
                font-size: 17px;
              }

              &:last-child {
                font-size: 20px;
              }
            }
          }
        }

        &__button {
          height: 100%;
          flex-direction: row;
          margin-top: 20px;
          justify-content: space-between;

          > div {
            width: 35%;
            height: 30px;
            line-height: 30px;
            padding: 0 20px;
            cursor: pointer;

            > p {
              font-size: 15px;
            }
          }
        }
      }

      &__current-data {
        padding: 18px 36px;

        > div {
          > div {
            > p {
              font-size: 17px;
            }
          }

          &:last-child {
            > p:last-child {
              font-size: 30px;
            }
          }
        }
      }
    }

    .modal {
      &__staking {
        padding: 29px 26px;

        &__button {
          padding: 23.75px 27.5px;

          &:nth-child(2) {
            margin-bottom: 36px;
            margin-top: 58px;
          }

          &__arrow {
            font-size: 25px !important;
          }

          > div {
            > p {
              font-size: 15px;
            }

            > h2 {
              font-size: 20px;
            }
          }
        }
      }
    }

    &__progress-bar {
      width: 100%;

      &__value {
        width: 100% !important; // temp
      }

      &__wrapper {
        padding: 10px 10px 50px;
      }

      &__content {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 15px;
        font-size: 15px;
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;

        > div {
          text-align: right;
        }
      }

      &__button {
        width: 20px;
        height: 20px;
        border-radius: 20px;

        > div {
          width: 200px;
          left: -90px;
          top: 57px;

          > p {
            font-size: 12px;

            &:last-child {
              position: absolute;
              width: 300px;
              left: -50px;
            }
          }
        }

        &__wrapper {
          top: -10px;
        }

        &.now {
          &::after {
            height: 32px;
            left: 9px;
            top: 19px;
          }
        }
      }
    }

    &__button {
      width: 100%;
      height: 40px;
      margin: 16px auto 0;

      > p {
        line-height: 40px;
        font-size: 17px;
      }
    }

    &__value {
      &__reward {
        height: 140px;
      }
    }

    &__content {
      > p {
        margin: 12px 0 0;
        font-size: 12px;
      }
    }

    &__infomation {
      width: 100%;
      margin-top: 75px;
      flex-direction: column;

      > :nth-child(2) {
        display: block;
        flex: none !important;
        width: 95px;
        height: 105px;
        transform: rotate(90deg);

        > img {
          width: 100% !important;
          height: 250px !important;
          bottom: 0 !important;
          margin: 0 auto;
          top: -75px;
        }
      }
    }

    &__container {
      border-radius: 10px;
      width: 100%;
      padding: 16px;

      &__header {
        padding-bottom: 6.5px;
      }

      > div {
        > h2 {
          font-size: 15px;

          > span {
            font-size: 25px;
          }

          .colored {
            font-size: 29px;
          }
        }
      }

      &__wrapper {
        width: 100%;
        padding: 10px;
        box-shadow: 0 0 6px #00000029;
        gap: 23px;
        box-sizing: border-box;

        > div:first-child {
          height: 140px;
        }

        > div:last-child {
          height: 250px;
        }
      }
    }
  }

  .modal {
    padding: 2.5vh 2.5vw;
    // display: block !important;

    &__account {
      margin: 10px;

      &__mainnet-info {
        border-radius: 10px 10px 0 0;
        padding: 14px 20px;

        > div:first-child {
          > p {
            font-size: 15px;
          }

          > div {
            > p {
              font-size: 15px;
            }

            > div {
              width: 14px;
              height: 14px;
              border-radius: 14px;
              margin-right: 6px;
            }
          }
        }

        > div:last-child {
          > p {
            margin: 7px 29px;
            font-size: 15px;
          }
        }

        &__button {
          > div {
            > p {
              font-size: 15px;
            }
          }

          > a {
            > div {
              > p {
                font-size: 15px;
              }
            }
          }
        }
      }

      &__status {
        margin-top: 25px;
        margin-bottom: 10px;

        > a {
          > div {
            margin-top: 10px;
            padding: 10px 20px;

            > div {
              display: flex;
              align-items: center;

              > p {
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    &__text-input {
      font-size: 50px !important;
    }

    .arrow-wrapper {
      height: 55px;
      margin: 0;
      // transform: scale(0.7);
      position: relative;
      top: 10px;
    }

    &__container {
      width: 480px;
      // height: 95vh;
      border-radius: 0;
      margin: auto;
      min-width: 360px;
      overflow-y: initial !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__header {
      padding: 10px;

      &__image {
        width: 36px;
        height: 36px;
      }

      &__name {
        font-size: 20px;
      }

      &__token-info-wrapper {
        gap: 10px;
      }
    }

    &__converter {
      &__column {
        > p {
          margin: 10px;
          font-size: 16px;
        }

        &--selected {
          > p {
            margin: 10px;
            font-size: 16px;
          }
        }
      }
    }

    &__migration {
      &__alert {
        > p {
          &:first-child {
            margin-top: 50px;
            font-size: 17px;
          }
        }

        > div {
          padding: 21px 30px;
          margin: 20px 0;
        }
      }

      &__wrapper {
        margin: 20px 18px 31px;

        > div {
          > p {
            font-size: 15px;
          }
        }
      }

      > div {
        > div {
          &:nth-child(3) {
            top: -30px;
          }
        }
      }

      &__content {
        gap: 5px;
        font-size: 12px;
      }

      &__input {
        height: 95px;
        border-radius: 6px;
        padding: 0 25px;

        > p {
          > input {
            font-size: 40px !important;
            width: 90%;
            height: 90%;
          }
        }
      }

      &__popup {
        border-radius: 8px;
        padding: 20px 15px;
        font-size: 15px;
        top: 60px;

        &__hover {
          border-radius: 20px;
          width: 20px;
          height: 20px;
          line-height: 20px;
        }

        &__info {
          gap: 5px;
        }
      }
    }

    &__button {
      width: 90%;
      height: 45px;
      border-radius: 5px;
      line-height: 45px;
      margin: 20px auto;

      > p {
        font-size: 18px;
      }
    }

    &__button--disable {
      width: 90%;
      height: 45px;
      border-radius: 5px;
      margin: 20px auto;
      line-height: 45px;

      > p {
        font-size: 18px;
      }
    }

    &__withdraw,
    &__deposit,
    &__migration {
      // position: absolute;
      overflow-y: auto;
      // height: calc(95vh - 45px - 32px - 85px);
      max-height: calc(95vh - 45px - 32px - 85px);
    }

    &__body {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
    }

    &__withdraw__value-wrapper,
    &__deposit__value-wrapper,
    &__value-wrapper {
      height: 140px;
      // height: 500px;
      padding: 0 20px;
    }

    &__input {
      &__maximum {
        font-size: 20px;
      }

      &__value {
        margin: 0 0 0 20px;
        font-size: 60px;

        &-converter {
          font-size: 15px;

          &-wrapper {
            padding: 15px 26px;
          }
        }
      }
    }

    &--deposit {
      .modal {
        &__header {
          &__type {
            gap: 10px;
          }
        }

        &__withdraw,
        &__deposit {
          &__maximum {
            font-size: 15px;
          }

          &__value {
            margin: 0 0 0 20px;
            font-size: 60px;

            &-converter {
              font-size: 15px;

              &-wrapper {
                padding: 15px 26px;
              }
            }
          }
        }

        &__withdraw {
          &__withdrawalable {
            border-bottom: 2px solid #f0f0f1;

            &__title {
              > p {
                font-size: 12px;
                margin: 0 0 18px 0 !important;
              }
            }

            &-amount-wrapper {
              margin: 20px 18px 0;
              padding-bottom: 20px;

              > div {
                > p {
                  margin: 10px 0;
                  font-size: 15px;
                }
              }
            }

            &-value-wrapper {
              margin: 20px 18px 31px;

              > div {
                > p {
                  margin: 10px 0;
                  font-size: 14px;

                  &:first-child {
                    width: 200px;
                  }

                  &:last-child {
                    width: 110px;
                  }
                }
              }
            }
          }
        }

        &__deposit {
          &__container {
            border-bottom: 2px solid #f0f0f1;
          }

          &__despositable {
            &-amount {
              &-container {
                margin: 20px 18px 0;
                padding-bottom: 20px;

                > p {
                  margin: 0 0 18px 0;
                  font-size: 12px;
                }
              }

              &-wrapper {
                > p {
                  font-size: 15px;
                  margin: 10px 0;
                }

                > div {
                  > p {
                    font-size: 15px;
                    margin: 10px 0;
                  }
                }
              }
            }

            &-value {
              &-wrapper {
                margin: 20px 18px 31px;

                > p {
                  margin: 0 0 16px;
                  font-size: 15px;
                }

                > div {
                  > p {
                    margin: 10px 0;
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &--minted {
      .modal {
        &__body {
          height: 173px;
          padding: 0 40px;

          &__maximum {
            font-size: 20px;
          }

          &__value {
            font-size: 60px;
          }
        }

        &__footer {
          &__text {
            margin: 28px;
          }

          &__amount-wrapper {
            margin: 0 28px 28px;
          }
        }
      }
    }

    &__staking {
      &__container {
        margin: 20px 18px 31px;

        > p {
          margin: 0 0 16px;
          font-size: 15px;
        }

        > div {
          > p {
            margin: 15px 0;
            font-size: 15px;
          }
        }
      }
    }
  }

  .footer {
    padding-top: 25px;
    padding-bottom: 25px;

    &__content {
      font-size: 15px;
      top: 14px;
    }

    &__container {
      width: 90%;
    }

    &__white-logo {
      width: 100px;
      height: 25.667px;
    }

    &__wrapper {
      height: 50px;
    }

    &__logo-wrapper {
      width: 120px;
      height: 26px;
    }
  }

  .lptoken_reward_Modal_body {
    border-top: 1px solid #f0f0f1;
    border-bottom: 1px solid #f0f0f1;
    padding: 27px 21.5px 30px 30.5px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }

    > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      > div {
        display: flex;
        align-items: center;
        font-size: 17px;
      }
    }

    .lp_reward_dai {
      display: flex;
      align-items: center;
      color: #fab01d;
      font-size: 30px;

      > div {
        font-size: 25px;
        color: #646464;
        display: inline-block;
        margin-left: 3px;
      }
    }

    .lp_reward_eth {
      display: flex;
      align-items: center;
      color: #627eea;
      font-size: 30px;

      > div {
        font-size: 25px;
        color: #646464;
        display: inline-block;
        margin-left: 3px;
      }
    }

    .lp_reward_elfi {
      display: flex;
      align-items: center;
      color: #00bfff;
      font-size: 30px;

      > div {
        font-size: 25px;
        color: #646464;
        display: inline-block;
        margin-left: 3px;
      }
    }
  }

  .staking_detail_box {
    display: flex;
    flex-direction: column;

    > div:nth-child(2) {
      display: none;
    }

    .lp_token_description {
      box-shadow: 0 0 6px #00000029;
      border-radius: 10px;
    }
  }

  .lp_token_date {
    text-align: right;
    margin-top: 15px;
    font-size: 12px;
    color: #646464;
  }

  .total_expected_reward {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    border: 1px solid #646464;
    border-radius: 10px;
    margin-top: 38px;
    font-size: 17px;

    > div:nth-child(1) {
      color: #888888;
    }

    > div:nth-child(3) {
      border-left: 1px solid #888888;
      height: 59px;
    }

    > div:nth-child(4) {
      color: #888888;
    }
  }

  .staked_lp_item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    > div:nth-child(1) {
      height: 106px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      flex-wrap: nowrap;
      box-shadow: 0 0 6px #00000029;
      border-radius: 5px;

      > div:nth-child(1) {
        width: 70px;
        font-size: 17px;
      }

      > div:nth-child(2) {
        width: 105px;
        font-size: 17px;
      }

      > div:nth-child(3) {
        width: 150px;
        font-size: 17px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: center;

      > div {
        width: 1px;
        border-top: 12px solid #333333;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        margin-top: 15px;
        margin-bottom: 11px;
      }
    }

    > div:nth-child(3) {
      padding: 20px 15px 19px 21px;
      box-shadow: 0 0 6px #00000029;
      border-radius: 5px;

      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 7px;

        > div:nth-child(1) {
          display: flex;
          align-items: center;
          font-size: 17px;
        }
      }

      > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div:nth-child(1) {
          display: flex;
          align-items: center;
          font-size: 17px;
        }
      }

      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }

      .staked_lp_item_reward {
        font-size: 20px;
      }

      .staked_lp_item_tokenType {
        display: inline-block;
        color: #646464;
      }
    }
  }

  .staked_lp_item_content_mobile {
    display: none;
  }

  .staked_lp_content {
    margin-bottom: 11px;

    > div:nth-child(1) {
      display: flex;
      justify-content: space-around;
      text-align: center;

      > span:nth-child(1) {
        width: 70px;
        font-size: 15px;
      }

      > span:nth-child(2) {
        width: 105px;
        font-size: 15px;
      }

      > span:nth-child(3) {
        width: 150px;
        font-size: 15px;
      }

      > div:nth-child(4) {
        width: 179px;
      }
    }

    .staked_lp_content_pc {
      display: none;
    }
  }

  .header_line {
    display: none;
  }

  .lp_button {
    width: 179px;
    height: 30px;
    box-shadow: 0 1px 6px #00000029;
    border: 1px solid #333333;
    background: #ffffff;
    font-size: 15px;
    cursor: pointer;
  }

  .lp_reward_content_wrapper {
    padding-top: 30px;
    padding-bottom: 21px;
    margin-top: 18px;
    box-shadow: 0 0 6px #00000029;
    border: 1px solid #e6e6e6;
    border-radius: 10px;

    .lp_reward_content {
      display: flex;
      flex-grow: 1;
      border-bottom: 1px solid #e6e6e6;

      .lp_token_wrapper {
        border-right: 1px solid #e6e6e6;
        flex-grow: 1;
        padding-right: 32.5px;

        > div:nth-child(1) {
          display: flex;
          align-items: center;
          padding-top: 12.5px;
        }

        > div:nth-child(2) {
          text-align: right;
          margin-top: 26px;
          margin-bottom: 35px;
          font-size: 30px;

          > div {
            display: inline-block;
            color: #646464;
          }
        }
      }

      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
        padding-left: 29px;
      }
    }
  }

  .lp_receive_token_wrapper {
    padding: 17px 25px 19px 29px;
    background: #f8f8f8;

    > div:nth-child(1) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div:nth-child(1) {
        font-size: 17px;
      }

      > div:nth-child(2) {
        > a {
          cursor: pointer;

          > button {
            width: 179px;
            height: 30px;
            background: #ffffff;
            box-shadow: 0 1px 6px #00000029;
            border: 1px solid #333333;
            border-radius: 3px;
            font-size: 15px;
            color: #333333;
            cursor: pointer;
          }
        }
      }
    }

    > div:nth-child(2) {
      font-size: 15px;
      color: #888888;
      margin-top: 21px;

      > span {
        color: #888888;
      }
    }
  }

  .selecte_button {
    width: 180px;
    height: 44.27px;
    border: 1px solid #00bfff;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    box-shadow: 0 1px 6px #00000029;
    border-radius: 5px;
  }
}
