@import 'palette', '_mixin';

$bold: 'Inter-Bold', 'Montserrat-bold';
$device-width: 320px;
$footer-height: 100px;
$navigation-margin-height: 100px;

@mixin page-container {
  width: $device-width;
  margin: 0 auto;
  padding-top: 100px;
}

.view-m {
  background: none;
  min-width: #{$device-width};
  min-height: calc(100vh - #{$footer-height});

  .pc-only {
    display: none;
  }

  .navigation {
    overflow-y: scroll;

    &__mainnet {
      &__current {
        width: 120px;
        height: 35px;
        border-radius: 5px;

        > img {
          width: 30px;
        }

        > h2 {
          font-size: 11px;
        }
      }

      &__change-network {
        &__wrapper {
          width: 120px;
          top: 29px;
          padding-top: 6px;
          padding-bottom: 2px;

          &.network-error {
            width: 185px;
          }
        }

        > h2 {
          font-size: 12px;
        }

        > img {
          width: 30px;
        }
      }
    }

    &__apy {
      display: flex;
      align-items: center;
      height: 32px;
      max-width: 1190px;
      margin: auto;
      overflow: hidden;

      > div {
        display: flex;

        > h3 {
          flex: none;
          font-family: Inter;
          margin: 0;
          font-size: 12px;
        }

        > p {
          flex: none;
          font-size: 11px;
        }

        > h3:nth-child(3),
        h3:nth-child(5),
        h3:nth-child(7) {
          padding-left: 13px;
        }

        > p:nth-child(2),
        p:nth-child(4),
        p:nth-child(6) {
          padding-right: 13px;
          border-right: 1px solid $font-color;
        }
      }
    }

    &__apy::after {
      content: '';
      position: absolute;
      border-bottom: 1px solid #efefef;
      height: 40px;
      left: 0;
      width: 100%;
    }

    .navigation__hamburger {
      &__content {
        .mobile-beta {
          > p {
            position: relative;

            &::after {
              content: 'B';
              position: absolute;
              top: 4px;
              right: -20px;
              width: 12px;
              height: 12px;
              color: $background-color;
              font-size: 9px;
              line-height: 12px;
              border-radius: 2px;
              background-color: #0087ff;
              text-align: center;
            }
          }
        }

        > div,
        a > div {
          // padding: 33px 0px 36px;
          height: 70px;
          width: $device-width;
          margin: 0 auto;
          border-bottom: 1px solid $gray-line;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 16px;
            color: $font-color;
          }
        }
      }

      &__lnb {
        > div {
          width: 9px;
          height: 9px;
          border-top: 1px solid $font-color;
          border-right: 1px solid $font-color;
          top: -3px;
          position: relative;
          transition: all 0.3s ease-in-out;
        }

        &__sub-navigation {
          &__container {
            width: 100% !important;
            margin: none !important;
            background: $gray-line;
            height: auto !important;
          }

          &__wrapper {
            margin: 0 auto;
            width: $device-width;
            padding: 13px 0;

            > div {
              padding: 15px 0;
            }
          }
        }
      }

      &__footer {
        width: $device-width;
        margin: 30px auto;
        display: flex;
        flex-direction: column;

        &__lang {
          display: flex;
          flex-direction: row;
          margin-bottom: 35px;

          > p {
            font-size: 16px;
            color: $gray-font-color;

            &.active {
              color: $font-color;
            }

            &:first-child {
              padding-right: 16px;
            }

            &:last-child {
              padding-left: 16px;
              border-left: 1px solid $gray-line;
            }
          }
        }
      }

      &__button {
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        i {
          border-radius: 2px;
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background: $font-color;
        }

        i:nth-child(1) {
          -webkit-animation: out-t 0.8s backwards;
          animation: out-t 0.8s backwards;
          -webkit-animation-direction: reverse;
          animation-direction: reverse;
        }

        i:nth-child(2) {
          margin: 5px 0;
          -webkit-animation: out-m 0.8s backwards;
          animation: out-m 0.8s backwards;
          -webkit-animation-direction: reverse;
          animation-direction: reverse;
        }

        i:nth-child(3) {
          -webkit-animation: out-btm 0.8s backwards;
          animation: out-btm 0.8s backwards;
          -webkit-animation-direction: reverse;
          animation-direction: reverse;
        }

        &.active {
          i:nth-child(1) {
            -webkit-animation: in-t 0.8s forwards;
            animation: in-t 0.8s forwards;
          }

          i:nth-child(2) {
            -webkit-animation: in-m 0.8s forwards;
            animation: in-m 0.8s forwards;
          }

          i:nth-child(3) {
            -webkit-animation: in-btm 0.8s forwards;
            animation: in-btm 0.8s forwards;
          }
        }
      }
    }

    &__wrapper {
      justify-content: space-between;
    }

    &__container {
      max-width: $device-width;
      height: 60px;
    }

    &__link {
      font-size: 12px;

      &__container {
        display: none;
      }
    }

    &__sub-menu {
      border-radius: 15px;
      top: 140px;
      left: -45px;
      right: -45px;

      &__item {
        padding: 20px;
      }

      :first-child {
        border-radius: 15px 15px 0 0;
      }

      :last-child {
        border-radius: 0 0 15px 15px;
      }
    }

    &__address {
      justify-content: space-around;
      width: 100%;
    }

    &__wallet {
      display: inline-flex;
      background: #00bfff 0% 0%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
      height: 50px;
      border-radius: 28px;
      margin: 0;

      &--connected {
        width: 100%;
        height: 54px;
        margin: 0;
        display: inline-flex;
        border-radius: 5px;
        opacity: 1;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        position: relative;
        cursor: pointer;
        border: 2px solid #e6e6e6;

        &:hover {
          border: 2px solid $elysia-color;
        }

        &.FAIL {
          box-shadow: 0 0 6px #ff1d1d81;
          border: 2px solid #ff1d1d;
        }

        &.PENDING {
          border: 2px solid #f5f5f5;
          color: #00bfff;
          box-shadow: inset 0 0 0 1px rgba(#00bfff, 0.5);

          &::before,
          &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            z-index: -1;
            margin: -1 * 0.05 * 100%;
            box-shadow: inset 0 0 0 2px;
            animation: clip-me 4s linear infinite;
          }

          &::before {
            animation-delay: 4s * -0.5;
          }

          // @keyframes clip-me {
          //   0%,
          //   100% {
          //     clip: rect(0px, 205px * 1.1, 2px, 0px);
          //   }
          //   25% {
          //     clip: rect(0px, 2px, 67px * 1.1, 0px);
          //   }
          //   50% {
          //     clip: rect(67px * 1.1 - 2px, 205px * 1.1, 67px * 1.1, 0px);
          //   }
          //   75% {
          //     clip: rect(0px, 205px * 1.1, 67px * 1.1, 205px * 1.1);
          //   }
          // }

          .loader {
            left: 15px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: relative;
            -webkit-animation: load5 1.1s infinite ease;
            animation: load5 1.1s infinite ease;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
          }
        }

        &.CONFIRM {
          box-shadow: 0 0 6px #23eb1b81;
          border: 2px solid #23eb1b;
        }

        &.unknown-chain {
          > h2 {
            font-size: 15px;
          }
        }
      }

      &__status {
        &--connected {
          margin: 0;
          margin-right: 12px;
          font-size: 15px;
        }
      }

      &__mainnet {
        font-size: 12px;
        margin-right: 12px;
      }

      &__sub-menu {
        margin-top: 32px;
        border-radius: 5px;
        top: 40px;
        width: 445px;
        // left: 0px;
        right: -120px;

        > hr {
          width: 100%;
          height: 1px;
        }

        &__item {
          padding: 10px 10px;
          font-size: 15px;
          text-align: center;
        }

        :first-child {
          border-radius: 5px 5px 0 0;
        }

        :last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }

    &__margin {
      margin-bottom: 40px;
    }
  }

  // navigation end

  .main {
    width: $device-width;
    margin: 0 auto;
    margin-top: 120px;

    &__popup {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999;
      background: rgba(0, 0, 0, 0.7);
      overflow: hidden;
      backdrop-filter: blur(4px);

      &__container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          > img {
            min-width: 270px;
            max-width: 360px;
            width: 90vw;
            max-height: 80vh;
            margin-top: 10px;
          }

          > div {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            gap: 5px;

            > p {
              color: #ffffff;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }

      &__button {
        border: 1px solid #ffffff;
        width: 12px;
        height: 12px;
        cursor: pointer;

        &:hover {
          background-color: #ffffff50;
        }
      }
    }

    &__image-wrapper {
      width: 310px;
      transform: scale(0.8);

      > img {
        width: 310px;
        object-fit: scale-down;
      }

      .dom {
        top: -50px;
        z-index: 1;
        animation: floating alternate 4s ease-in-out infinite;
      }

      .pit {
        top: 140px;
      }

      @keyframes floating {
        from {
          top: -15px;
        }

        to {
          top: 20px;
        }
      }
    }

    &__section {
      flex-direction: column;
      min-height: 80vh;
      justify-content: center;
    }

    &__title {
      flex-direction: column;
      margin-top: 80px;
      justify-content: flex-start;
      height: 100vh;
      min-height: 600px;

      &__text-container {
        margin-bottom: 0;
        text-align: center;

        > p {
          &:first-child {
            font-size: 23px;
            margin-bottom: 25px;

            strong {
              font-size: 23px;
              line-height: 20px;
            }
          }

          &:last-child {
            font-size: 11px;
            line-height: 18px;
          }
        }

        > h2 {
          font-size: 50px;
        }
      }

      &__button {
        flex-direction: column;
        width: 100%;

        > div {
          margin-bottom: 10px;

          > a {
            width: 100%;
            height: 50px;

            > p {
              font-size: 15px;
              line-height: 50px;
            }
          }
        }
      }
    }

    &__content {
      flex-direction: column;
      width: $device-width;

      > div {
        > h2 {
          margin-top: 20vh;
          font-size: 23px;
          margin-bottom: 15px;
        }

        &:last-child {
          > p {
            font-size: 23px;
            line-height: 32px;

            strong {
              font-size: 23px;
            }
          }
        }
      }

      &__details {
        margin-top: 25px;
        font-size: 12px !important;
        line-height: 20px !important;
        margin-bottom: 20px;
      }

      &__image-container {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--01 {
          &--01 {
            top: 100px;
            position: relative;

            $pit-height: 292px;

            > img {
              object-fit: scale-down;
            }

            .pit {
              width: $device-width;
              height: $pit-height;
              top: calc(#{$pit-height} / 2 * -1);
              position: absolute;
            }

            .bottom {
              width: $device-width;
              position: relative;
              z-index: 3;
            }
          }

          .token-low {
            width: $device-width;
            position: relative;
            z-index: 2;
            margin-bottom: -25px;
            animation-duration: 15s;
            animation-name: down-fade-mobile;
            animation-iteration-count: infinite;
          }

          .token-many {
            position: relative;
            z-index: 2;
            margin-top: -210px;
            animation-duration: 15s;
            animation-name: up-fade-mobile;
            animation-iteration-count: infinite;
            width: $device-width;
          }
        }

        &--02 {
          position: relative;

          .asset-image {
            width: $device-width;
          }

          .coin {
            position: absolute;
            top: 40px;
            left: 30px;
            animation-duration: 6s;
            animation-name: floating-image;
            animation-iteration-count: infinite;
            width: 120px;
          }

          .pit {
            position: absolute;
            bottom: -35px;
            left: -20px;
          }
        }

        &--03 {
          position: relative;

          .governance--image {
            width: $device-width;
          }

          > div {
            position: absolute;
            right: 0;
            top: -100px;

            .paper--o {
              left: 0;
              position: relative;
              z-index: 2;
              animation-delay: 1s;
              animation-duration: 6s;
              animation-name: floating-image;
              animation-iteration-count: infinite;
              width: 60px;
            }

            .paper--x {
              top: 20px;
              position: relative;
              z-index: 3;
              animation-duration: 6s;
              animation-name: floating-image;
              animation-iteration-count: infinite;
              width: 60px;
            }
          }

          .pit {
            position: absolute;
            right: 0;
            width: 130px;
          }
        }
      }

      @keyframes floating-image {
        0% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(40px);
        }
      }

      @keyframes down-fade-mobile {
        0% {
          transform: translateY(0);
          opacity: 0;
        }

        10% {
          transform: translateY(40px);
          opacity: 1;
        }

        30% {
          transform: translateY(362px);
          opacity: 1;
        }

        100% {
          transform: translateY(362px);
        }
      }

      @keyframes up-fade-mobile {
        0% {
          transform: translateY(80px);
          opacity: 1;
        }

        30% {
          transform: translateY(80px);
        }

        60% {
          transform: translateY(-200px);
        }

        70% {
          transform: translateY(-190px);
        }

        80% {
          transform: translateY(-200px);
        }

        90% {
          transform: translateY(-190px);
          opacity: 1;
        }

        100% {
          transform: translateY(-200px);
          opacity: 0;
        }
      }

      &__button {
        width: $device-width;
        height: 50px;
        position: relative;
        z-index: 4;

        > p {
          line-height: 50px;
          font-size: 15px;
        }
      }
    }

    &__advantages {
      margin-top: 140px;

      > h2 {
        font-size: 23px;
      }

      &__container {
        margin-top: 65px;
        display: flex;
        flex-direction: column;

        > div {
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;

          > img {
            width: 45px;
            height: 40px;
            object-fit: initial;
            margin-right: 17px;
          }

          > div {
            > h2 {
              font-size: 15px;
              margin-bottom: 10px;
            }

            > p {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__service {
      margin-top: 20vh;

      > h2 {
        font-size: 23px;
      }

      &__graph {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 0;
        transform: scale(0.85);

        .scroll-arrow {
          position: initial;
        }

        &--circle {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          text-align: center;
          width: 140px;
          height: 140px;
          border-radius: 100%;
          border: 1px solid $elysia-color;

          &.circle_01 {
            left: 10px;
            position: relative;
          }

          &.circle_02 {
            width: 160px;
            height: 160px;
            background: #f7fbff;
          }

          &.circle_03 {
            left: 10px;
            position: relative;
            background: #f7fbff;
          }

          &.circle_04 {
            width: 175px;
            height: 175px;
            position: relative;
            background: #f7fbff;

            > div {
              &:first-child {
                width: 11px;
                height: 11px;
                position: absolute;
                border: 1px solid $elysia-color;
                border-radius: 100%;
                right: 8px;
                top: 33px;
                background: #ffffff;
              }

              &:last-child {
                width: 7px;
                height: 7px;
                position: absolute;
                border: 1px solid #00bfff;
                border-radius: 100%;
                right: 4px;
                top: 27px;
                background: #ffffff;
              }
            }
          }

          > p {
            font-size: 12px;
            color: $font-color;
          }
        }

        &--section01 {
          display: flex;
          flex-direction: row;
          height: 100%;
          width: $device-width;
          justify-content: center;
          position: relative;

          &--01 {
            display: flex;
            flex-direction: column;

            > div {
              display: flex;
              flex-direction: row;

              > div {
                height: 39px;
              }
            }

            &--01,
            &--02 {
              > div {
                flex: 0.5;

                &:first-child,
                &:last-child {
                  flex: 2;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                > p {
                  font-size: 12px;
                }
              }
            }
          }

          &--02 {
            display: flex;
            flex-direction: row;
            align-items: center;

            &--01 {
              margin-left: 50px;
              position: absolute;

              > p {
                font-size: 12px;
              }
            }

            > div {
              &:first-child {
                position: absolute;
                top: 70px;

                > .temp {
                  right: -32px;
                  top: -5px;
                  position: relative;

                  > span {
                    margin-top: 4px;
                  }
                }
              }
            }
          }
        }

        &--section02 {
          display: flex;
          flex-direction: column;
          align-items: center;

          &--01 {
            display: flex;

            > div {
              flex: 0.5;

              &:first-child,
              &:last-child {
                flex: 2;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              > p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &__partners {
      margin: 0;
      height: auto;

      > div {
        width: 100%;
        margin-top: 20vh;

        > h2 {
          font-size: 21px;
        }

        &:first-child {
          > img {
            margin-top: 25px;
            width: 160px;
          }
        }

        > section {
          display: flex;
          margin-top: 25px;

          > img {
            width: 125px;
            height: 125px;
            object-fit: scale-down;
          }

          > img:first-child {
            margin-right: 20px;
          }
        }
      }

      > div:nth-child(2) {
        margin-top: 100px;

        > div {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 300px;

          > img {
            width: 125px;
            height: 52px;
            object-fit: scale-down;
          }
        }
      }

      &__lawfirm {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        width: 100%;
        grid-gap: 30px 30px;
        gap: 30px 30px;
        align-items: center;
        justify-items: center;
        margin-top: 25px;

        > img {
          width: 140px;
        }
      }
    }

    &__governance {
      align-items: flex-start;
      margin-top: 20vh;

      > h2 {
        font-size: 23px;
      }

      &__table {
        width: $device-width;
        margin-top: 50px;

        > hr {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      &__converter {
        gap: 7px;

        &__button {
          height: 50px;
          border-radius: 28px;

          > h2 {
            font-size: 13px;
            line-height: 50px;
          }
        }
      }

      &__header {
        padding: 16px 20px 12px;

        > p {
          font-size: 12px;
        }
      }

      &__body {
        margin: 0 20px;
        padding: 20px 0;

        > p {
          font-size: 12px;
        }
      }

      &__more {
        margin-top: 12px;

        > p {
          font-size: 12px;
          line-height: 50px;
        }
      }
    }

    &__dao {
      margin: 0;
      padding: 20px 10px;
      width: 100%;
      border: 1px solid #0099cc;
      border-radius: 15px;
      background-color: #f7fbff;

      > h2 {
        font-size: 20px !important;
      }

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        > div {
          margin: 10px;
          width: 150px;
          height: 150px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #0099cc;
          background-color: #ffffff;
          padding: 0;

          > p {
            text-align: center;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }

  // main end

  .deposit {
    @include page-container;

    &__title {
      height: 300px;
      margin-bottom: 40px;
      padding-top: 180px;
      margin-top: -230px;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      background-size: 100%;

      .react-loading-skeleton {
        margin-top: 12px;
      }

      > p {
        font-size: 18px;
      }

      > h2 {
        padding-top: 16px;
        font-size: 30px;
      }

      &__round {
        margin-top: 15px;
        margin-bottom: 70px;

        > div {
          width: 104px;
          height: 40px;
          border-radius: 20px;

          > p {
            line-height: 18px;
            font-size: 10px;
          }
        }
      }
    }

    &__table {
      margin-bottom: 45px;

      &__wrapper {
        margin-top: 15px;
      }

      &__header {
        width: 95%;
        margin: auto;
        padding: 15px 20px 15px 20px;

        &__token-info {
          padding: 10px 12px 0 0;
          margin-bottom: 15px;
          // min-width: 150px;

          > img {
            width: 40px;
            height: 40px;
          }

          > section {
            margin-left: 18px;

            > p:first-child {
              font-size: 13px;
              margin-bottom: 8px;
            }

            > p:last-child {
              font-size: 15px;
            }
          }
        }

        &__data-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          justify-items: flex-end;
          gap: 10px;

          > div {
            width: 120px;

            > p:first-child {
              display: flex;
              align-items: center;
              font-size: 13px;
              margin-bottom: 7px;

              > p {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 6px;
              }
            }

            > p:last-child {
              font-size: 14px;
            }
          }
        }
      }

      &__body {
        &__amount {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          &__button {
            border-radius: 15px;
            width: 130px;
            height: 26px;
            margin: 0 auto;
            margin-top: 15px;
            margin-bottom: 20px;

            > p {
              font-size: 12px !important;
            }
          }

          > div {
            .amounts {
              font-size: 12px;
            }

            > h2 {
              font-size: 12px;
              margin-bottom: 5px;

              > span {
                font-size: 12px;
              }
            }

            > p {
              font-size: 10px;
            }
          }

          &__container {
            flex-direction: column;
          }

          &__wrapper {
            padding: 12px 15px 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-bottom: 1px solid $gray-line;

            &.right {
              border: none;
            }

            > div {
              > h2 {
                font-size: 12px;
              }
            }
          }
        }

        &__event-box {
          margin-top: 15px;
          margin-bottom: 15px;

          &__content {
            border-bottom: 1px solid #f0f0f1;
            padding: 0 15px 12px 15px;

            > div:first-child {
              display: flex;
              justify-content: space-between;

              > .DAI {
                strong {
                  color: #f9ae1a;
                }

                font-size: 12px;
              }

              > .USDT {
                strong {
                  color: #26a17b;
                }

                font-size: 12px;
              }

              > div:nth-child(2) {
                text-align: right;

                > div {
                  font-size: 12px;
                  margin-bottom: 6px;

                  > span:last-child {
                    font-family: $bold;
                  }
                }

                > p {
                  font-size: 10px;
                  color: $gray-font-color;
                }
              }
            }

            .event-button {
              margin: auto;
              margin-top: 15px;
            }
          }
        }

        &__loan-list {
          padding: 12px 15px;

          .component__loan-list__container {
            grid-template-columns: repeat(2, 135px);

            > div {
              width: 135px;
            }
          }

          > div {
            > div {
              align-items: center;

              > h2 {
                font-size: 12px !important;
                margin: 0 !important;
              }

              > a {
                > div {
                  margin: 0 !important;
                  width: 130px !important;
                  height: 26px !important;

                  > p {
                    font-size: 12px !important;
                    color: $font-color !important;
                  }
                }
              }
            }
          }

          &__more-button {
            border-radius: 15px;
            width: 130px;
            height: 26px;
            cursor: pointer;
            margin: 0 auto 10px;
            border: 0;
            box-shadow: 0 0 6px #00000029;

            > a > p {
              font-size: 12px;
              line-height: 26px;
            }
          }
        }

        &__strategy {
          padding: 12px 15px 15px;

          > h2 {
            font-size: 12px;
            margin-bottom: 20px;
          }

          > div {
            width: 100%;
            height: 20px;

            > div {
              width: 100%;
              align-items: center;
              display: flex;
              justify-content: center;

              &:first-child {
                border-radius: 15px 0 0 15px;

                > p {
                  font-size: 10px;
                  line-height: 12px;
                }
              }

              &:last-child {
                border-radius: 0 15px 15px 0;

                > p {
                  font-size: 10px;
                  line-height: 12px;
                }
              }
            }
          }

          &__text-info {
            display: flex;
            align-items: center;
            height: auto !important;
            margin-top: 10px;
            gap: 5px;
            justify-content: flex-start !important;

            .last {
              background: #40afd5 !important;
            }

            > div {
              width: 10px !important;
              height: 10px;
              border-radius: 100% !important;
            }

            > p {
              font-size: 12px;
              color: $gray-font-color;
            }
          }
        }
      }
    }

    &__remote-control {
      display: none;
    }
  }

  // deposit end

  .reward {
    @include page-container;

    &__progress-bar {
      height: 13px;
      border-radius: 13px;
      margin-top: 5px;

      &__wrapper {
        margin-bottom: 12px;
      }

      &__content {
        height: 21px;
        top: 5px;

        &__fill {
          > p {
            font-size: 10px;
            margin-right: 3.5px;
          }
        }
      }
    }

    &__token {
      margin-top: 10px;

      &__image-container {
        > img {
          width: 41px;
          height: 41px;
        }
      }

      > img {
        width: 41px;
        height: 41px;
      }

      > h2 {
        margin-top: 9px;
        font-size: 18px;
      }

      &__elfi {
        width: 140px;
        height: 26px;
        border-radius: 15px;
        margin-top: 10px;
        margin-bottom: 54px;

        > p {
          font-size: 12px;
          line-height: 26px;
        }
      }

      &__container {
        margin-top: 90px;
      }

      &__header {
        padding: 15px;

        > img {
          width: 21px;
          height: 21px;
        }

        > h2 {
          font-size: 13px;
          margin-left: 6px;
        }
      }

      &__staking {
        flex-direction: column;
        padding: 15px;
        gap: 15px;

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          border: 0 !important;

          > p {
            font-size: 12px;
          }

          > div {
            > h2 {
              font-size: 12px;
            }

            > span {
              margin-left: 3px;
              font-size: 12px;
            }
          }
        }
      }

      &__data {
        flex-direction: column;

        > div {
          width: 100%;
          padding: 15px 15px 25px;
          border: none !important;

          &:first-child {
            border-bottom: 1px solid $gray-line !important;
          }
        }

        &__content {
          padding: 0 0 15px;
          gap: 15px;
          display: flex;
          flex-direction: column;

          > h2 {
            font-size: 12px;
          }

          > div {
            > p {
              font-size: 12px;
            }
          }
        }
      }

      @mixin arrow-transform($angle, $x: 0, $y: 0) {
        i:first-child {
          transform: translate($x, $y) rotate($angle);
        }

        i:last-child {
          transform: translate($x, -$y) rotate(-$angle);
        }
      }

      &__array-handler {
        display: none;
      }

      &__lp {
        &__container {
          margin-top: 70px;
        }

        &__title {
          padding: 15px;

          > img {
            width: 21px;
            height: 21px;

            &.last-token {
              margin-left: -5px;
              border-radius: 37px;
              margin-right: 6px;
            }
          }

          > h2 {
            font-size: 13px;
          }
        }

        &__content {
          > div {
            flex: 1;
            padding: 15px;

            > p {
              font-size: 12px;
            }

            > h2 {
              font-size: 12px;
            }
          }

          &--left {
            padding-right: 12px !important;
          }

          &--right {
            padding-left: 12px !important;
          }
        }
      }
    }

    &__token-deposit {
      margin-bottom: 35px;

      &__header {
        padding: 15px;
        flex-direction: column;

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 11px;

          > img {
            width: 21px;
            height: 21px;
            margin-right: 6px;
          }

          > h2 {
            font-size: 13px;
            color: $font-color;
          }
        }

        > p {
          font-size: 12px;
          line-height: 18px;
          color: $sub-font-color;
        }
      }

      &__apy {
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px;

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > p {
            font-size: 12px;
            color: $sub-font-color;
          }

          > h2 {
            font-size: 12px;
            color: $font-color;
          }
        }
      }

      &__data {
        flex-direction: column;

        > div {
          flex: 1;
          padding: 15px;
          width: 100%;

          &:first-child {
            border-bottom: 1px solid $gray-line;
          }
        }
      }
    }
  }

  // reward end

  .detail {
    @include page-container;

    padding-top: 80px;

    &__header {
      margin-bottom: 70px;

      > img {
        width: 42px;
        height: 42px;
        margin-bottom: 10px;
      }

      > h2 {
        font-size: 18px;
      }

      &__DAI {
        margin-top: 15px;
        margin-bottom: 30px;

        > div {
          width: 104px;
          height: 40px;
          border-radius: 20px;
          background-color: #ffffff;

          > p {
            line-height: 18px;
            font-size: 10px;
          }
        }
      }
    }

    &__data-wrapper {
      margin-bottom: 40px;
      background: #ffffff;

      &__title {
        padding: 14px 16px;

        > h2 {
          font-size: 13px;
        }
      }

      &__total {
        display: flex;
        flex-direction: column;
        padding: 12px 14px;
        gap: 12px;

        > div {
          display: flex;
          justify-content: space-between;
          width: 100%;

          &:last-child {
            border: none;
          }

          > h2 {
            font-size: 12px;
          }

          > p {
            font-size: 12px;
            color: $gray-font-color;
          }
        }
      }

      &__info {
        flex-direction: column;

        &__deposit {
          &__wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid $gray-line;

            > div {
              width: 100%;
              padding: 12px 14px;

              > p {
                font-size: 12px;
              }

              > h2 {
                font-size: 12px;
              }
            }
          }

          > div {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 6px;
          }

          > p {
            font-size: 12px;
          }

          &__utilization {
            padding: 12px 14px;
            width: 100%;

            > h2 {
              font-size: 12px;
            }
          }

          &__strategy {
            position: relative;
            width: calc(100% - 40px) !important;
            margin: 0 0 0 20px;
            padding: 10px 15px !important;
            border-radius: 5px;
            gap: 15px;

            > div {
              > p {
                font-size: 11px;
              }

              > div {
                gap: 5px;

                > div {
                  width: 14px;
                  height: 14px;

                  > p {
                    font-size: 10px;
                  }
                }

                > p {
                  font-size: 11px;
                }
              }
            }

            &__defi-wrapper {
              width: calc(100% - 15px) !important;
              bottom: -40px;
              left: 15px;
              padding: 15px 20px;

              > div {
                > img {
                  width: 19px;
                  height: 19px;
                  margin-right: 8px;
                }

                > p {
                  font-size: 15px;
                }
              }
            }
          }
        }

        &__circle-wrapper {
          justify-content: center !important;
        }

        &__circle--moblie {
          width: 100%;
          height: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > div {
            &.main-color {
              width: 100%;
              align-items: center;
              display: flex;
              justify-content: center;
              border-radius: 15px 0 0 15px;
            }

            &.sub-color {
              width: 100%;
              align-items: center;
              display: flex;
              justify-content: center;
              border-radius: 0 15px 15px 0;
            }

            > p {
              font-size: 10px;
              line-height: 12px;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    &__graph {
      &__wrapper {
        &__tooltip {
          width: 226px;
          height: 70px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px 16px 12px 21px;
          border-radius: 5px;

          &__payload {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 7px;

            > div:first-child {
              font-family: Inter;
              font-size: 12px;
              color: $gray-font-color;
            }

            > div:last-child {
              font-size: 15px;
              color: $font-color;
            }
          }
        }
      }

      &__converter {
        &__button {
          width: 75px;
          height: 30px;

          > h2 {
            font-size: 13px;
          }
        }
      }
    }
  }

  // detail end

  .market {
    position: relative;
    width: 320px;
    margin: 0 auto;
    padding-top: 100px;

    &__guide {
      width: 320px;
      margin: 0 auto;
      padding-top: 150px;

      &__header {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 150px;

        > h1 {
          font-size: 18px;
          margin-bottom: 10px;
        }

        > p {
          font-size: 12px;
        }

        > span {
          font-size: 10px;
          color: $gray-font-color;
        }
      }
    }

    &__header {
      text-align: center;
      margin-top: 30px;

      > h1 {
        font-size: 18px;
        margin-bottom: 5px;
      }

      > div {
        width: 54px;
        height: 18px;
        margin: 0 auto 7px;

        > p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    &__content {
      > h2 {
        font-size: 11px;
        padding: 0 5px 17px 5px;
        margin-bottom: 20px;
      }

      > article {
        display: flex;
        flex-direction: column;

        > a {
          &.disable {
            position: relative;
            z-index: 1;
            pointer-events: none;
            cursor: not-allowed;

            &::after {
              content: '';
              width: 300px;
              height: 300px;
              background: #00000013;
              border-radius: 5px;
            }
          }

          > section {
            width: 300px;
            height: 300px;
            margin: 0 auto;

            > figure {
              > img {
                width: 280px;
                height: 200px;
                object-fit: cover;
              }

              > figcaption {
                width: 100px;
                height: 25px;
                font-size: 12px;
              }
            }

            > div {
              margin-top: 10px;

              > p {
                font-size: 10px;
              }

              > b {
                margin-top: 5px;
                font-size: 11px;

                > span {
                  margin-left: 3px;
                  font-size: 15px;

                  &::after {
                    content: '%';
                  }
                }
              }
            }

            > progress {
              width: 100%;
              margin-top: 12px;
              height: 8px;
              border: 0;
              vertical-align: 0;

              &::-webkit-progress-bar {
                height: 8px;
                border-radius: 8px;
                background: #e6e6e6;
              }

              &::-webkit-progress-value {
                border-radius: 8px;
                height: 8px;
              }

              &::-moz-progress-bar {
                height: 8px;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
  }

  // market end

  .nft-details {
    width: 320px;
    margin: 0 auto;
    padding-top: 130px;

    &__guide {
      font-size: 10px;
      text-align: right;
      margin-bottom: 8px;
      width: 100%;
    }

    &__header {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        margin-bottom: 20px;

        > h1 {
          text-align: center;
          font-size: 18px;
          margin-bottom: 20px;
        }

        > p {
          text-align: center;
          font-size: 12px;
        }
      }

      > section {
        width: 320px;
        height: initial;
        box-shadow: 0 0 6px #00000029;
        border-radius: 5px;
        padding: 12px 15px 0;

        > div {
          flex-direction: row;
          margin-bottom: 30px;

          > b {
            text-align: left;
            font-size: 12px;

            > span {
              margin-left: 5px;
              cursor: pointer;
            }
          }

          > span {
            font-size: 12px;
            color: $font-color;
            text-align: right;
            display: block;

            &.null {
              color: #888888;
            }
          }
        }

        > section {
          gap: 10px;

          > a {
            width: 130px;
            height: 26px;
            line-height: 26px;
            font-size: 10px;
          }

          > button {
            display: block;
            width: 130px;
            border-radius: 15px;
            height: 26px;
            margin: 0 auto 20px;
            background: #00bfff;
            cursor: pointer;
            font-size: 10px;
            color: #ffffff;
            border: 0;
          }
        }
      }
    }

    &__content {
      margin-top: 50px;
      padding: 12px 15px;
    }

    &__current-nfts {
      > article {
        flex-direction: column;
        background-color: initial;
        box-shadow: none;
        margin-top: 10px;
        border-radius: 0;
        width: 100%;

        > section {
          background-color: #ffffff;
          box-shadow: 0 0 6px #00000029;
          margin-top: 10px;
          border-radius: 10px;

          &:last-child {
            border-left: none;
            margin-top: 20px;
          }

          > div {
            font-size: 12px;
            padding: 12px 15px;

            > b {
              font-size: 12px;

              > span {
                margin-left: 5px;
              }
            }
          }

          > section {
            height: initial;

            > p {
              margin-top: 22px;
              font-size: 16px;
              padding-right: 15px;
            }

            > b {
              margin-top: 22px;
              font-size: 18px;
              padding-right: 15px;
            }

            > section {
              display: flex;
              flex-direction: column;
              margin: 25px 15px 15px;
              gap: 10px;

              > a {
                width: 100%;
                height: 26px;
                font-size: 12px;
                line-height: 26px;
              }

              > button {
                width: 100%;
                height: 26px;
                font-size: 12px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }

    &__purchase {
      margin-top: 20px;
      flex-direction: column;
      align-items: center;

      &__header {
        padding: 12px 15px;

        > h2 {
          font-size: 12px;
        }

        span.claim-completed {
          right: 14px;
          font-size: 6px;
        }
      }

      div.nft-details__purchase__amount {
        font-size: 12px;
        padding: 12px 15px;
      }

      &__claims-section {
        padding: 0 15px;
        display: flex;
        flex-direction: column;

        div.nft-details__purchase__claim-details {
          margin-top: 10px;

          table {
            font-size: 8px;

            th {
              font-size: 12px;
            }

            td {
              font-size: 10px;

              span {
                font-size: 10px;
              }
            }

            th:last-child,
            td:last-child {
              padding-right: 10px;
            }
          }
        }
      }

      &__progress-section {
        padding: 0 15px;

        > section {
          margin-top: 20px;

          > b {
            font-size: 12px;
          }

          > div {
            margin-top: 10px;
            padding: 12px 15px;
            border: 1px solid #e6e6e6;
            border-radius: 10px;

            > div {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              justify-content: space-between;

              > p {
                color: #707070;
                font-size: 10px;
              }

              > b {
                font-size: 12px;
                color: #333333;
              }
            }
          }
        }

        > span {
          font-size: 10px;
          display: block;
          text-align: right;
          color: $gray-font-color;
          margin-top: 8px;
        }
      }

      &__footer {
        height: 30px;

        > p {
          margin: 9px;
          color: #333333;
          font-size: 10px;
        }
      }

      > section {
        flex: 1;
        padding: 0 !important;
        width: 100%;

        > div {
          height: initial;
        }
      }

      &__status {
        > div {
          padding: 12px 15px;

          > div {
            > p {
              font-size: 10px;
            }

            > b {
              font-size: 12px;
            }
          }

          > progress {
            margin: 10px 0 0;
          }

          > p {
            font-size: 10px;
          }
        }

        > section {
          > a {
            > img {
              top: -7px;
              width: 13px;
              height: 13px;
            }
          }
        }
      }

      &__round {
        > div {
          padding: 12px 15px;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            > b {
              font-size: 20px;
              color: $font-color;
            }

            > div {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 0;

              > b {
                font-size: 20px;
                color: $font-color;
              }

              > p {
                font-size: 10px;
                color: #707070;
              }
            }
          }

          > p {
            padding: 0;
          }
        }
      }
    }

    &__product-point {
      > h2 {
        font-size: 12px;
        margin-bottom: 20px;
      }

      > div {
        grid-template-columns: repeat(1, 1fr);

        > section {
          width: 100%;
          align-items: center;

          > img {
            width: 60px;
            height: 60px;
          }

          > div {
            margin-left: 10px;
            flex: 1;

            > h3 {
              font-size: 12px;
              margin: 0 0 10px;
            }

            > p {
              font-size: 10px;
            }
          }
        }
      }
    }

    &__tab {
      &__wrapper {
        > div {
          display: flex;
          flex-direction: row;
          margin-bottom: 40px;
          align-items: center;
          margin-top: 70px;

          > h2 {
            font-size: 12px;
            margin-right: 5px;
          }
        }
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 3px solid #00bfff;

        > b {
          margin: 5px 0;
          font-size: 10px;
        }
      }
    }

    &__nft-info {
      margin-top: 50px;

      > section {
        > h2 {
          font-size: 12px;
        }

        > a {
          > img {
            top: -7px;
            width: 13px;
            height: 13px;
          }
        }
      }

      > div.nft-info__contents {
        display: flex;
        flex-direction: column;
        margin-top: 22px;

        > figure {
          margin: 0;

          > video {
            width: 290px;
            border-radius: 5px;
          }
        }

        > table {
          width: 290px;
          margin-top: 10px;
          margin-left: 0;
          font-size: 11px;

          tbody > tr {
            height: 38px;

            > th {
              width: 70px;
            }

            > td {
              padding: 5px 10px;

              > ul {
                margin: 0;
                padding: 0;
                list-style: none;

                > li {
                  margin: 10px 0 10px 5px;
                  position: relative;

                  &::marker {
                    display: none !important;
                  }

                  &::before {
                    content: '·';
                    position: absolute;
                    left: -5px;
                  }
                }
              }

              > div {
                display: flex;
                flex-direction: column;
                gap: 0;

                > a {
                  margin: 8px 0;
                  width: 100%;
                  height: 28px;
                  border: 1px solid #3679b5;
                  border-radius: 5px;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  color: #3679b5;
                  cursor: pointer;
                  font-size: 10px;

                  > img {
                    margin: 0 10px;
                  }
                }
              }
            }

            > th,
            > td {
              border: 1px solid #e3e3e3;
            }

            &:first-child {
              > th {
                border-radius: 10px 0 0 0;
              }
            }

            &:last-child {
              > th {
                border-radius: 0 0 0 10px;
              }
            }
          }
        }
      }
    }

    &__bond-nft .bond-nft--kr {
      > table {
        tr {
          th {
            text-align: center;
          }

          td {
            color: #656565;
            font-size: 12px;
            padding: 10px 10px;

            img.product_structure {
              width: 50%;
              margin: 10px auto;
              margin-bottom: 23px;
            }

            section {
              gap: 10px;
              margin-top: 12px;
              display: flex;
              flex-direction: column;
              align-items: baseline;

              > .table-button {
                width: auto;
                padding: 7px 10px;

                > p {
                  font-size: 12px;
                  font-weight: 400;
                  color: #3679b5;
                  background: none;
                  -webkit-text-fill-color: initial;
                }

                > img {
                  margin: 0 10px 0 0;
                  width: 15px;
                }
              }
            }

            div.paragraph {
              display: flex;
              flex-direction: row;

              b {
                margin-right: 5px;
              }

              margin-bottom: 23px;

              &:last-child {
                margin-bottom: 0;
              }

              > div {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }

    &__bond-nft > div {
      margin-top: 60px;

      > h2 {
        font-size: 12px;
        margin-bottom: 20px;
      }

      > img {
        margin: 0 auto 30px;
        display: block;
      }

      > table {
        box-shadow: 0 0 0 1px #e3e3e3;
        border-collapse: collapse;
        border-radius: 10px;
        border-style: hidden;
        word-wrap: break-word;
        width: 100%;
        font-size: 10px;
        table-layout: fixed;

        > tr {
          height: 38px;
          text-align: left;

          > th {
            padding: 5px 5px;
            width: 100px;
            background-color: #f8f9fa;
          }

          > td {
            padding: 5px 10px;
            word-break: break-all;

            section {
              gap: 10px;
              margin-top: 12px;
              display: flex;
              flex-direction: column;
              align-items: center;

              > .table-button {
                @include hover__sub_box-shadow;

                width: 100%;
                display: flex;
                align-items: center;
                padding: 6px 10px;
                margin-right: 5px;
                border: 1px solid #3679b5;
                border-radius: 5px;
                cursor: pointer;

                > img {
                  width: 15px;
                }

                > p {
                  font-size: 10px;
                  color: #3679b5;
                  background: none;
                  -webkit-text-fill-color: initial;
                }
              }
            }

            &.image-wrapper {
              width: 100%;
            }

            > img {
              margin: 0 auto 10px;
              display: block;
              width: 100%;
            }
          }

          > th,
          > td {
            border: 1px solid #e3e3e3;
          }

          &:last-child {
            > th {
              border-radius: 0 0 0 10px;
            }
          }
        }
      }
    }

    &__borrower {
      margin-top: 60px;

      &__youtube {
        height: 220px;
      }

      > h2 {
        font-size: 12px;
        margin-bottom: 20px;
      }

      > table {
        width: 100%;
        font-size: 10px;

        > tr {
          height: 38px;
          text-align: left;

          > th {
            padding: 5px 10px;
            width: 100px;
            background-color: #f8f9fa;
          }

          > td {
            padding: 5px 10px;
            word-break: break-all;

            > div {
              display: flex;
              flex-direction: column;
              gap: 8px;

              > a {
                width: 100%;
                height: 28px;

                > img {
                  margin: 0 5px;
                  width: 10px;
                }

                > p {
                  font-size: 10px;
                }
              }
            }
          }

          > th,
          > td {
            border: 1px solid #e3e3e3;
          }

          &:last-child {
            > th {
              border-radius: 0 0 0 10px;
            }
          }
        }
      }
    }

    &__real-estate-info {
      margin-top: 40px;

      > h2 {
        font-size: 12px;
        margin-bottom: 20px;
      }

      > table {
        width: 100%;
        font-size: 10px;
        margin: 10px 0 5px;

        > tr {
          height: 38px;
          text-align: left;

          > th {
            padding: 5px 10px;
            width: 100px;
            background-color: #f8f9fa;
          }

          > td {
            padding: 5px 10px;

            > div {
              display: flex;
              align-items: center;

              > a {
                display: flex;
                align-items: center;

                > img {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }

          > th,
          > td {
            border: 1px solid #e3e3e3;
          }

          &:last-child {
            > th {
              border-radius: 0 0 0 10px;
            }
          }
        }
      }

      &__asset-feature {
        > h2 {
          font-size: 12px;
          margin-bottom: 20px;
        }

        > div {
          display: flex;
          flex-direction: column;

          > section {
            &:first-child {
              flex-direction: column;
              margin: 0;

              > img {
                width: 100%;
                height: 176px;
                object-fit: cover;
                margin: 0;
                margin-bottom: 10px;
              }

              > div {
                flex-direction: row;

                > figure {
                  width: 100%;

                  &.disable {
                    &::after {
                      content: '';
                      width: 100%;
                      height: 100%;
                      z-index: 2;
                      left: 0;
                      border-radius: 5px;
                      position: absolute;
                      background: rgba(0, 0, 0, 0.6);
                    }
                  }

                  > img {
                    width: 100%;
                  }
                }
              }
            }

            &:last-child {
              margin-top: 20px;
              height: initial;

              > div {
                > b {
                  margin-right: 10px;
                  font-size: 12px;
                }

                > div {
                  margin-bottom: 15px;

                  > b {
                    font-size: 12px;
                    margin-bottom: 5px;
                  }

                  > p {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }

      &__around-asset {
        > h2 {
          font-size: 12px;
          margin-bottom: 7px;
        }

        > p {
          font-size: 10px;
          margin-bottom: 15px;
        }

        > section {
          flex-direction: column;
          padding: 12px 10px;
          gap: 15px;

          > div {
            width: 100%;

            > img {
              width: 100%;
              height: initial;
            }

            > b {
              font-size: 12px;
            }

            > table {
              font-size: 10px;

              > tr {
                > th {
                  padding: 5px 10px;
                  width: 110px;
                }
              }
            }
          }
        }

        > span {
          font-size: 10px;
        }
      }
    }

    &__terms {
      margin-top: 65px;

      > b {
        font-size: 12px;
        margin: 0 0 20px;
        display: block;
      }

      > ul {
        padding: 0;
        list-style: none;

        > li {
          font-size: 12px;
          margin-left: 5px;
        }
      }

      > div {
        > button {
          width: 148px;
          height: 28px;
          line-height: 28px;
          font-size: 12px;
        }
      }
    }

    &__faq {
      > div {
        > h2 {
          font-size: 12px;
        }

        > a {
          font-size: 10px;
        }
      }

      &__content {
        margin-top: 30px;

        > div {
          border: 1px solid #f0f0f1;
          border-radius: 5px;
          padding: 5px 10px;
          background-color: $background-color;
          margin-bottom: 10px;
          overflow: hidden;
          transition: all 0.5s cubic-bezier(0.28, 0.6, 0.57, 0.96);

          > div {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > h3 {
              margin: 0;
              font-size: 10px;

              > span {
                font-family: SpoqaHanSansNeo-Bold;
                font-weight: 900;
                color: $elysia-color;
                margin-right: 3px;
              }
            }
          }

          > p {
            margin-top: 20px;
            font-size: 10px;
            color: $sub-font-color;
            margin-bottom: 14px;
            line-height: 15px;
          }
        }
      }
    }

    &__news {
      margin-top: 45px;

      > h2 {
        font-size: 12px;
        margin-bottom: 20px;
      }

      > section {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        margin-bottom: 5px;
        border: 0;
        grid-gap: 20px;
        gap: 20px;

        > a {
          width: 290px;
          box-shadow: 0 0 6px #00000029;
          border-radius: 5px;
          padding: 10px;

          > img {
            width: 270px;
            height: initial;
            object-fit: cover;
          }

          > b {
            margin-top: 20px;
            font-size: 12px;
            color: #262626;
            display: block;
            margin-bottom: 5px;
          }

          > p {
            font-size: 13px;
            color: #262626;
          }

          > div {
            width: 148px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            margin: 10px auto 0;
          }
        }
      }
    }
  }

  // --kr styles

  .nft-details__nft-info .nft-info__holder-benefits--kr {
    h2 {
      margin-top: 60px;
      margin-bottom: 10px;
      font-size: 12px;
      margin-right: 5px;
    }

    section {
      font-size: 12px;
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      opacity: 1;
      padding: 8px;

      div.card {
        margin-bottom: 5px;
        padding: 15px 15px;
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        opacity: 1;

        .circle {
          background-color: #e7f1fd;
          width: 25%;
          border-radius: 50%;
          position: relative;
          line-height: 0;

          &:after {
            display: block;
            content: '';
            padding-bottom: 100%;
          }

          .circle-text {
            position: absolute;
            bottom: 50%;
            width: 100%;
            text-align: center;
          }

          text-align: center;
          font-weight: bold;
          margin: auto;
        }

        .description {
          padding: 10px 20px;

          p {
            margin: auto;
            font-size: 15px;
            font-family: SpoqaHanSansNeo;
            line-height: 28px;
            font-weight: bold;
            letter-spacing: 0;
            color: #333333;
            opacity: 1;
            text-align: center;
          }

          div {
            margin: auto;
            text-align: center;
          }
        }
      }
    }
  }

  .nft-details__real-estate-info__asset-feature--kr {
    margin-top: 70px;

    h2 {
      font-size: 12px;
      margin-bottom: 20px;
    }

    div {
      display: flex;
      flex-direction: column;
      // height: 284px;

      .images {
        display: flex;
        flex-direction: column;
        // margin-right: 40px;

        img {
          border-radius: 5px;
        }

        > img:first-child {
          width: 100%;
          // height: 284px;
        }

        > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 10px;
          margin-top: 10px;

          > figure {
            position: relative;
            flex: 1;
            cursor: pointer;
            transition: all 0.2s ease;

            &:not(.disable) {
              &:hover {
                filter: brightness(120%);
              }
            }

            &.disable {
              &::after {
                content: '';
                width: 100%;
                height: 100%;
                z-index: 2;
                left: 0;
                border-radius: 5px;
                position: absolute;
                background: rgba(0, 0, 0, 0.6);
                transition: all 0.2s ease;
              }

              &:hover {
                &::after {
                  transition: all 0.2s ease;
                  background: rgba(0, 0, 0, 0.3);
                }
              }

              &:active {
                &::after {
                  transition: all 0.2s ease;
                  background: rgba(0, 0, 0, 0);
                }
              }
            }

            > img {
              width: 100%;
              height: 100%;
              position: relative;
              object-fit: cover;
            }
          }
        }
      }

      .descriptions {
        // height: 395px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0;
        line-height: 25px;

        > div {
          display: flex;
          flex-direction: row;
          margin: 10px 0;

          &:last-child {
            margin: 0;
          }

          > div {
            display: flex;
            flex-direction: column;

            > b {
              font-size: 15px;
            }

            > p {
              font-size: 15px;
              color: #646464;

              &.bold {
                font-family: SpoqaHanSansNeo;
                color: #262626;
              }
            }
          }

          > b {
            color: #00a6ff;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .nft-details__real-estate-info__around-asset--kr {
    margin-top: 70px;

    > h2 {
      font-size: 12px;
      margin-bottom: 10px;
    }

    > section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 13px 14px;
      border: 1px solid #e3e3e3;

      img {
        width: 100%;
      }
    }
  }

  .nft-details__real-estate-info__fail-safe--kr {
    div.line {
      padding: 5px;
    }

    margin-top: 45px;

    > h2 {
      font-size: 12px;
      margin-bottom: 20px;
    }

    > table {
      width: 100%;
      box-shadow: 0 0 0 1px #e3e3e3;
      border-collapse: collapse;
      border-radius: 10px;
      border-style: hidden;
      table-layout: fixed;
      margin: 10px 0 5px;
      font-family: SpoqaHanSansNeo;
      line-height: 25px;
      font-size: 10px;
      color: #262626;

      > tr {
        height: 38px;
        text-align: left;

        th {
          padding: 11px 10px;
          width: 75px;
          background-color: #f8f9fa;

          > div {
            display: flex;
          }

          b {
            color: #00a6ff;
            margin-right: 5px;
          }
        }

        > td {
          padding: 11px 8px;

          > div.paragraph {
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          > div {
            section {
              padding-left: 21px;
            }
          }

          .week {
            color: #656565;
          }
        }

        > th,
        td {
          border: 1px solid #e3e3e3;
        }

        &:last-child {
          > th {
            border-radius: 0 0 0 10px;
          }
        }
      }
    }
  }

  .bond-nft--kr {
    margin-top: 60px;

    > h2 {
      font-size: 12px;
      margin-bottom: 20px;
    }

    > img {
      width: 90%;
      margin: 0 auto 30px;
      display: block;
    }

    > table {
      width: 100%;
      font-size: 10px;
      table-layout: fixed;

      > tr {
        height: 38px;
        text-align: center;

        > th {
          padding: 5px 5px;
          width: 100px;
          background-color: #f8f9fa;
        }

        > td {
          padding: 5px 10px;
          word-break: break-all;

          section {
            flex-direction: column;
            gap: 10px;

            > .table-button {
              width: 100%;

              > img {
                width: 15px;
              }

              > p {
                font-size: 10px;
              }
            }
          }

          &.image-wrapper {
            width: 100%;
          }

          > img {
            margin: 0 auto 10px;
            display: block;
            width: 100%;
          }
        }

        > th,
        > td {
          border: 1px solid #e3e3e3;
        }

        &:last-child {
          > th {
            border-radius: 0 0 0 10px;
          }
        }
      }
    }
  }

  // nft-details end

  .governance {
    @include page-container;

    $asset-width: 150px;

    &__asset {
      width: $asset-width;

      &__progress-bar {
        height: 6px;
        border-radius: 6px;

        &::-webkit-progress-bar {
          height: 6px;
          border: 0;
          border-radius: 6px;
          background: #e6e6e6;
        }

        &::-webkit-progress-value {
          height: 6px;
          border: 0;
          border-radius: 6px;
        }

        &::-moz-progress-bar {
          height: 6px;
          border-radius: 6px;
          border: 0;
        }
      }

      > div {
        > img {
          border-radius: 5px 5px 0 0;
        }

        &:last-child {
          > div {
            justify-content: space-between;
            padding: 5px 11px;

            > p {
              font-size: 10px;
              color: $font-color;

              &:first-child {
                color: $sub-font-color;
              }
            }

            &:last-child {
              padding: 0 11px 17px;

              > div > h2 {
                font-size: 10px;
                line-height: 12px;
                margin-top: 8px;
                margin-bottom: -8px;
              }
            }
          }
        }
      }
    }

    &__grid {
      margin-bottom: 125px;
      grid-gap: 20px 25px;
      grid-template-columns: repeat(1, 1fr);

      > a {
        > article {
          width: 95%;
          margin: auto;
          background: $background-color;
          border-radius: 10px;
          box-shadow: 0 0 6px #1e54711d;
          padding: 22px 41px 23px 29px;

          > header {
            display: flex;
            height: 25px;
            width: 90px;
            align-items: center;
            background-color: #d9edff;
            border-radius: 13px;
            margin-bottom: 15px;

            &.ELYIP {
              background-color: #d3f4ff;

              > p {
                color: #00beff;
              }
            }

            > p {
              font-size: 13px;
              width: 90px;
              font-family: SpoqaHanSansNeo-Bold, Inter-bold;
              color: #3ca3ff;
              text-align: center;
            }
          }

          > section {
            margin-bottom: 35px;
            // height: 100px;

            > p:first-child {
              font-family: SpoqaHanSansNeo-Bold, Inter-bold;
              font-size: 12px;
              color: #262626;
              margin-bottom: 10px;
              // height: 41px;
            }

            > p:last-child {
              font-family: SpoqaHanSansNeo, Inter;
              color: #707070;
              font-size: 11px;
              // height: 30px;
            }
          }

          > div {
            font-family: SpoqaHanSansNeo, Inter;
            display: flex;
            align-items: center;
            font-size: 13px;

            > p {
              color: #262626;
              margin-right: 25px;
            }

            > div {
              display: flex;
              align-items: center;
              justify-content: center;

              > div {
                width: 10px;
                height: 10px;
                background-color: #57b275;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }

    &__elfi {
      &__info {
        width: 310px;
        margin: auto;
        background-color: #ffffff;
        box-shadow: 0 0 6px #1e54711d;
        border-radius: 10px;
        padding: 44px 20px 48px 20px;

        &__first {
          margin-bottom: 62px;

          > header {
            font-family: SpoqaHanSansNeo-Bold, Inter-Bold;
            font-size: 12px;
            margin-bottom: 22px;

            > div {
              // display: flex;
              // align-items: center;

              > span {
                color: $elysia-color;
                margin-right: 5px;
              }

              > a {
                > p {
                  margin-top: 5px;
                  margin-left: 22px;
                  cursor: pointer;
                  font-family: SpoqaHanSansNeo-All-Bold;
                  color: #00a7ff;
                }
              }
            }

            > p {
              margin-top: 13px;
              font-size: 11px;
              color: #707070;
              margin-left: 25px;
            }
          }

          > section {
            width: 100%;
            // display: flex;

            > figure {
              display: none;
              margin-left: 25px;

              > img {
                width: 136px;
                height: 136px;
              }
            }

            > article {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              row-gap: 10px;

              > div {
                width: 453px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 10px;
                border: 1px solid #e6e6e6;
                padding: 22px 25px 18px 25px;

                > p:first-child {
                  font-size: 12px;
                  color: #707070;

                  > span {
                    margin-left: 5px;
                    // display: flex;
                    // align-items: center;
                  }
                }

                > p:last-child {
                  font-family: Inter-Number-Bold, Inter-Bold;
                  font-weight: bold;
                  font-size: 12px;
                  color: #262626;
                }
              }
            }
          }
        }

        &__second {
          margin-bottom: 82px;

          > header {
            font-family: SpoqaHanSansNeo-Bold, Inter-Bold;
            font-size: 12px;
            margin-bottom: 22px;

            > div {
              // display: flex;
              // align-items: center;

              > span {
                color: $elysia-color;
                margin-right: 5px;
              }

              > a {
                > p {
                  margin-top: 5px;
                  margin-left: 22px;
                  cursor: pointer;
                  font-family: SpoqaHanSansNeo-All-Bold;
                  color: #00a7ff;
                }
              }
            }

            > p {
              margin-top: 13px;
              font-size: 11px;
              color: #707070;
              margin-left: 25px;
            }
          }

          > section {
            width: 100%;

            > article {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              row-gap: 10px;

              > div {
                width: 530px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 10px;
                border: 1px solid #e6e6e6;
                padding: 22px 25px 18px 25px;

                > p:first-child {
                  font-size: 13px;
                  color: #707070;
                }

                > p:last-child {
                  font-family: Inter-Number-Bold, Inter-Bold;
                  font-weight: bold;
                  font-size: 13px;
                  color: #262626;
                }
              }
            }
          }
        }

        &__third {
          > header {
            font-family: SpoqaHanSansNeo-Bold, Inter-Bold;
            font-size: 12px;
            margin-bottom: 22px;

            > div {
              // display: flex;
              // align-items: center;

              > span {
                color: $elysia-color;
                margin-right: 5px;
              }

              > a {
                > p {
                  margin-top: 5px;
                  margin-left: 22px;
                  cursor: pointer;
                  font-family: SpoqaHanSansNeo-All-Bold;
                  color: #00a7ff;
                  font-weight: 900;
                }
              }
            }

            > p {
              margin-top: 13px;
              font-size: 11px;
              color: #707070;
              margin-left: 25px;
            }
          }

          > section {
            width: 100%;

            > article {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              row-gap: 10px;

              > a {
                cursor: pointer;

                > div {
                  width: 100%;
                  border-radius: 10px;
                  border: 1px solid #e6e6e6;
                  padding: 25px 20px 27px 20px;

                  > img {
                    width: 55px;
                    height: 55px;
                  }

                  > p:nth-child(2) {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    font-family: Inter-Bold;
                    font-size: 15px;
                    color: #262626;
                    margin-top: 11px;
                    margin-bottom: 11px;

                    > img {
                      margin-left: 6.76px;
                    }
                  }

                  > p:last-child {
                    cursor: pointer;
                    font-size: 13px;
                    color: #707070;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__header {
      > div:first-child {
        padding: 0 5px 17px 5px;
        margin-bottom: 20px;

        > header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > h3 {
            display: flex;
            margin: 0;
            font-size: 11px;

            > span {
              margin-left: 5px;
              display: flex;
              align-items: center;
            }
          }

          > a {
            > p {
              cursor: pointer;
              font-family: SpoqaHanSansNeo-Bold, Inter-Bold, Montserrat-bold;
              color: $elysia-color;
              font-weight: 900;
              font-size: 11px;
            }
          }
        }
      }
    }

    &__elyfi-graph {
      padding: 10px;
      margin-bottom: 50px;
      flex-direction: column;

      > div {
        width: 100%;
        justify-content: center !important;

        &:first-child {
          > div {
            border-radius: 15px;
            height: 30px;

            > p {
              line-height: 30px;
              font-size: 12px;
            }
          }
        }

        > div {
          flex: 2;
          text-align: center;

          > p {
            font-size: 12px;
          }
        }

        &:last-child {
          > div {
            border-radius: 15px;
            height: 30px;

            > p {
              line-height: 30px;
              font-size: 12px;
            }
          }
        }
      }

      &__arrow-container {
        flex-direction: row;
        align-items: center;
        width: 90% !important;

        > .arrow-wrapper {
          flex-direction: column;
          height: 80px;
          width: 10px;
          flex: 0.5;

          > .line {
            height: 100%;
            width: 0;
            border-right: 1px dashed $elysia-color;
          }

          > .right-arrow {
            border-top: 10px solid #00bfff;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-bottom: 0;
          }

          > .left-arrow {
            border-bottom: 10px solid #00bfff;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-top: 0;
          }
        }
      }
    }

    &__content {
      h2 {
        font-size: 18px;
        margin-bottom: 30px;
      }

      &__button {
        border-radius: 30px;
        padding: 5px;
        flex: 1;
        margin: 0;

        > p {
          font-size: 12px;
          color: $font-color;
        }

        &__wrapper {
          margin: 17px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 30px;
        }
      }
    }
  }

  // governance end

  .loan {
    margin-top: 100px;
    margin-bottom: 50px;

    > div {
      margin-bottom: 20px;
      padding-bottom: 10px;

      > h2 {
        font-size: 13px;
        margin-bottom: 10px;
      }

      > p {
        font-size: 12px;
      }
    }
  }

  .portfolio {
    @include page-container;

    position: relative;

    &__view-button {
      height: 50px;
      font-size: 13px;
    }

    &__borrower {
      &__title {
        font-size: 13px;
        padding: 14px 16px;
      }

      &__header {
        flex-direction: column;
        padding: 20px 14px;
        gap: 12px;

        > img {
          width: 160px;
          margin-bottom: 30px;
          margin-top: 10px;
        }

        > div {
          width: 100%;
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > p {
            font-size: 12px;
            line-height: 25px;
          }

          > a {
            font-size: 12px;
          }
        }
      }

      &__data {
        flex-direction: column;

        > div {
          padding: 0 14px;
          display: flex;
          flex-direction: column;
          gap: 12px;

          &:first-child {
            padding-top: 20px;
            margin-bottom: 12px;
          }

          &:last-child {
            padding-bottom: 20px;
            border: none;
          }

          > div {
            align-items: center;

            > p {
              font-size: 12px;
            }

            > a {
              font-size: 12px;
            }
          }
        }

        &--left {
          padding-right: 25px;
        }

        &--right {
          padding-left: 25px;
        }
      }
    }

    &__collateral {
      margin-top: 25px;

      > h2 {
        font-size: 13px;
        padding: 14px 16px;
      }

      &__data {
        flex-direction: column;

        &--left {
          flex: none;
          height: 250px;
          padding: 14px 16px;
          border-bottom: 1px solid $gray-line;

          > a > img {
            width: 100% !important;
            height: 100% !important;
            object-fit: scale-down;
          }
        }

        &--right {
          padding: 20px 16px;
          border: none;

          > div {
            margin-bottom: 12px;

            > p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  // portfolio end

  .staking {
    @include page-container;

    padding-top: 10px;

    &__v2 {
      width: 320px;
      margin: 0 auto;
      padding-top: 80px;
      position: relative;

      &__title {
        text-align: center;
        margin-top: 0;
        margin-bottom: 120px;

        > h2 {
          font-size: 20px;
          margin-bottom: 16px;
          color: $font-color;
        }

        > p {
          color: $font-color;
          font-size: 12px;
        }
      }

      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;

        > div:first-child {
          display: flex;
          align-items: center;
          width: 100%;
          margin-left: 10px;
          margin-bottom: 0;

          > div {
            display: flex;
            flex-direction: row;

            > img {
              width: 24px;
              height: 24px;

              &:last-child {
                margin-left: -4px;
              }
            }
          }

          > h2 {
            font-size: 15px;
            color: $font-color;
            margin-left: 8px;
          }
        }
      }

      &__container {
        margin-bottom: 100px;
      }

      &__content {
        > div {
          background-color: $background-color;

          &:first-child {
            border-radius: 10px;
            border: 1px solid $gray-line;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 2;
            position: relative;

            > div {
              flex: 1;
              padding: 10px 15px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              &:first-child {
                border-right: 1px solid $gray-line;
              }

              > p {
                font-size: 12px;
                color: $font-color;
              }

              > h2 {
                font-size: 12px;
                color: $font-color;
              }
            }
          }

          &:last-child {
            width: calc(100% - 4px);
            margin: 0 auto;
            padding: 20px 15px;
            position: relative;
            bottom: 5px;
            border: 1px solid $gray-line;
            border-radius: 0 0 10px 10px;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            > p {
              font-size: 12px;
              margin-bottom: 20px;
            }

            > div {
              border-radius: 15px;
              width: 138px;
              height: 26px;
              line-height: 26px;
              background: #ffffff;
              box-shadow: 0 0 6px #00000029;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: 10px;

              > p {
                cursor: pointer;
                color: $font-color;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &__title {
      padding-top: 60px;

      > h2 {
        font-size: 18px;
        margin-bottom: 11px;
      }

      > p {
        line-height: 25px;
        font-size: 12px;
      }

      &__button {
        min-width: 164px;
        min-height: 26px;
        border-radius: 35px;
        padding: 5px 10px;
        margin-top: 15px;
        margin-bottom: 10px;

        > div {
          > img {
            width: 22px;
            margin-left: -5px;
          }

          > p {
            font-size: 12px;
          }
        }
      }

      &__content {
        padding-bottom: 20px;

        > p {
          font-size: 12px;
          line-height: 20px;
        }

        &__token-wrapper {
          margin-bottom: 12px;
          justify-content: space-between;

          .reward-plan-button {
            width: auto;
          }

          > div {
            &:first-child {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            > img {
              width: 21px;
              height: 21px;
            }

            > h2 {
              font-size: 13px;
              margin-left: 7px;
            }
          }
        }
      }
    }

    &__round {
      margin-top: 17px;

      $current-data-height: 107px;

      &__container {
        width: $device-width;
      }

      .token-amount {
        margin-left: 5px;
      }

      &__border {
        display: none;
      }

      &__button {
        &__wrapper {
          display: flex;
          flex-direction: row !important;
          justify-content: space-between;
          padding: 0 16px 18px 16px;
        }

        border-radius: 15px;
        width: 138px;
        height: 26px;
        line-height: 26px;
        padding: 0;

        > p {
          font-size: 12px;
        }
      }

      &__current-data {
        height: $current-data-height;
        flex-direction: column;
        width: 100%;
        justify-content: center;

        > div {
          height: auto;
          width: 100%;

          &:first-child {
            padding: 15px 16px;
            border-bottom: 1px solid $gray-line;
            gap: 5px;
          }

          &:last-child {
            flex-direction: row;
            padding: 11px 16px;
            justify-content: space-between;
            display: flex;

            > p {
              font-size: 12px;
            }

            > h2 {
              font-size: 12px;
            }
          }

          > h2 {
            font-size: 12px;
          }

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            > h2 {
              font-size: 12px;
              letter-spacing: -0.5px;
            }

            > p {
              font-size: 12px;

              strong {
                font-size: 12px;
              }
            }
          }
        }
      }

      // current-data end

      &__previous {
        flex-direction: column;
        margin: 10px 0;
        border: 1px solid $gray-line !important;
        border-radius: 10px;

        &__wrapper {
          width: 100%;
          border: none;
        }

        > div {
          flex-direction: column;
          width: 100%;
        }

        &__title {
          text-align: left;
          padding: 16px;
          border-radius: 10px 10px 0 0;

          > h2 {
            font-size: 12px;
            margin-bottom: 8px;
          }

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            > p {
              font-size: 12px;
              line-height: 17px;

              > br {
                display: none;
              }
            }
          }
        }

        &__body {
          padding: 16px;
          gap: 12px;

          > div {
            > p {
              font-size: 12px;
            }

            > h2 {
              font-size: 12px;
            }
          }
        }
      }

      // previous end

      &__header {
        margin: 45px 0 0;
        height: auto;
        border-radius: 10px;
        border: 1px solid $gray-line;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;

        > div {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 12px 15px;

          &:first-child {
            border: none;
            border-bottom: 1px solid $gray-line;
          }

          > p {
            font-size: 12px;
          }

          > h2 {
            font-size: 12px;
          }
        }
      }

      &__remaining-data {
        margin: 10px 0 0;
        height: auto;
        flex-direction: column;

        &__title {
          border: none;
          padding: 16px;
          border-bottom: 1px solid $gray-line;
          align-items: flex-start;

          > div {
            border: none !important;
            width: 100%;

            &:first-child {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              > div {
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
                width: 100%;

                > p {
                  font-size: 12px;
                  line-height: 17px;
                }
              }
            }

            > h2 {
              font-size: 12px;
              color: $elysia-color !important;
            }

            > p {
              font-size: 12px;
            }
          }
        }

        &__body {
          flex-direction: column;
          padding: 12px 15px 18px;
          gap: 12px;

          > div {
            display: block;
            width: 100%;
            border: none !important;

            &:first-child {
              border-bottom: 1px solid $gray-line !important;
              padding-bottom: 20px;
            }

            > div {
              border: none;
              padding: 20px 0 0;

              > h2 {
                font-size: 12px;
                margin-bottom: 5px;

                > span {
                  margin-left: 3px;
                }
              }

              > p {
                font-size: 10px;
                margin-bottom: 10px;
              }
            }

            > h2 {
              font-size: 12px;
            }
          }
        }
      }

      .waiting {
        background-color: #f8f8f8;

        .staking__round__remaining-data__title {
          > div:first-child {
            > p,
            h2 {
              color: #888888 !important;
            }
          }

          > div:last-child {
            background-color: #f8f8f8;
            justify-content: flex-end;
          }
        }
      }
    }

    &__lp {
      &__header {
        margin-top: 60px;

        > h2 {
          font-size: 18px;
          margin-bottom: 11px;
        }

        > p {
          font-size: 12px;
        }

        > div {
          margin-top: 15px;
          margin-bottom: 70px;

          > div {
            width: 80px;
            height: 26px;
            border-radius: 15px;

            > p {
              line-height: 26px;
              font-size: 12px;
            }
          }
        }
      }

      &__detail-box {
        flex-direction: column;
        margin-top: 10px;
        gap: 25px;

        &__body {
          position: relative;
        }

        &__container {
          flex: 1;
        }

        &__header {
          padding: 15px;
          top: 2px;
          z-index: 2;

          > img {
            width: 21px;
            height: 21px;

            &.second-token {
              left: -3px;
              border-radius: 21px;
            }
          }

          > h2 {
            font-size: 13px;
            margin-left: 5px;
          }
        }

        &__item-header {
          padding: 12px 15px 11px;
          gap: 12px;
          display: flex;
          flex-direction: column;

          > div {
            border: none;

            > div {
              > p {
                font-size: 12px;
              }
            }

            > h2 {
              font-size: 12px;
            }
          }
        }

        &__receive-token {
          padding: 13px 15px 18px;

          &__header {
            margin-bottom: 10px;

            > div {
              > p {
                font-size: 12px;
                font-family: $bold;
              }
            }
          }

          &__button {
            width: 130px;
            height: 26px;
            border-radius: 15px;
            line-height: 26px;
            margin: 0 auto;

            > p {
              font-size: 12px;
            }
          }

          &__content {
            > p {
              font-size: 12px;
              line-height: 20px;
              margin-bottom: 13px;
            }
          }
        }

        &__staking {
          padding: 13px 15px 18px;

          &__header {
            > div {
              > p {
                font-size: 12px;
                font-family: $bold;
              }
            }
          }

          &__button {
            width: 130px;
            height: 26px;
            border-radius: 15px;
            line-height: 26px;
            margin: 0 auto;

            > p {
              font-size: 12px;
            }
          }

          &__value {
            margin-top: 14px;
            margin-bottom: 18px;

            > h2 {
              font-size: 15px;
            }
          }
        }

        &__time-data {
          margin-top: 10px;
          font-size: 10px;
        }
      }

      // detail-box end

      &__staked {
        margin-top: 60px;
        position: relative;

        &__wrapper {
          display: flex;
          flex-direction: column-reverse;
        }

        &__header {
          margin-top: 86px;
          padding-bottom: 10px;

          > h2 {
            font-size: 13px;
          }
        }

        &__table {
          border-radius: 0;
          border: none;
          display: flex;
          flex-direction: column-reverse;

          &__header {
            display: none;

            &__container {
              &--left {
                flex: 5;
                margin-bottom: 7px;
                font-size: 15px;

                > div {
                  &:nth-child(1) {
                    flex: 2;
                  }

                  &:nth-child(2) {
                    flex: 2;
                  }

                  &:nth-child(3) {
                    flex: 3;
                  }

                  &:nth-child(4) {
                    flex: 4;
                  }
                }
              }

              &--center {
                width: 35px;
              }

              &--right {
                flex: 2;
                margin-bottom: 7px;
                font-size: 15px;
              }
            }
          }

          &__content {
            &__wrapper {
              background: $background-color;
              border: 1px solid $gray-line;
              padding: 17px 15px;
              margin-bottom: 20px;
            }

            margin-bottom: 20px;
            flex-direction: column;

            &__button {
              width: 130px;
              height: 26px;
              border-radius: 15px;
              line-height: 26px;

              > p {
                font-size: 12px;
              }
            }

            &--left {
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: 11px 12px;
              gap: 12px;

              > div {
                display: flex;
                flex-direction: row !important;
                justify-content: space-between;
                width: 100%;

                > h2 {
                  font-size: 12px;
                  color: $font-color;
                }

                > p {
                  font-size: 12px;
                  color: $gray-font-color;
                }

                &:nth-child(1) {
                  flex: 2;
                }

                &:nth-child(2) {
                  flex: 2;
                }

                &:nth-child(3) {
                  flex: 3;
                }

                &:nth-child(4) {
                  flex: 4;
                  gap: 18px;
                  justify-content: center;
                }
              }
            }

            &--center {
              height: 35px;
              align-items: center;

              > div {
                width: 0;
                height: 0;
                border-top: 10px solid $font-color;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
              }
            }

            &--right {
              width: 100%;

              &__header {
                padding: 12px 12px 8px;
                border-bottom: 1px solid $gray-line;

                > p {
                  font-size: 12px;
                  color: $gray-font-color;
                }
              }

              &__body {
                display: flex;
                flex-direction: column !important;
                padding: 13px 12px 18px;
                gap: 12px;

                > div {
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  justify-content: space-between;
                }
              }

              &__image {
                gap: 8px;

                > img {
                  width: 21px;
                  height: 21px;
                }

                > h2 {
                  font-size: 13px;
                }
              }

              &__reward {
                &__amount {
                  font-size: 15px;
                  font-family: $bold;
                }

                &__unit {
                  font-size: 15px;
                }
              }
            }
          }
        }

        &__reward {
          margin-top: 12px;
          flex-direction: column;
          margin-bottom: 15px;

          &__total-liquidity {
            width: 100%;
            justify-content: space-between;
            flex: none;
            border-bottom: 1px solid $gray-line;
            padding: 15px;

            > h2 {
              font-size: 12px;
            }
          }

          &__amount {
            width: 100%;
            padding: 18px 15px;
            justify-content: space-between;
            align-items: flex-start;

            &__unit {
              font-size: 12px;
              color: $sub-font-color;
              margin-left: 3px;

              &__wrapper {
                flex-direction: column !important;
                gap: 12px;

                > div {
                  display: flex;
                  flex-direction: row;

                  > span {
                    font-size: 12px;
                    color: $font-color;
                  }
                }
              }
            }

            > h2 {
              font-size: 12px;
            }

            > div {
              display: flex;
              flex-direction: column;
              gap: 12px;

              > div {
                display: flex;
                flex-direction: row;
                text-align: right;
                justify-content: flex-end;
                width: 100%;

                > span {
                  font-size: 12px;
                }

                > h2 {
                  font-size: 12px;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      &__reward {
        margin-top: 73px;

        &__button {
          width: 130px;
          height: 26px;
          border-radius: 15px;
          line-height: 26px;

          > p {
            font-size: 12px;
          }

          &__wrapper {
            padding: 12px 0 14px;
          }
        }

        &__header {
          padding-bottom: 10px;
          position: relative;

          > h2 {
            font-size: 13px;
          }
        }

        &__content {
          margin-top: 20px;
          border-radius: 10px;

          &__container {
            flex-direction: column;
            border: none;
            padding: 18px 17px 16px 15px;
            gap: 12px;
            border-bottom: 1px solid $gray-line;
          }

          &__wrapper {
            border: none;
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &__amount {
            > h2 {
              font-size: 15px;

              &:last-child {
                font-size: 15px;
                margin-left: 3px;
              }
            }
          }
        }

        &__image {
          > img {
            width: 21px;
            height: 21px;
          }

          > h2 {
            font-size: 13px;
            margin-left: 8px;
          }
        }
      }
    }

    &__coming-soon {
      width: 240px;
      height: 240px;
      border-radius: 100%;
      margin: 70px auto;

      > div {
        border-radius: 100%;
        background: #ffffff;
        position: absolute;

        &:nth-child(2) {
          left: 0;
          width: 20px;
          height: 20px;
          top: 60px;
        }

        &:nth-child(3) {
          right: 2px;
          width: 10px;
          height: 10px;
          bottom: 50px;
        }

        &:last-child {
          right: 15px;
          width: 20px;
          height: 20px;
          bottom: 34px;
        }

        > h2 {
          font-size: 20px;
        }
      }
    }

    &__el {
      height: calc(100vh - 280px);
      margin: 0 auto;
      width: 100%;

      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 80px auto 0;
        height: 100%;
        text-align: center;
        width: 320px;

        > h2 {
          font-size: 20px;
          color: $font-color;
          margin-bottom: 20px;
        }

        > p {
          font-size: 12px;
          line-height: 20px;
          color: $font-color;
          margin-bottom: 50px;
        }

        > div {
          width: 150px;
          height: 30px;
          border-radius: 30px;
          box-shadow: 0 0 6px #00000029;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          > p {
            font-size: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }

  // staking end

  .legacy {
    min-height: calc(100vh - 322px);
    margin: 0 auto;
    width: 320px;
    padding-top: 100px;

    .lp {
      &__header {
        &__button {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-around;
          margin-top: 35px;

          > div {
            width: 210px;
            height: 47px;
            border-radius: 24px;
            transition: all 0.2s ease;
            background: #ffffff;
            box-shadow: 0 1px 6px #00000029;
            text-align: center;
            cursor: pointer;

            > p {
              color: $font-color;
              cursor: pointer;
              line-height: 47px;
              font-size: 15px;
            }

            &.active {
              border: 1px solid $elysia-color;

              > p {
                font-family: $bold;
                line-height: 47px;
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      text-align: center;
      padding-bottom: 50px;

      > h2 {
        font-size: 20px;
        color: $font-color;
        margin-bottom: 25px;
      }

      > p {
        font-size: 12px;
        line-height: 20px;
        color: $font-color;
        margin-bottom: 25px;
      }
    }

    &__body {
      width: 100%;
      background: $background-color;
      border: 1px solid $gray-line;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.connect {
        border: 0;
      }

      &--left {
        border-right: none;
        border-bottom: 1px solid $gray-line;
        width: 100%;
      }

      &__all-round {
        width: 250px;
        height: 80px;
        background: $background-color;
        border: 1px solid $gray-line;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 15px auto;

        > h2 {
          font-size: 15px;
          color: $elysia-color;
          margin-bottom: 10px;
        }

        > p {
          width: auto;
          text-align: center;
          font-size: 12px;
          color: $font-color;
        }
      }

      &--right {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 20px 15px;
      }

      &__undefined-data {
        > h2 {
          font-size: 13px;
          text-align: center;
        }
      }
    }
  }

  .modal {
    .close-button {
      transform: scale(0.7);
    }

    &__button {
      width: 90%;
      height: 40px;
      margin: 20px auto;
      line-height: 40px;
      border-radius: 40px;

      > p {
        font-size: 14px;
      }
    }

    &__container {
      border-radius: 20px;
      // width: 95vw;
      width: $device-width;
      min-width: $device-width;
    }

    &__header {
      padding: 10px 12px;

      &__image {
        width: 24px;
        height: 24px;
        margin-right: 10px;

        &--sub-token {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          margin-left: -6px;
        }
      }

      &__name {
        font-size: 15px;
      }
    }

    &__converter {
      &__column {
        flex: 1;

        > h2 {
          font-size: 13px;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        &.selected {
          flex: 1;
        }
      }
    }

    &__input {
      height: 100px;
      padding: 0 12px;

      &__maximum {
        font-size: 13px;
        margin-right: 15px;
      }

      &__value {
        &__amount {
          font-size: 35px !important;
        }
      }
    }

    &__deposit {
      &__despositable-amount {
        margin: 20px 15px 0;
        padding-bottom: 15px;

        > p {
          font-size: 12px;
        }

        > div {
          margin-top: 15px;

          > h2 {
            font-size: 12px;
          }
        }
      }

      &__despositable-value {
        margin: 15px;

        > p {
          margin-bottom: 8px;
          font-size: 12px;
        }

        > div {
          > h2 {
            font-size: 12px;
            margin-top: 12px;
          }
        }
      }
    }

    &__withdraw {
      &__withdrawalable {
        &__amount {
          margin: 20px 15px 0;
          padding-bottom: 15px;
        }

        &__value {
          margin: 15px;
        }

        &__content {
          > h2 {
            font-size: 12px;
            margin-top: 12px;
          }
        }

        &__title {
          margin-bottom: 8px;

          > p {
            font-size: 12px;
          }
        }
      }
    }

    &__confirm {
      padding: 20px 15px 25px;

      > img {
        width: 50%;
      }

      > div {
        > h2 {
          margin-top: 30px;
          margin-bottom: 20px;
          font-size: 12px;
        }

        > p {
          font-size: 12px;
        }
      }
    }

    &__incentive {
      &__body {
        height: 100px;
      }

      &__value {
        padding: 20px;
        font-size: 50px;
      }
    }

    &__account {
      margin: 15px;

      &__mainnet-info {
        border-radius: 10px 10px 0 0;
        padding: 10px;

        > div:first-child {
          > p {
            margin: 0 0 5px 0;
            font-size: 12px;
          }

          > div {
            > p {
              font-size: 12px;
              color: $font-color;
            }
          }
        }

        > div:last-child {
          border-radius: 20px;
          width: 100px;
          height: 30px;
          border: 1px solid $gray-line;
          box-shadow: 0 0 3px #00000011;
          line-height: 30px;
          text-align: center;

          > p {
            font-size: 12px;
          }
        }

        &__button {
          border-radius: 0 0 10px 10px;

          > div {
            > p {
              margin: 0 10px;
              color: $font-color;
              font-size: 12px;
            }

            &:first-child {
              border-radius: 0 0 0 10px;
            }
          }

          > a {
            width: 50%;

            > div {
              width: 100%;
              height: 40px;
              margin: 0;
              justify-content: center;

              > p {
                margin: 0 10px;
                font-size: 12px;
                color: $font-color;
              }
            }
          }
        }
      }

      &__status {
        margin-top: 15px;
        margin-bottom: 10px;

        > p {
          font-size: 12px;
        }

        > a {
          > div {
            margin-top: 6px;
            padding: 12px;
            width: 100%;
            height: auto;
            border-radius: 5px;

            > p {
              font-size: 13px;
            }

            > div {
              > img {
                width: 24px;
                height: 24px;
                margin-right: 5px;
              }
            }
          }
        }
      }

      &__add-tokens {
        margin-top: 20px;

        > h2 {
          font-size: 12px;
        }

        > div {
          padding: 10px;
          border-radius: 10px;
          margin-top: 10px;

          > div {
            border-radius: 10px;
            padding: 7px 10px;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            > div {
              > img {
                width: 16px;
                height: 16px;

                &:first-child {
                  margin-right: 8px;
                }
              }

              > div {
                > img {
                  width: 16px;
                  height: 16px;

                  &:first-child {
                    margin-right: 8px;
                  }
                }
              }

              > p {
                font-size: 12px;
              }
            }

            > p {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__staking {
      &__container {
        padding: 15px 15px 20px;

        > p {
          font-size: 12px;
        }

        > div {
          > h2 {
            font-size: 12px;
            margin-top: 15px;
          }
        }
      }
    }

    &__migration {
      &__wrapper {
        padding: 15px;

        > div {
          > h2 {
            font-size: 12px;
            margin-bottom: 10px;
          }
        }
      }

      &__input {
        height: 50px;
        border-radius: 10px;
        padding: 0 20px 0 20px;

        .modal__input__value__amount {
          font-size: 30px !important;
        }
      }

      &__popup {
        &__info {
          > h2 {
            font-size: 12px;
            margin-bottom: 10px;
          }
        }
      }

      .arrow-wrapper {
        height: 55px;
        transform: scale(0.5);
        margin: -5px 0 -30px;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;

        > .line {
          height: 0;
          width: 100%;
          border: 1px dashed $elysia-color;
        }

        > .left-arrow {
          width: 0;
          height: 0;
          border-right: 10px solid $elysia-color;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }

        > .right-arrow {
          width: 0;
          height: 0;
          border-left: 10px solid $elysia-color;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
        }
      }

      &__nth {
        margin-top: 6px;

        > img {
          transform: scale(0.7);
        }

        > p {
          font-size: 10px;
        }
      }

      &__content {
        padding: 0 15px 15px;

        > p {
          margin-top: 20px;
          font-size: 12px;
        }

        > div {
          > h2 {
            margin-top: 15px;
            font-size: 12px;
          }
        }
      }
    }

    &__alert {
      &__content {
        > h2 {
          font-size: 12px;
          margin-top: 5px;
          line-height: 20px;
        }
      }

      &__wrapper {
        padding: 15px;
        margin: 30px 0 20px;
        border-radius: 15px;

        > p {
          font-size: 12px;
          margin-bottom: 6px;
        }

        > div {
          > p {
            font-size: 12px;
          }
        }
      }
    }

    &__lp {
      &__reward {
        &__container {
          > div {
            margin: 0 15px;

            > div {
              padding: 15px 0;

              > img {
                width: 21px;
                height: 21px;
                margin-right: 6px;
              }

              > h2 {
                font-size: 15px;
              }
            }

            > h2 {
              font-size: 15px;

              > span {
                font-size: 13px;
                margin-left: 3px;
              }
            }
          }
        }
      }

      &__staking {
        &__undefined {
          > h2 {
            font-size: 15px;
            padding: 100px 0;
          }
        }
      }
    }

    &__connect {
      &__content {
        padding: 25px;

        > p {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }

    &__network-change-modal__button {
      &__wrapper {
        padding: 10px 20px;
        gap: 10px;
      }

      height: 30px;

      > img {
        width: 28px;
        height: 28px;
      }

      > h2 {
        font-size: 12px;
        margin-right: 8px;
      }
    }

    &__increate-allowance {
      &__content {
        padding: 15px 10px;

        > p {
          font-size: 10px;
          line-height: 18px;
        }
      }

      > p {
        font-size: 10px;
      }

      > h2 {
        font-size: 14px;
        padding: 10px 0 20px;
      }
    }
  }

  // modal end

  .component {
    &__loan-list {
      $loan-list-size: 150px;

      width: $loan-list-size;

      &__container {
        display: grid;
        grid-template-columns: repeat(2, $loan-list-size);
        grid-template-rows: 1fr;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 20px;
        gap: 17px;
      }

      &__image {
        height: 112px;

        > img {
          height: 112px;
        }
      }

      &__data {
        padding: 7px 12px 5px;

        > div {
          > p:last-child {
            font-size: 10px;
          }

          > p:first-child {
            font-size: 10px;
          }
        }
      }
    }

    // loan-list end

    &__text-navigation {
      display: none;
      // padding-top: 50px;
      // font-size: 12px;
      // > p {
      //   font-size: 12px;
      // }
    }

    // text navigation end

    &__progress-bar {
      > div {
        margin-bottom: 5px;

        &:first-child {
          > p {
            font-size: 12px;
          }
        }

        &:nth-child(2) {
          > p {
            font-size: 12px;

            &:last-child {
              font-size: 12px;

              > span {
                font-size: 12px;
              }
            }

            > span {
              font-size: 12px;
            }
          }
        }
      }

      progress {
        height: 6px;
        border-radius: 13px;
        margin-top: 5px;

        &::-webkit-progress-bar {
          border-radius: 6px;
        }

        &::-webkit-progress-value {
          border-radius: 6px;
        }
      }
    }

    // progress bar end

    &__data-info {
      padding: 15px;
      gap: 15px;
      display: flex;
      flex-direction: column;

      > div {
        > p {
          font-size: 12px;
        }
      }
    }

    // data info end

    &__popup-info {
      margin-left: 4px;
      width: 12px;
      height: 12px;
      margin-top: 2px;

      &__hover-mark {
        border-radius: 100%;
        font-size: 10px;
        line-height: 10.5px;
      }

      &__content {
        top: 30px;
        padding: 15px;
        border-radius: 6px;

        > p {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }

  .percent {
    &::after {
      margin-left: 3px;
    }
  }

  .amount {
    &::before {
      margin-right: 3px;
    }
  }

  .equal_amount {
    &::before {
      margin-right: 2px;
    }
  }

  .reward-plan-button {
    > a {
      > p {
        font-size: 12px;
      }
    }
  }

  .elysia-logo {
    width: 106px;
    height: 25px;
  }

  .logo-wrapper {
    height: 40px;
  }

  .footer {
    margin-top: 80px;

    &__link {
      > a {
        width: 38px;
        height: 38px;
        margin-left: 15px;
      }
    }

    > div {
      width: $device-width;
      margin: 0 auto;

      > div {
        width: 100%;
        justify-content: space-between;
      }
    }

    > h2 {
      font-size: 12px;
      color: $sub-font-color;
    }

    &__lang {
      flex: 1;

      &__image-handler {
        &__wrapper {
          > img {
            margin-left: 5px;
          }

          > p {
            margin-right: 5px;
          }
        }
      }

      &__wrapper {
        > img {
          margin-left: 8px;
        }

        > p {
          margin-right: 8px;
        }
      }
    }
  }

  .error {
    &__container {
      border: 1px solid #ffffff;
      width: 300px;
      height: 445.5px;
      border-radius: 10px;
      background: #ffffff;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;

      &__header {
        display: flex;
        justify-content: space-between;
        padding: 29.25px 31px 20.25px 28px;

        > div:first-child {
          font-size: 18px;
          color: #333333;
        }
      }

      &__inquiry {
        padding: 33.25px 37.25px 30.75px 38.75px;
        font-size: 15px;
      }

      &__description {
        background: #f0f0f1;
        padding: 18px 31.25px 21px 27.75px;

        > div:first-child {
          margin-bottom: 10px;
        }

        > div:nth-child(2) {
          display: flex;

          > div {
            width: 100%;

            textarea {
              resize: none;
              width: 100%;
              height: 88px;
              text-align: left;
              padding: 20px;
              border: 1px solid #e6e6e6;
              border-radius: 5px;
            }
          }
        }
      }

      &__button {
        width: 239px;
        height: 30px;
        margin: auto auto;
        margin-top: 10px;
        line-height: 30px;
        border-radius: 47px;

        > p {
          font-size: 14px;
        }
      }
    }
  }

  .wallet_select_modal {
    &__content {
      width: 320px;
      height: 263px;

      &__header {
        padding: 24.5px 23px 22.5px 25px;
      }

      &__wallet_btn {
        margin: 25px auto 23px auto;
        width: 270px;
        height: 55px;
        border-radius: 5px;
        padding: 15px 0 13px 30.5px;

        img {
          width: 28px;
          height: 27px;
          margin-right: 15px;
        }
      }
    }
  }

  .change_network_modal {
    &__content {
      width: 320px;
      height: 263px;

      &__header {
        font-family: SpoqaHanSansNeo-Bold;
        font-size: 15px;
        padding: 22.25px 23px 18px 25px;
      }

      &__guide {
        > p:first-child {
          color: '#333333';
          width: 250px;
          height: 43px;
          margin: auto;
          text-align: center;
          margin-top: 23px;
          margin-bottom: 13px;
          font-size: 13px;
          font-weight: 600;
        }

        > div:last-child {
          font-family: SpoqaHanSansNeo;
          color: '#333333';
          width: 300px;
          height: 16px;
          margin: auto;
          text-align: center;
          margin-bottom: 25px;
          font-size: 11px;
        }
      }

      &__button {
        cursor: pointer;
        font-family: Montserrat-bold;
        width: 230.75px;
        height: 40px;
        font-size: 13px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        border: 1px solid #00bfff;
        margin-top: 19px;
      }
    }
  }

  .indicator {
    height: 200px;
    padding: 15px 20px;

    .lds-spinner {
      transform: scale(0.7);
    }

    > p {
      font-size: 12px;
    }
  }

  .faq {
    max-width: 330px;
    margin: auto;
    margin-top: 190px;

    &__switch {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      margin-top: 20px;

      > button {
        border: 0;
        width: 130px;
        background: #00bfff;
        height: 25px;
        cursor: pointer;
        font-size: 12px;
        color: #ffffff;

        &.disable {
          border: 1px solid #00bfff;
          background-color: #ffffff;
          color: #00bfff;
        }
      }
    }

    &__wrapper {
      max-width: 1190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;

      &__header {
        font-family: SpoqaHanSansNeo-Bold, Inter-Bold;

        > h1 {
          font-size: 18px;
        }
      }

      &__section {
        margin-top: 84px;

        > div {
          border: 1px solid #f0f0f1;
          border-radius: 5px;
          padding: 20px 20px 40px 20px;
          background-color: $background-color;
          margin-bottom: 20px;

          > div:first-child {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 28px;

            > h3 {
              margin: 0;
              font-size: 10px;
              letter-spacing: -0.5px;

              > span {
                font-family: SpoqaHanSansNeo-Bold;
                font-weight: 900;
                color: $elysia-color;
              }
            }
          }

          > p {
            font-size: 12px;
            color: $sub-font-color;
            margin-bottom: 14px;
            line-height: 17px;

            > span {
              font-weight: 900;
            }

            > p {
              font-size: 12px;
              color: $sub-font-color;
            }
          }

          > div:last-child {
            background-color: #f8f8f8;
            border: 1px solid #f0f0f1;
            border-radius: 5px;
            padding: 18px 15px 22px 15px;

            > div:not(p:nth-child(4)) {
              margin-bottom: 10px;
            }

            > div:first-child {
              color: $sub-font-color;
              font-size: 12px;
            }

            > div:nth-child(2),
            div:nth-child(3),
            div:nth-child(4) {
              color: $sub-font-color;
              font-size: 12px;
              // display: flex;

              > div {
                // width: 70px;
                color: $sub-font-color;
                font-weight: bold;
                margin-right: 5px;
              }

              > p {
                // width: 90%;
              }
            }
          }
        }

        > .question_title_box {
          overflow: hidden;
          height: 70px;
        }

        > .box1_down {
          animation: m-q1-box-down 0.3s ease-in;
          height: auto;
        }

        > .box1_up {
          animation: m-q1-box-up 0.3s ease-in;
          height: 70px;
        }

        > .box2_down {
          animation: m-q2-box-down 0.3s ease-in;
          height: auto;
        }

        > .box2_up {
          animation: m-q2-box-up 0.3s ease-in;
          height: 70px;
        }

        > .box3_down {
          animation: m-q3-box-down 0.3s ease-in;
          height: auto;
        }

        > .box3_up {
          animation: m-q3-box-up 0.3s ease-in;
          height: 70px;
        }

        > .box4_down {
          animation: m-q4-box-down 0.3s ease-in;
          height: auto;
        }

        > .box4_up {
          animation: m-q4-box-up 0.3s ease-in;
          height: 70px;
        }

        > .box5_down {
          animation: m-q5-box-down 0.3s ease-in;
          height: auto;
        }

        > .box5_up {
          animation: m-q5-box-up 0.3s ease-in;
          height: 70px;
        }

        > .box6_down {
          animation: m-q6-box-down 0.3s ease-in;
          height: auto;
        }

        > .box6_up {
          animation: m-q6-box-up 0.3s ease-in;
          height: 70px;
        }

        > .box7_down {
          animation: q7-box-down 0.3s ease-in;
          height: auto;
        }

        > .box7_up {
          animation: q7-box-up 0.3s ease-in;
          height: 70px;
        }
      }
    }
  }

  .arrow {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    > div {
      width: 12.86px;
      border: 1px solid $elysia-color;
      position: absolute;
    }

    > div:first-child {
      left: 50%;
      top: 50%;
      transform: translate(-81%, -50%) rotate(45deg);
    }

    > div:last-child {
      left: 50%;
      top: 50%;
      transform: translate(-19%, -50%) rotate(-45deg);
    }
  }

  .arrow_up {
    animation: arrow-up 0.3s ease-in;
    transform: rotate(180deg);
  }

  .arrow_down {
    animation: arrow-down 0.3s ease-in;
    transform: rotate(0deg);
  }

  @keyframes arrow-up {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes arrow-down {
    0% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes m-q1-box-down {
    0% {
      height: 70px;
    }

    100% {
      height: 630px;
    }
  }

  @keyframes m-q1-box-up {
    0% {
      height: 630px;
    }

    100% {
      height: 70px;
    }
  }

  @keyframes m-q2-box-down {
    0% {
      height: 70px;
    }

    100% {
      height: 350px;
    }
  }

  @keyframes m-q2-box-up {
    0% {
      height: 350px;
    }

    100% {
      height: 70px;
    }
  }

  @keyframes m-q3-box-down {
    0% {
      height: 70px;
    }

    100% {
      height: 420px;
    }
  }

  @keyframes m-q3-box-up {
    0% {
      height: 420px;
    }

    100% {
      height: 70px;
    }
  }

  @keyframes m-q4-box-down {
    0% {
      height: 70px;
    }

    100% {
      height: 390px;
    }
  }

  @keyframes m-q4-box-up {
    0% {
      height: 390px;
    }

    100% {
      height: 70px;
    }
  }

  @keyframes m-q5-box-down {
    0% {
      height: 70px;
    }

    100% {
      height: 210px;
    }
  }

  @keyframes m-q5-box-up {
    0% {
      height: 210px;
    }

    100% {
      height: 70px;
    }
  }

  @keyframes m-q6-box-down {
    0% {
      height: 70px;
    }

    100% {
      height: 140px;
    }
  }

  @keyframes m-q6-box-up {
    0% {
      height: 140px;
    }

    100% {
      height: 70px;
    }
  }

  @keyframes q7-box-down {
    0% {
      height: 70px;
    }

    100% {
      height: 370px;
    }
  }

  @keyframes q7-box-up {
    0% {
      height: 460px;
    }

    100% {
      height: 70px;
    }
  }

  .market_modal {
    &__wrapper {
      width: 310px;
    }

    &__header {
      padding: 20.75px 19px 20.75px 19px;

      > h3 {
        font-size: 13px;
      }

      > div {
        width: 20px;
        height: 20px;
      }
    }

    &__section {
      font-size: 11px;
      padding-top: 45px;
      padding-bottom: 43.25px;
      line-height: 20px;
    }

    &__button {
      > button {
        width: 470px;
        height: 38px;

        > img {
          width: 35px;
          height: 35px;
        }

        > p {
          font-size: 12px;
        }
      }
    }

    &__step {
      height: 51px;

      > div {
        font-size: 12px;
      }

      > div:nth-child(1) {
        padding-left: 20px;

        > div:last-child {
          top: -6px;
          border-top: 31px solid transparent;
          border-bottom: 31px solid transparent;
        }
      }

      > div:nth-child(2) {
        padding-right: 20px;
      }
    }

    &__input {
      height: 120px;
      padding: 0 27.25px;

      > section {
        > div {
          font-size: 15px;
        }

        > input {
          height: 53px;
          font-size: 35px;
        }
      }

      > p {
        font-size: 12px;
      }
    }

    &__crypto {
      img {
        width: 25px;
        height: 25px;
      }

      > p {
        font-size: 13px;
      }

      > div {
        height: 38px;
        font-size: 13px;

        > ul {
          top: 38px;

          > li {
            font-size: 13px;
            height: 38px;

            > img {
              margin-right: 10.25px;
            }
          }
        }
      }
    }

    &__amount {
      font-size: 13px;
    }

    &__purchase_btn {
      > button {
        height: 39px;
        font-size: 16px;
      }
    }

    &__confirm {
      > h3 {
        font-size: 14px;
      }

      > div:nth-child(2),
      div:nth-child(3) {
        font-size: 14px;
      }

      > div:last-child {
        height: 200px;

        > ul {
          font-size: 11px;

          > li:first-child > span {
            font-size: 10px;
          }
        }
      }
    }

    &__twitter {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      > figure {
        > video {
          width: 250px;
        }

        > figcaption {
          font-size: 20px;
        }

        margin-bottom: 30px;
      }

      > section {
        height: 103px;
        background-color: #f6f6f8;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding: 0 100px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 10px;

          > b {
            font-size: 20px;
            color: #333333;
          }

          > p {
            font-size: 13px;
            color: #707070;
          }
        }

        > b {
          font-size: 20px;
          color: #333333;
        }
      }
    }

    &__visible {
      position: absolute;
      bottom: -30px;
      display: flex;
      align-items: center;

      > div {
        width: 16px;
        height: 16px;
        border: 1px solid #ffffff;
        border-radius: 2px;
        margin-right: 9px;
        cursor: pointer;

        &:hover {
          background-color: #ffffff33;
        }

        &:active {
          background-color: #ffffff88;
        }
      }

      > b {
        font-size: 15px;
        color: #ffffff;
      }
    }

    &__token-reward {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0 30px;

      > b {
        font-size: 20px;
        line-height: 28px;
      }

      > video {
        width: 250px;
      }

      > section {
        width: 100%;
        margin: 10px auto 0;
        padding: 10px;
        // height: 120px;
        border-radius: 10px;
        background-color: #f6f6f8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > p {
          font-size: 13px;
          color: #333333;

          &:first-child {
            color: #707070;
          }
        }

        > b {
          font-size: 20px;
          margin-bottom: 12.5px;
        }
      }
    }

    &__approve {
      > h5 {
        font-size: 16px;
        padding-bottom: 30px;
      }

      > div {
        border: 1px solid #e6e6e6;
        padding: 32px 24px;

        > p {
          font-size: 12px;
        }
      }

      > p {
        font-size: 11px;
      }
    }

    &__investment-reward {
      &__burn {
        padding: 12px 15px;

        > b {
          font-size: 12px;
        }

        > div {
          margin-top: 13px;
          width: 100%;
          border-radius: 10px;
          padding: 12px 15px;

          > b {
            font-size: 25px;

            > span {
              font-size: 20px;
            }
          }

          margin-bottom: 10px;
        }

        > p {
          font-size: 10px;
        }
      }

      &__current {
        padding: 12px 15px;

        > div {
          > b {
            font-size: 12px;

            > span {
              margin-left: 3px;
              color: #646464;
            }
          }
        }

        > section {
          border-radius: 10px;
          padding: 12px 15px;

          > div {
            > p {
              font-size: 12px;
              color: $font-color;

              > span {
                margin-left: 5px;
              }
            }
          }
        }
      }

      &__button {
        display: flex;

        > button {
          flex: 1;
          height: 30px;
          margin: 12px;
          background: #00bfff;
          border-radius: 35px;
          cursor: pointer;
          font-size: 12px;
          line-height: 30px;
          color: #ffffff;
          border: 0;
        }
      }
    }
  }
}
